<template>
  <div class="activation-info">
    <div class="activation-info__icon">
      <IconClock />
    </div>

    <div class="activation-info__text">
      <template v-if="delay > 0">
        <span v-html="getText()"></span>
      </template>
    </div>
  </div>
</template>

<script>
import { pluralize } from 'app/modules/utils'
import IconClock from './icons/IconClock'

export default {
  name: 'ActivationInfo',
  components: { IconClock },
  props: {
    delay: {
      type: Number,
      default: 0
    }
  },
  methods: {
    pluralize (...args) {
      return pluralize.apply(this, args)
    },
    getText() {
      return pluralize(this.delay,
        this.$t('form.activation_info.1_day', { delay: this.delay }),
        this.$t('form.activation_info.2_days', { delay: this.delay }),
        this.$t('form.activation_info.other', { delay: this.delay })
      )
    }
  }
}
</script>

<style lang='scss' scoped>
.activation-info {
  width: 100%;
  background: #FFFFFF;
  font-size: rem(13);
  line-height: 126%;
  color: #6E7882;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: rem(20);
  padding: rem(17) rem(30);
  margin-bottom: rem(20);
  @include mobile {
    line-height: 120%;
    border-radius: rem(10);
    padding: rem(20) rem(10);
    margin-bottom: rem(13);
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__text {
    line-height: 1;
    display: flex;
    align-items: center;
    margin-left: rem(20);
    @include mobile {
      line-height: 140%;
      text-align: left;
      margin-left: rem(13);
    }
  }

  &__text::v-deep {
    .color-text-orange {
      @include mobile {
        color: var(--theme-reserve-primary-background-component);
      }
    }
  }
}
</style>
