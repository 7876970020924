import Vue from 'vue'
import axios from 'axios'

export default {
  make (positions, options) {
    Vue.$logger.info('order | Making order', JSON.parse(JSON.stringify(positions)), JSON.parse(JSON.stringify(options)))

    let params = {
      positions
    }

    if (options.stat) {
      params['stat'] = options.stat
    }
    if (options.promocode) {
      params['promocode'] = options.promocode
    }
    if (options.orderId) {
      params['orderId'] = options.orderId
    }
    if (options.psOverride) {
      params['ps'] = options.psOverride
    }
    if (options.successUrl) {
      params['successUrl'] = options.successUrl
    }
    if (options.failUrl) {
      params['failUrl'] = options.failUrl
    }
    if (options.externalParams) {
      params['externalParams'] = options.externalParams
    }

    const isDemo = options.demo || false

    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + (isDemo ? '/demo-order' : '/order')

    return axios.post(ACTION_URL, params)
      .then((response) => {
        return response.data
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('order_error')
          } else if (error.response.status === 404) {
            throw new Error('order_error')
          } else {
            throw new Error('order_error')
          }
        } else if (error.request) {
          Vue.$logger.error('order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  test (params, options) {
    Vue.$logger.info('order | Sending test certificate', JSON.parse(JSON.stringify(params)), JSON.parse(JSON.stringify(options)))

    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/send-test'

    return axios.post(ACTION_URL, params)
      .then((response) => {
        return response.data
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('order_error')
          } else if (error.response.status === 404) {
            throw new Error('order_error')
          } else {
            throw new Error('order_error')
          }
        } else if (error.request) {
          Vue.$logger.error('order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
