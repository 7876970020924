<template>
  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="svg-content" d="M1 4L4 7L9 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckOrange'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(10);
  height: rem(9);
}

.svg-content {
  stroke: var(--theme-svg-icon-content);
}
</style>
