import { DateTime } from 'luxon'
import { timeZone } from 'app/modules/constants'

export function isInDST (date, region) {
  if (date) {
    const dateISO = DateTime.fromISO(date)
    const selectedTime = DateTime.fromObject({
      year: dateISO.year,
      month: dateISO.month,
      day: dateISO.day,
      zone: timeZone[region].zoneId
    })

    return selectedTime.isInDST
  }

  return false
}
