<template>
  <div class="send-test">
    <div class="send-test__container">
      <div class="send-test__block">

        <div class="widget-input">
          <div class='widget-input__wrapper'>
            <label>{{ $t('test.your_name') }}</label>
            <input v-model.lazy="name" type="text" name="name" id="widget-input">
          </div>
        </div>

      </div>
      <div class="send-test__block">

        <div class="widget-input" :class="{ 'widget-input--error': $v.email.$error }">
          <div class='widget-input__wrapper'>
            <label>{{ $t('test.your_email') }}*</label>
            <EmailDropdown v-model.lazy="$v.email.$model" @input="delayTouch(($v.email))" inputId="'send-test-email'"
              :placeholder="$v.email.$error ? $t('form.required_field') : ''" :domains="domains" :maxSuggestions="6"
              :defaultDomains="defaultDomains[$store.state.region.macroregion]" />
          </div>
        </div>
      </div>
    </div>
    <div class="send-test__btn">
      <div class="send-test__btn-block">
        <default-btn @click="sendTestCertificate" :disabled="isPending">
          <span v-if="!isPending">{{ $t('form.buttons.send_test') }}</span>
          <span v-if="isPending" v-html="$t('test.is_sending')"></span>
        </default-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { validateEmail } from 'app/modules/utils'
import order from 'app/api/order'
import EmailDropdown from './default/EmailDropdown'
import { domains, defaultDomains } from 'app/modules/constants'
import DefaultBtn from './default/DefaultBtn'
import ModalSendTest from './modals/ModalSendTest'

const touchMap = new WeakMap()

export default {
  components: {
    DefaultBtn,
    EmailDropdown,
  },
  data() {
    return {
      name: '',
      email: '',
      isPending: false,
      isShipped: false,
      error: '',
      domains,
      defaultDomains,
    }
  },
  validations: {
    email: {
      required,
      validateEmail: function (value) {
        return validateEmail(value)
      },
    },
  },
  computed: {
    product() {
      return this.$store.state.main.product
    },
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1500))
    },
    validate() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async sendTestCertificate() {
      let stat = {}

      if (typeof window.roistat !== 'undefined') {
        stat.roistatVisit = window.roistat.getVisit()
      }

      try {
        this.isShipped = false
        this.isPending = true
        this.error = ''

        if (this.validate()) {
          const data = await order.test({
            productId: this.$store.state.card.current.productId,
            email: this.email,
            name: this.name,
            face: this.$store.state.card.current.face,
            cardImage: this.$store.state.card.current.cardImage
          }, { stat })

          if (
            data.status === 'ok' &&
            data.data.result === 'ok'
          ) {
            this.isShipped = true
            this.$modal.show(ModalSendTest, {
              title: this.$t(`test.was_sent.${ this.$store.state.main.product.kind }`),
              buttonOk: this.$t('modals.buttons.ok')
            })
          } else if (
            data.status === 'ok' &&
            data.data.result === 'error'
          ) {
            this.$modal.show(ModalSendTest, {
              title: data.data.message,
              buttonOk: this.$t('modals.buttons.ok')
            })
          } else {
            this.$modal.show(ModalSendTest, {
              title: this.$t('form.errors.general'),
              buttonOk: this.$t('modals.buttons.ok')
            })
          }
        }
      } catch (e) {
        this.$modal.show(ModalSendTest, {
          store: this.$store,
          title: this.$t('form.errors.general'),
          buttonOk: this.$t('modals.buttons.ok')
        })
      } finally {
        this.isPending = false
      }
    },
    resetValidation() {
      this.$parent.$refs.recipient.$v.$reset()
      this.$parent.$refs.sendTime.$v.$reset()
      this.$nextTick()
    },
  },
}
</script>

<style lang="scss" scoped>
.send-test {
  background-color: #ffffff;
  border-radius: rem(20);
  padding: rem(40) rem(20) rem(20);

  @include mobile {
    padding: rem(30) rem(10);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20);

    @include mobile {
      flex-direction: column;
      padding: 0;
      margin-bottom: rem(30);
    }
  }

  &__block {
    width: calc(50% - 15px);
    flex-grow: 1;

    @include mobile {
      width: 100%;
    }

    &:first-child {
      margin-right: rem(30);

      @include mobile {
        margin-right: 0;
        margin-bottom: rem(20);
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-block {
      @include mobile {
        flex-grow: 1;
      }
    }

    .btn {
      @include mobile {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.recipient::v-deep {
  .email-dropdown-item:focus-visible {
    outline: none;
  }

  .email-dropdown-wrapper .email-dropdown-list .email-dropdown-item {
    padding: 6px 12px;
    border: none;

    &:focus,
    &:hover {
      border: none;
    }
  }
}

.widget-label-tip {
  opacity: 0;
  position: absolute;
}
</style>
