<template>
  <div class="promocode">
    <div class="promocode__block" :class="{ 'promocode--correct': $store.state.cart.promoCode !== null }">
      <div class="promocode-label">
        <label class="promocode-label__text" for="promocode-input">
          {{ $t('cart.promocode_enter') }}
          <div class="promocode-label__tip" tabindex="-1">
            <div class="promocode-input-tip__text" v-html="$t('cart.promocode_tip')"></div>
          </div>
        </label>
      </div>
      <div class="promocode-input">
        <div class='promocode-input__wrapper'>
          <input v-model.lazy="code" :class="{ 'promocode-input--error': errorText !== '' }" type="text" id="promocode-input">
          <div class="promocode-input-error" v-if="errorText !== ''">
            <IconError />
            <div class='promocode-input-error__tip'>
              <span v-html="errorText"></span>
            </div>
          </div>
          <div class="promocode-input-error" v-if="errorText === '' && code !== ''">
            <IconCheck />
          </div>
        </div>
      </div>
      <div class='promocode__btn'>
        <default-btn round @click="clearPromocode">
          <IconDelete />
        </default-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import promocode from 'app/api/promocode'
import DefaultBtn from './default/DefaultBtn'
import IconCheck from './icons/IconCheck'
import IconError from './icons/IconError'
import IconDelete from './icons/IconDelete'

export default {
  components: { IconDelete, DefaultBtn, IconError, IconCheck },
  data () {
    return {
      code: '',
      errorText: ''
    }
  },
  computed: {
    product () {
      return this.$store.state.main.product
    },
  },
  watch: {
    code: function (code) {
      if (code !== '') {
        code = code.toLowerCase()

        promocode.check(code, this.$store.state.main.productId)
        .then((response) => {
          this.errorText = ''
          this.$store.dispatch('cart/setPromoCode', response)
        }).catch((error) => {
          this.$store.dispatch('cart/setPromoCode', null)
          if (error.message === 'promocode_error') {
            this.errorText = 'Промокод не найден'
          } else {
            this.errorText = 'Ошибка. Попробуйте воспользоваться промокодом позже'
          }
          Vue.$logger.debug('Promocode | promocode.check | ошибка: ', error)
        })
      } else {
        this.errorText = ''
        this.$store.dispatch('cart/setPromoCode', null)
      }
    }
  },
  mounted() {
    if (this.$store.state.cart.promoCode) {
      const strCode = this.$store.state.cart.promoCode.promocode + ''

      this.code = strCode.toLowerCase()
    }
  },
  methods: {
    clearPromocode() {
      this.code = ''
      this.errorText = ''
    }
  },
}
</script>

<style lang="scss" scoped>
.promocode {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(4);
  border: rem(1) solid #F1F2F3;
  padding: rem(14) rem(30);
  margin-bottom: rem(30);

  &__block {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1.7fr 0.3fr;
    grid-gap: 2vw;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }
}

.promocode-label {
  display: flex;
  align-items: center;

  &__text {
    font-size: rem(13);
    line-height: rem(16);
    color: #6E7882;
    position: relative;

    &:hover {
      cursor: help;

      .promocode-label__tip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }

  &__tip {
    max-width: rem(200);
    min-width: rem(200);
    background-color: #FA6400;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: rem(30);
    opacity: 0;
    border-radius: rem(4);
    padding: rem(5) rem(15);
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #FA6400;
      transform: translate(-50%, 0);
    }
  }
}

.promocode-input {
  &__wrapper {
    max-width: rem(245);
    position: relative;
  }

  input {
    width: 100%;
    font-size: rem(13);
    line-height: rem(16);
    color: #0B132A;
    text-transform: uppercase;
    border: none;
    outline: none;
    padding: rem(4) rem(22) rem(4) 0;
    border-bottom: rem(1) solid rgb(110 120 130 / 20%);

    &.promocode-input--error {
      border-bottom-color: #FA6400;
    }
  }
}

.promocode-input-error {
  position: absolute;
  top: rem(4);
  right: 0;
  cursor: help;

  &__tip {
    max-width: rem(200);
    min-width: rem(200);
    background-color: #FA6400;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: rem(30);
    opacity: 0;
    border-radius: rem(4);
    padding: rem(10) rem(20);
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #FA6400;
      transform: translate(-50%, 0);
    }
  }

  &:hover {
    .promocode-input-error__tip {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.promocode-label-tip {
  opacity: 0;
  position: absolute;
}
</style>
