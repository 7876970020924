<template>
  <div class="page offer">
    <div class="offer__container">
      <div class="page__header">
        <span class="page__title" v-html="$t('offer.title')"></span>
      </div>
      <div class="offer__content">
        <div v-if="$store.state.main.options.pages && $store.state.main.options.pages.offer"
          v-html="$store.state.main.options.pages.offer.content"></div>
        <div v-else>
          <component :is="offer" ref="app" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offer: null,
      defaultOffers: {
        'ru': 'ru/2',
        'kz': 'ru/3',
        'by': 'ru/4',
        'es': 'es/1',
        'fr': 'fr/1',
        'en': 'en/1',
      },
    }
  },
  computed: {
    legalInfo() {
      return this.$store.state.main.product.legal_info
    }
  },
  methods: {
    getOfferComponent() {
      if (this.$store.state.main.options.locale) {
        const locale = this.$store.state.main.options.locale

        return require(`../../../shared/views/offer/${this.defaultOffers[locale]}`).default
      }

      if (this.legalInfo?.macroregion && this.legalInfo?.self_id) {
        return require(`../../../shared/views/offer/${this.legalInfo.macroregion}/${this.legalInfo.self_id}`).default
      }

      return require(`../../../shared/views/offer/${this.defaultOffers[this.$store.state.main.product.region]}`).default
    }
  },
  mounted() {
    this.offer = this.getOfferComponent()
  }
}
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: rem(40);
    @include mobile {
      margin-bottom: rem(20);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 120%;
    color: #0E1E0E;

    @include mobile {
      font-size: rem(15);
    }

    &::v-deep {
      br {
        display: none;

        @include mobile {
          display: block;
        }
      }

      .weight-400 {
        font-weight: 400;
      }
    }
  }
}

.offer {
  &__content {
    font-size: rem(13);
    line-height: rem(18);
    color: #6E7882;
    background-color: #ffffff;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(10);
    padding: rem(30) rem(20);

    @include mobile {
      padding: rem(24) rem(16);
    }
  }

  a {
    color: #0E1E0E;
  }
}

.offer::v-deep {
  a {
    color: #0E1E0E;
  }

  h3 {
    font-weight: 600;
    font-size: rem(13);
    margin: 0 0 rem(14);
  }

  p {
    margin: 0;
  }

  p+p {
    margin-top: rem(14);
  }

  ol,
  ul {
    margin: 0;
    list-style: none;
  }

  ol {
    padding: 0 0 0 rem(26);

    @include mobile {
      padding-left: rem(16);
    }
  }

  ul {
    padding: 0 0 0 rem(18);
  }

  ol li+li,
  ul li+li {
    margin-top: rem(14);
  }

  ol li,
  ul li {
    position: relative;
  }

  ol>li {
    counter-increment: item;
  }

  ol li::before,
  ul li::before {
    position: absolute;
  }

  ol li::before {
    content: counter(item) '.';
    font-weight: 400;
    left: rem(-26);

    @include mobile {
      left: rem(-16);
    }
  }

  ul li::before {
    content: '';
    left: rem(-20);
    top: rem(7);
    width: rem(6);
    height: rem(6);
    border-radius: 50%;
    background: var(--theme-primary-background-component);
  }
}
</style>
