<template>
  <div class="short-info-block__item">
    <div class="short-info-block__icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 6V12L16 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="short-info-block__text">
      <span v-html="$t(`conditions.date.${ $store.state.main.product.kind }`)"></span>
      <span v-html="$t('conditions.date_ex')"></span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
  },
  methods: {
    openConditions() {
      this.$emit('click-on-link')
    }
  }
}
</script>
