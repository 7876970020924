<template>
  <div id="pixie-container">
    <div>
      <label class="upload-file" :class="{ 'upload-file--hidden': showButton === false }">
        <span>Своё изображение</span>
        <input accept="image/png, image/jpeg, image/jpg" type="file" ref="file" @change="setFile" class="file-input">
      </label>
    </div>
    <pixie-editor id="pixieEditor" v-pre></pixie-editor>
  </div>
</template>

<script>
import cover from 'app/api/cover'
import { b64toBlob, fileToBase64 } from 'app/modules/utils'

let pixie
const navItems = [
  { name: 'filter', icon: 'filter-custom', action: 'filter' },
  { type: 'separator' },
  { name: 'draw', icon: 'pencil-custom', action: 'draw' },
  { name: 'shapes', icon: 'polygon-custom', action: 'shapes' },
  { name: 'stickers', icon: 'sticker-custom', action: 'stickers' },
  { name: 'frame', icon: 'frame-custom', action: 'frame' },
  { name: 'crop', icon: 'crop-custom', action: 'crop' },
  { name: 'transform', icon: 'transform-custom', action: 'transform' }
]

export default {
  name: 'PixieEditor',
  data: () => ({
    file: {},
    isInitialized: false
  }),
  props: {
    showButton: Boolean
  },
  methods: {
    initPixie() {
      this.$store.dispatch('main/setLoadingState', true)

      const pixieScript = document.createElement('script')
      pixieScript.setAttribute('src', '/pixie.min.js')
      document.head.appendChild(pixieScript)

      pixieScript.onload = () => {
        this.onScriptLoad()
      }
    },
    onScriptLoad() {
      pixie = new Pixie({ // eslint-disable-line
        baseUrl: window.location.origin,
        languages: {
          active: 'russian',
          custom: {
            russian: {
              'resize': 'размер',
              'crop': 'обрезать',
              'transform': 'повернуть',
              'Apply': 'Применить',
              'Editor': 'Редактор',
              'Save': 'Сохранить',
              'Cancel': 'Отменить',
              'Close': 'Назад',
              'Width': 'Ширина',
              'Height': 'Высота',
              'Zoom': 'Масштаб',
              'Applied: resize': 'Размер изменён',
              'Applied: crop': 'Обрезано',
              'Applied: transform': 'Трансформировано',
              'Maintain Aspect Ratio': 'Сохранить пропорции',
              'Use Percentages': 'Использовать проценты',
              'opacity': 'Прозрачность',
              'filter': 'Фильтры',
              'draw': 'Кисть',
              'drawing': 'Рисование',
              'text': 'Текст',
              'shape': 'Фигура',
              'shapes': 'Фигуры',
              'sticker': 'Стикер',
              'stickers': 'Стикеры',
              'frame': 'Рамка',
              'corners': 'Углы',
              'background': 'Фон',
              'merge': 'Объединить',
              'History': 'История',
              'Objects': 'Объекты',
              'image': 'Изображение',
              'mainImage': 'Главное изображение',
              'Canvas Background': 'Canvas фон',
              'Brush Color': 'Цвет кисти',
              'Brush Type': 'Тип кисти',
              'Brush Size': 'Размер кисти',
              'Size': 'Размер',
              'Radius': 'Радиус',
              'Align Text': 'Выравнивание текста',
              'Format Text': 'Формат текста',
              'Color Picker': 'Выбор цвета',
              'Add Text': 'Добавить текст',
              'Font': 'Шрифт',
              'Upload': 'Загрузить',
              'Google Fonts': 'Google шрифты',
              'Search...': 'Поиск...',
              'Shadow': 'Тень',
              'Color': 'Цвет',
              'Outline': 'Обводка',
              'Background': 'Фон',
              'Texture': 'Текстура',
              'Gradient': 'Градиент',
              'Text Style': 'Стиль текста',
              'Delete': 'Удалить',
              'Background Color': 'Цвет фона',
              'Outline Width': 'Ширина обводки',
              'Offset X': 'Отступ X',
              'Offset Y': 'Отступ Y',
              'Open': 'Открыть',
              'Applied: filter': 'Применено: фильтр',
              'Added: drawing': 'Добавлено: рисование',
              'Added: text': 'Добавлено: текст',
              'Added: shape': 'Добавлено: фигура',
              'Added: sticker': 'Добавлено: стикер',
              'Added: frame': 'Добавлено: рамка',
              'Objects: merged': 'Объекты: объединено',
              'Applied: rounded corners': 'Применено: скруглённые края',
              'Changed: background': 'Изменено: фон',
              'Changed: background image': 'Изменено: фоновое изображение',
              'Added: overlay image': 'Добавлено: изображение',
              'Initial': 'Начальный',
              'Loaded: state': 'Загружено: состояние',
              'Changed: style': 'Изменено: стиль',
              'grayscale': '1',
              'black & white': '2',
              'sharpen': '3',
              'invert': '4',
              'vintage': '5',
              'polaroid': '6',
              'kodachrome': '7',
              'technicolor': '8',
              'brownie': '9',
              'sepia': '10',
              'remove color': '11',
              'brightness': '12',
              'gamma': '13',
              'noise': '14',
              'pixelate': '15',
              'blur': '16',
              'emboss': '17',
              'blend color': '18',
              'distance': 'Дистанция',
              'blocksize': 'Размер пикселя',
              'basic': '1',
              'pine': '2',
              'oak': '3',
              'rainbow': '4',
              'grunge #1': '5',
              'grunge #2': '6',
              'ebony': '7',
              'art #1': '8',
              'art #2': '9',
              'circle': 'Круг',
              'rectangle': 'Квадрат',
              'triangle': 'Треугольник',
              'ellipse': 'Эллипс',
              'arrow #1': 'Стрелка 1',
              'arrow #2': 'Стрелка 2',
              'arrow #3': 'Стрелка 3',
              'line': 'Линия',
              'star': 'Звезда',
              'polygon': 'Полигон',
              'badge': 'Значок',
              'font picker': 'Выбор шрифта',
              'emotions': 'Эмоции',
              'people': 'Люди',
              'gestures': 'Жесты',
              'animals': 'Животные',
              'other': 'Другие'
            }
          }
        },
        tools: {
          stickers: {
            replaceDefault: true,
            items: [
              {
                name: 'emotions',
                type: 'png',
                items: 110,
                thumbnailUrl: 'images/stickers/emotions/0.png'
              },
              {
                name: 'people',
                type: 'png',
                items: 83,
                thumbnailUrl: 'images/stickers/people/0.png'
              },
              {
                name: 'gestures',
                type: 'png',
                items: 37,
                thumbnailUrl: 'images/stickers/gestures/0.png'
              },
              {
                name: 'animals',
                type: 'png',
                items: 22,
                thumbnailUrl: 'images/stickers/animals/0.png'
              },
              {
                name: 'other',
                type: 'png',
                items: 10,
                thumbnailUrl: 'images/stickers/other/0.png'
              }
            ]
          },
          export: {
            defaultFormat: 'jpeg',
            defaultName: 'image',
            defaultQuality: 0.8
          },
          crop: {
            replaceDefault: true,
            hideCustomControls: false,
            defaultRatio: '68:31',
            items: ['68:31']
          },
          import: {
            validExtensions: ['png', 'jpg', 'jpeg']
          }
        },
        ui: {
          mode: 'overlay',
          allowZoom: false,
          toolbar: {
            hideOpenButton: false,
            hideCloseButton: false
          },
          openImageDialog: {
            show: false
          },
          nav: {
            position: 'top',
            replaceDefault: true,
            items: navItems
          }
        },
        onLoad: () => {
          this.openFile()
          this.$store.dispatch('main/setLoadingState', false)
        },
        onSave: async img => {
          this.$store.dispatch('main/setLoadingState', true)

          const image = this.getResizedFile(img)

          const block = image.split(';')
          const contentType = block[0].split(':')[1]
          const extension = contentType.split('/')[1]
          const realData = block[1].split(',')[1]
          const blob = b64toBlob(realData, contentType)
          const file = new File([blob], 'image')

          const formData = new FormData()
          formData.append('file', file, `file.${extension}`)

          const data = await cover.upload(formData)

          this.$emit('image-uploaded', data.url)

          pixie.close()
          this.$store.dispatch('main/setLoadingState', false)
        },
        onClose: () => {
          // destroy Pixie
          document.getElementById('pixieEditor').remove()
          const newPixie = document.createElement('pixie-editor')
          newPixie.id = 'pixieEditor'
          document.getElementById('pixie-container').append(newPixie)
        }
      })
    },
    async openFile(file = this.file) {
      pixie.resetAndOpenEditor()
      await pixie.getTool('import').openBackgroundImage(file)
      setTimeout(this.openTransformTool, 200)
    },
    /* eslint-disable */
    openCropTool() {
      const cropTool = [...pixieEditor.getElementsByClassName('name')].find(tag => tag.textContent === 'обрезать')
      cropTool.click()
      setTimeout(() => {
        pixieEditor.querySelectorAll('.aspect-ratio.selected')[0].click()
        pixie.setConfig('ui.nav.items', navItems.slice(0, -2))
      }, 250)
      this.cropAndGo()
    },
    openTransformTool() {
      const transformTool = [...pixieEditor.getElementsByClassName('name')].find(tag => tag.textContent === 'повернуть')
      transformTool.click()
      pixie.setConfig('ui.nav.items', navItems.slice(0, -1))
      pixieEditor.querySelectorAll('mat-slider')[0].style.visibility = 'hidden'
      const rotateButton = pixieEditor.querySelectorAll('.rotate-button')[2]
      rotateButton.click()
      rotateButton.click()
      this.transformAndGo()
    },
    // Доступ через DOM, т. к. исходники на ангуляре - todo
    // Не отпускать юзера, пока не кропнет изображение
    cropAndGo() {
      const cancelButton = pixieEditor.getElementsByClassName('cancel-button')[0]
      const exportButton = pixieEditor.getElementsByClassName('export-button')[0]
      const applyButton = pixieEditor.getElementsByClassName('apply-button')[0]
      const mobileCloseButton = pixieEditor.querySelectorAll('.left button')[0]
      const mobileApplyButton = pixieEditor.querySelectorAll('.right button')[0]
      const drawerWrapper = pixieEditor.querySelectorAll('.drawer-wrapper')[0]
      const info = document.createElement('div')
      info.className = 'info'
      info.innerHTML = '<span>2. Обрежьте фотографию под необходимый формат</span>'
      drawerWrapper.append(info)
      mobileCloseButton.style.visibility = 'hidden'
      applyButton.addEventListener('click', () => {
        cancelButton.style.display = 'block'
        if (exportButton) exportButton.style.visibility = 'visible'
        setTimeout(this.checkCropRatio, 250)
      }, { once: true })
      if (mobileApplyButton) {
        mobileApplyButton.addEventListener('click', () => {
          mobileCloseButton.style.visibility = 'visible'
          setTimeout(this.checkCropRatio, 250)
        }, { once: true })
      }
    },
    transformAndGo() {
      const applyButton = pixieEditor.getElementsByClassName('apply-button')[0]
      const mobileApplyButton = pixieEditor.querySelectorAll('.right button')[0]
      const mobileCloseButton = pixieEditor.querySelectorAll('.left button')[0]
      const drawerWrapper = pixieEditor.querySelectorAll('transform-drawer .content')[0]
      const info = document.createElement('div')
      info.className = 'info'
      info.innerHTML = '<span>1. Поверните изображение, если требуется</span>'
      drawerWrapper.prepend(info)
      mobileCloseButton.style.visibility = 'hidden'
      applyButton.addEventListener('click', () => {
        setTimeout(this.openCropTool, 100)
      }, { once: true })
      if (mobileApplyButton) {
        mobileApplyButton.addEventListener('click', () => {
          setTimeout(this.openCropTool, 100)
        }, { once: true })
      }
    },
    /* eslint-enable */
    checkCropRatio() {
      const base64 = pixie.getTool('export').getDataUrl()
      const img = new Image()

      img.onload = () => {
        const ratio = 68 / 31
        if ((img.width / img.height) <= ratio) {
          pixie.getTool('crop').apply({
            width: img.width,
            height: img.width / ratio,
            left: 0,
            top: (img.height / 2) - (img.width / (ratio * 2))
          })
        } else {
          pixie.getTool('crop').apply({
            width: img.height * ratio,
            height: img.height,
            left: (img.width / 2) - (img.height * ratio / 2),
            top: 0
          })
        }
      }

      img.src = base64
    },
    getResizedFile(img) {
      const block = img.split(';')
      const contentType = block[0].split(':')[1]
      const realData = block[1].split(',')[1]
      const blob = b64toBlob(realData, contentType)
      const file = new File([blob], 'image')

      if (file.size > 1024 * 1024) {
        pixie.getTool('resize').apply(1360, 620)
        return pixie.getTool('export').getDataUrl()
      }
      return img
    },
    async setFile() {
      if (this.$refs.file.files.length > 0) {
        this.file = await fileToBase64(this.$refs.file.files[0])
        if (this.isInitialized) {
          this.onScriptLoad()
        } else {
          this.initPixie()
          this.isInitialized = true
        }
      }
    }
  }
}
</script>

<style>
.upload-file {
  appearance: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  max-width: 460px;
  padding: 5px;
  background: var(--theme-primary-background-component);
  font-size: 12px;
  color: #0E1E0E;
  outline: transparent;
  cursor: pointer;
  border-radius: 5px;
}

.upload-file.upload-file--hidden {
  overflow: hidden;
  width: 0;
  height: 0;
  padding: 0;
  font-size: 0;
}

.upload-file i {
  font-size: 20px;
  margin-bottom: 4px;
}

.upload-file span {
  color: #fff;
  font-size: 12px;
}

@media (min-width: 640px) {
  .upload-file {
    padding: 10px;
    font-size: 15px;
    border-radius: 7px;
  }

  .upload-file i {
    font-size: 28px;
    margin-bottom: 6px;
  }

  .upload-file span {
    font-size: 15px;
  }
}

.file-input {
  display: none;
}

#pixieEditor .tool-panel-container crop-drawer.controls-drawer {
  display: none;
}

toolbar .open-button {
  display: none;
}

toolbar>.right button:not(.close-button) {
  display: none;
}

toolbar>.right button:only-child {
  display: block;
}

toolbar .mobile-open-button {
  visibility: hidden;
}

#pixieEditor floating-object-controls {
  display: none;
}

#pixieEditor .cancel-button {
  display: none;
}

#pixieEditor .export-button {
  visibility: hidden;
}

#pixieEditor .info {
  height: 100%;
  display: flex;
  align-items: center;
}

#app #pixieEditor section {
  display: flex;
}

#pixieEditor editor-controls .button-with-image.no-label>img {
  width: auto;
  height: auto;
  max-width: 80px;
  max-height: 80px;
}

#pixieEditor editor-controls .button-with-image img {
  width: auto;
  height: auto;
  max-width: 60px;
  max-height: 60px;
}

.upload-icon {}

.mat-icon-button .mat-icon {
  vertical-align: middle;
}

.mat-icon {
  vertical-align: middle;
}


.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-badge-small .mat-badge-content {
  font-size: 6px
}

.mat-badge-large .mat-badge-content {
  font-size: 24px
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h5,
.mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px
}

.mat-h6,
.mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px
}

.mat-body-2,
.mat-body-strong {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px
}

.mat-caption,
.mat-small {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -.05em
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -.02em
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -.005em
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500
}

.mat-button-toggle,
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500
}

.mat-card-header .mat-card-title {
  font-size: 20px
}

.mat-card-content,
.mat-card-subtitle {
  font-size: 14px
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px
}

.mat-chip {
  font-size: 14px;
  font-weight: 500
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 18px
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-calendar-body {
  font-size: 13px
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid transparent
}

.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  -webkit-transform: translateY(-1.34375em) scale(.75);
  transform: translateY(-1.34375em) scale(.75);
  width: 133.33333333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: translateY(-1.34374em) scale(.75);
  transform: translateY(-1.34374em) scale(.75);
  width: 133.33334333%
}

.mat-form-field-label-wrapper {
  top: -.84375em;
  padding-top: .84375em
}

.mat-form-field-label {
  top: 1.34375em
}

.mat-form-field-underline {
  bottom: 1.34375em
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: .66666667em;
  top: calc(100% - 1.79166667em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: .4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  -webkit-transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
  -ms-transform: translateY(-1.28125em) scale(.75);
  width: 133.33333333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
  -ms-transform: translateY(-1.28124em) scale(.75);
  width: 133.33334333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
  -ms-transform: translateY(-1.28123em) scale(.75);
  width: 133.33335333%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: .54166667em;
  top: calc(100% - 1.66666667em)
}

@media print {

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    -webkit-transform: translateY(-1.28122em) scale(.75);
    transform: translateY(-1.28122em) scale(.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
    -webkit-transform: translateY(-1.28121em) scale(.75);
    transform: translateY(-1.28121em) scale(.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    -webkit-transform: translateY(-1.2812em) scale(.75);
    transform: translateY(-1.2812em) scale(.75)
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: .25em 0 .75em
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  -webkit-transform: translateY(-.59375em) scale(.75);
  transform: translateY(-.59375em) scale(.75);
  width: 133.33333333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: translateY(-.59374em) scale(.75);
  transform: translateY(-.59374em) scale(.75);
  width: 133.33334333%
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  -webkit-transform: translateY(-1.59375em) scale(.75);
  transform: translateY(-1.59375em) scale(.75);
  width: 133.33333333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: translateY(-1.59374em) scale(.75);
  transform: translateY(-1.59374em) scale(.75);
  width: 133.33334333%
}

.mat-grid-tile-footer,
.mat-grid-tile-header {
  font-size: 14px
}

.mat-grid-tile-footer .mat-line,
.mat-grid-tile-header .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-grid-tile-footer .mat-line:nth-child(n+2),
.mat-grid-tile-header .mat-line:nth-child(n+2) {
  font-size: 12px
}

input.mat-input-element {
  margin-top: -.0625em
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px
}

.mat-radio-button,
.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-select-trigger {
  height: 1.125em
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400
}

.mat-step-sub-label-error {
  font-weight: 400
}

.mat-step-label-error {
  font-size: 14px
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-tab-label,
.mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px
}

.mat-list-item,
.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-list-base .mat-list-item {
  font-size: 16px
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px
}

.mat-list-base .mat-list-option {
  font-size: 16px
}

.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px
}

.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2),
.mat-list-base[dense] .mat-list-option {
  font-size: 12px
}

.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px
}

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, .87)
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  color: rgba(0, 0, 0, .54)
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500
}

.mat-ripple {
  overflow: hidden;
  position: relative
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, -webkit-transform 0s cubic-bezier(0, 0, .2, 1);
  transition: opacity, transform 0s cubic-bezier(0, 0, .2, 1);
  transition: opacity, transform 0s cubic-bezier(0, 0, .2, 1), -webkit-transform 0s cubic-bezier(0, 0, .2, 1);
  -webkit-transform: scale(0);
  transform: scale(0)
}

@media (-ms-high-contrast: active) {
  .mat-ripple-element {
    display: none
  }
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

.cdk-overlay-container:empty {
  display: none
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}

@-webkit-keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@-webkit-keyframes cdk-text-field-autofill-end {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  -webkit-animation-name: cdk-text-field-autofill-start;
  animation-name: cdk-text-field-autofill-start
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  -webkit-animation-name: cdk-text-field-autofill-end;
  animation-name: cdk-text-field-autofill-end
}

textarea.cdk-textarea-autosize {
  resize: none
}

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, .1)
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, .04)
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, .04);
  color: rgba(0, 0, 0, .87)
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1565c0
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #009688
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, .38)
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, .54)
}

.mat-pseudo-checkbox::after {
  color: #fafafa
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #009688
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1565c0
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0
}

.mat-elevation-z0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12)
}

.mat-elevation-z8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z16 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z20 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z21 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z22 {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12)
}

.mat-elevation-z24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, .87)
}

.mat-theme-loaded-marker {
  display: none
}

.mat-autocomplete-panel {
  background: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #fff
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, .87)
}

@media (-ms-high-contrast: active) {
  .mat-badge-content {
    outline: solid 1px;
    border-radius: 0
  }

  .mat-checkbox-disabled {
    opacity: .5
  }
}

.mat-badge-accent .mat-badge-content {
  background: #009688;
  color: #fff
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: #f44336
}

.mat-badge {
  position: relative
}

.mat-badge-hidden .mat-badge-content {
  display: none
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, .38)
}

.mat-badge-content {
  color: #fff;
  background: #1565c0;
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  -webkit-transform: scale(.6);
  transform: scale(.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none
}

.mat-badge-content.mat-badge-active {
  -webkit-transform: none;
  transform: none
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px
}

.mat-bottom-sheet-container {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
  background: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: inherit;
  background: 0 0
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #1565c0
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #009688
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: #f44336
}

.mat-button.mat-accent[disabled],
.mat-button.mat-primary[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, .26)
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1565c0
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #009688
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent
}

.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
  opacity: .1;
  background-color: currentColor
}

.mat-button-focus-overlay {
  background: #000
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, .12)
}

.mat-fab,
.mat-flat-button,
.mat-mini-fab,
.mat-raised-button {
  color: rgba(0, 0, 0, .87);
  background-color: #fff
}

.mat-fab.mat-accent,
.mat-fab.mat-primary,
.mat-fab.mat-warn,
.mat-flat-button.mat-accent,
.mat-flat-button.mat-primary,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-accent,
.mat-mini-fab.mat-primary,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-accent,
.mat-raised-button.mat-primary,
.mat-raised-button.mat-warn {
  color: #fff
}

.mat-fab.mat-accent[disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled] {
  color: rgba(0, 0, 0, .26);
  background-color: rgba(0, 0, 0, .12)
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #1565c0
}

.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
  background-color: #009688
}

.mat-fab.mat-warn,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-warn {
  background-color: #f44336
}

.mat-fab.mat-accent .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-flat-button.mat-accent .mat-ripple-element,
.mat-flat-button.mat-primary .mat-ripple-element,
.mat-flat-button.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-flat-button:not([class*=mat-elevation-z]),
.mat-stroked-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([class*=mat-elevation-z]),
.mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]),
.mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-fab[disabled]:not([class*=mat-elevation-z]),
.mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group,
.mat-button-toggle-standalone {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle {
  color: rgba(0, 0, 0, .38)
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, .12)
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, .87);
  background: #fff
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #000
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: 1px solid rgba(0, 0, 0, .12)
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, .54)
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, .87)
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, .26);
  background-color: #eee
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #fff
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd
}

.mat-card {
  background: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, .54)
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, .54)
}

.mat-checkbox-checkmark {
  fill: #fafafa
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important
}

@media (-ms-high-contrast: black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important
  }
}

.mat-checkbox-mixedmark {
  background-color: #fafafa
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #1565c0
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #009688
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
  background-color: #f44336
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, .54)
}

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: 0 0
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #1565c0
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #009688
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: #f44336
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, .87)
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, .87);
  opacity: .4
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: .4
}

.mat-chip.mat-standard-chip::after {
  background: #000
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1565c0;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #009688;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background: rgba(255, 255, 255, .1)
}

.mat-table {
  background: #fff
}

.mat-table tbody,
.mat-table tfoot,
.mat-table thead,
.mat-table-sticky,
[mat-footer-row],
[mat-header-row],
[mat-row],
mat-footer-row,
mat-header-row,
mat-row {
  background: inherit
}

mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-bottom-color: rgba(0, 0, 0, .12)
}

.mat-header-cell {
  color: rgba(0, 0, 0, .54)
}

.mat-cell,
.mat-footer-cell {
  color: rgba(0, 0, 0, .87)
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, .54)
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: rgba(0, 0, 0, .54)
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, .38)
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, .12)
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, .54)
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, .87);
  border-color: transparent
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, .38)
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, .04)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, .38)
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, .18)
}

.mat-calendar-body-selected {
  background-color: #1565c0;
  color: #fff
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: rgba(21, 101, 192, .4)
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  background-color: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #009688;
  color: #fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: rgba(0, 150, 136, .4)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, .4)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content-touch {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-datepicker-toggle-active {
  color: #1565c0
}

.mat-datepicker-toggle-active.mat-accent {
  color: #009688
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336
}

.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
  background: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel {
  background: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: rgba(0, 0, 0, .04)
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #fff
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, .87)
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: rgba(0, 0, 0, .54)
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, .26)
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title {
  color: inherit
}

.mat-form-field-label,
.mat-hint {
  color: rgba(0, 0, 0, .6)
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #1565c0
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #009688
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336
}

.mat-focused .mat-form-field-required-marker {
  color: #009688
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, .87)
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1565c0
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #009688
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1565c0
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #009688
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
  color: #f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336
}

.mat-error {
  color: #f44336
}

.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, .54)
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
  background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, .04)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, .02)
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, .12)
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, .87)
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1565c0
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #009688
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, .06)
}

.mat-icon.mat-primary {
  color: #1565c0
}

.mat-icon.mat-accent {
  color: #009688
}

.mat-icon.mat-warn {
  color: #f44336
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, .54)
}

.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-input-element {
  caret-color: #1565c0
}

.mat-input-element::-ms-input-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-input-element::placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-accent .mat-input-element {
  caret-color: #009688
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: #f44336
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, .87)
}

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, .54)
}

.mat-list-item-disabled {
  background-color: #eee
}

.mat-list-option:focus,
.mat-list-option:hover,
.mat-nav-list .mat-list-item:focus,
.mat-nav-list .mat-list-item:hover {
  background: rgba(0, 0, 0, .04)
}

.mat-menu-panel {
  background: #fff
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-menu-item {
  background: 0 0;
  color: rgba(0, 0, 0, .87)
}

.mat-menu-item[disabled],
.mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, .38)
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, .54)
}

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, .04)
}

.mat-paginator {
  background: #fff
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, .54)
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, .54);
  border-right: 2px solid rgba(0, 0, 0, .54)
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, .54)
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, .38)
}

.mat-progress-bar-background {
  fill: #64b5f6
}

.mat-progress-bar-buffer {
  background-color: #64b5f6
}

.mat-progress-bar-fill::after {
  background-color: #1565c0
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b2dfdb
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b2dfdb
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #009688
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #1565c0
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: #009688
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: #f44336
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, .54)
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1565c0
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1565c0
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #009688
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #009688
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, .38)
}

.mat-radio-button .mat-ripple-element {
  background-color: #000
}

.mat-select-value {
  color: rgba(0, 0, 0, .87)
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, .38)
}

.mat-select-arrow {
  color: rgba(0, 0, 0, .54)
}

.mat-select-panel {
  background: #fff
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, .12)
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1565c0
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #009688
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, .38)
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, .87)
}

.mat-drawer {
  background-color: #fff;
  color: rgba(0, 0, 0, .87)
}

.mat-drawer.mat-drawer-push {
  background-color: #fff
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-drawer-side {
  border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-drawer-side.mat-drawer-end,
[dir=rtl] .mat-drawer-side {
  border-left: 1px solid rgba(0, 0, 0, .12);
  border-right: none
}

[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, .6)
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #009688
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 150, 136, .54)
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #009688
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1565c0
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(21, 101, 192, .54)
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1565c0
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, .54)
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #000
}

.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  background-color: #fafafa
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .38)
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
  background-color: #1565c0
}

.mat-primary .mat-slider-thumb-label-text {
  color: #fff
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: #009688
}

.mat-accent .mat-slider-thumb-label-text {
  color: #fff
}

.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label,
.mat-warn .mat-slider-track-fill {
  background-color: #f44336
}

.mat-warn .mat-slider-thumb-label-text {
  color: #fff
}

.mat-slider-focus-ring {
  background-color: rgba(0, 150, 136, .2)
}

.cdk-focused .mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, .38)
}

.mat-slider-disabled .mat-slider-thumb,
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, .12)
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, .87)
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .26);
  background-color: transparent
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .38)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .26)
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, .7)
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: rgba(0, 0, 0, .04)
}

@media (hover: none) {
  .mat-step-header:hover {
    background: 0 0
  }
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, .54)
}

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, .54);
  color: #fff
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #1565c0;
  color: #fff
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, .87)
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: #fff
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, .12)
}

.mat-horizontal-stepper-header::after,
.mat-horizontal-stepper-header::before,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-sort-header-arrow {
  color: #757575
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.mat-tab-group-inverted-header .mat-tab-header,
.mat-tab-group-inverted-header .mat-tab-nav-bar {
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: none
}

.mat-tab-label,
.mat-tab-link {
  color: rgba(0, 0, 0, .87)
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, .87)
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, .38)
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(100, 181, 246, .3)
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1565c0
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, .3)
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #009688
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(100, 181, 246, .3)
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #1565c0
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, .3)
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #009688
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-toolbar {
  background: #f5f5f5;
  color: rgba(0, 0, 0, .87)
}

.mat-toolbar.mat-primary {
  background: #1565c0;
  color: #fff
}

.mat-toolbar.mat-accent {
  background: #009688;
  color: #fff
}

.mat-toolbar.mat-warn {
  background: #f44336;
  color: #fff
}

.mat-toolbar .mat-focused .mat-form-field-ripple,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-form-field-underline {
  background-color: currentColor
}

.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-select-value {
  color: inherit
}

.mat-toolbar .mat-input-element {
  caret-color: currentColor
}

.mat-tooltip {
  background: rgba(97, 97, 97, .9)
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #fff
}

.mat-nested-tree-node,
.mat-tree-node {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, .87)
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, .7);
  background: #323232;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-simple-snackbar-action {
  color: #009688
}

pixie-editor button.no-style {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border-radius: 0;
  min-width: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  outline: 0;
  cursor: pointer;
  text-align: initial
}

pixie-editor .mat-flat-button.mat-gray {
  background-color: #f1f1f1;
  color: rgba(0, 0, 0, .38);
  border: 1px solid #e0e0e0
}

pixie-editor .mat-flat-button.mat-gray:hover {
  background-color: #f6f6f6
}

pixie-editor .input-container {
  display: block;
  width: 100%
}

pixie-editor .input-container .error {
  margin-top: 6px;
  color: #f44336
}

pixie-editor .input-container p {
  color: rgba(0, 0, 0, .54);
  font-size: 13px
}

pixie-editor .input-container label {
  color: inherit;
  display: block;
  padding-bottom: 5px
}

pixie-editor .input-container .fake-input,
pixie-editor .input-container input,
pixie-editor .input-container select,
pixie-editor .input-container textarea {
  width: 100%;
  height: 46px;
  color: inherit;
  border: 1px solid #e0e0e0;
  padding: 12px;
  line-height: normal;
  font-size: 14px;
  font-family: inherit;
  background-color: inherit;
  box-sizing: border-box;
  border-radius: 4px
}

pixie-editor .input-container .fake-input:invalid,
pixie-editor .input-container input:invalid,
pixie-editor .input-container select:invalid,
pixie-editor .input-container textarea:invalid {
  box-shadow: none
}

pixie-editor .input-container .fake-input:disabled,
pixie-editor .input-container input:disabled,
pixie-editor .input-container select:disabled,
pixie-editor .input-container textarea:disabled {
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, .38)
}

pixie-editor .input-container .fake-input[readonly],
pixie-editor .input-container input[readonly],
pixie-editor .input-container select[readonly],
pixie-editor .input-container textarea[readonly] {
  background-color: #fbfbfb
}

pixie-editor .input-container textarea {
  height: auto;
  padding: 10px
}

pixie-editor .input-container .input-with-action {
  display: flex
}

pixie-editor .input-container .input-with-action .mat-flat-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

pixie-editor .many-inputs>.input-container {
  margin-bottom: 20px
}

pixie-editor .be-modal {
  width: 530px
}

pixie-editor .be-modal .dialog-loading-bar {
  position: absolute;
  top: 0;
  left: 0
}

pixie-editor .be-modal .mat-dialog-container {
  position: relative
}

pixie-editor .be-modal .modal-header {
  display: flex;
  align-items: center;
  position: relative;
  color: #494949;
  margin: 0 0 35px
}

pixie-editor .be-modal .modal-header>h2 {
  font-size: 1.6rem;
  font-weight: 600;
  flex: 1 1 auto;
  margin: 0;
  line-height: 30px
}

pixie-editor .be-modal .mat-dialog-content {
  position: relative;
  padding-top: 1px;
  padding-bottom: 1px
}

pixie-editor .be-modal .mat-dialog-actions {
  justify-content: flex-end;
  padding-bottom: 16px
}

pixie-editor .be-modal .close-button {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, .5);
  transition: -webkit-transform ease .2s;
  transition: transform ease .2s;
  transition: transform ease .2s, -webkit-transform ease .2s;
  z-index: 999
}

pixie-editor .be-modal .close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

pixie-editor .be-modal .close-button .mat-icon {
  width: 24px;
  height: 24px
}

pixie-editor .be-modal .errors {
  color: #f44336
}

pixie-editor .be-modal .errors>.error {
  padding: 10px 0
}

pixie-editor .be-modal .errors>.error:last-of-type {
  margin-bottom: 20px
}

pixie-editor .be-modal .input-container select,
pixie-editor .be-modal .input-container>input,
pixie-editor .be-modal .input-container>textarea {
  background-color: transparent
}

pixie-editor .be-modal .input-container>.error {
  color: #f44336
}

pixie-editor .be-modal .input-container>p {
  margin-top: 8px;
  line-height: 1.5;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, .54)
}

pixie-editor .be-modal .no-input-error {
  padding: 10px 0;
  color: #f44336
}

pixie-editor .mat-dialog-container {
  background-color: #fff;
  color: rgba(0, 0, 0, .87)
}

pixie-editor .ps {
  position: relative;
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto
}

pixie-editor .ps>.ps__rail-x {
  opacity: .6
}

pixie-editor .ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0;
  position: absolute
}

pixie-editor .ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute
}

pixie-editor .ps--active-x>.ps__rail-x,
pixie-editor .ps--active-y>.ps__rail-y {
  display: block;
  background-color: transparent
}

pixie-editor .ps--focus>.ps__rail-x,
pixie-editor .ps--focus>.ps__rail-y,
pixie-editor .ps--scrolling-x>.ps__rail-x,
pixie-editor .ps--scrolling-y>.ps__rail-y,
pixie-editor .ps:hover>.ps__rail-x,
pixie-editor .ps:hover>.ps__rail-y {
  opacity: .6
}

pixie-editor .ps .ps__rail-x.ps--clicking,
pixie-editor .ps .ps__rail-x:focus,
pixie-editor .ps .ps__rail-x:hover,
pixie-editor .ps .ps__rail-y.ps--clicking,
pixie-editor .ps .ps__rail-y:focus,
pixie-editor .ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: .9
}

pixie-editor .ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute
}

pixie-editor .ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute
}

pixie-editor .ps__rail-x.ps--clicking .ps__thumb-x,
pixie-editor .ps__rail-x:focus>.ps__thumb-x,
pixie-editor .ps__rail-x:hover>.ps__thumb-x {
  background-color: #999;
  height: 11px
}

pixie-editor .ps__rail-y.ps--clicking .ps__thumb-y,
pixie-editor .ps__rail-y:focus>.ps__thumb-y,
pixie-editor .ps__rail-y:hover>.ps__thumb-y {
  background-color: #999;
  width: 11px
}

@supports (-ms-overflow-style:none) {
  pixie-editor .ps {
    overflow: auto !important
  }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  pixie-editor .ps {
    overflow: auto !important
  }
}

pixie-editor .cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, .15)
}

pixie-editor .floating-panel {
  display: block;
  min-width: 250px;
  background-color: #fff;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

pixie-editor .floating-panel .header {
  display: flex;
  align-items: center;
  padding: 12px;
  color: #fff;
  border-bottom: 1px solid #e0e0e0;
  background-color: #1565c0
}

pixie-editor .floating-panel .header .close-button {
  margin-left: auto;
  width: 24px;
  height: 24px;
  line-height: 24px
}

pixie-editor .floating-panel .items-list {
  height: auto;
  max-height: 300px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

pixie-editor .floating-panel .items-list .reorder-item-wrapper {
  padding: 5px 10px
}

pixie-editor .floating-panel .items-list .reorder-item-wrapper .floating-panel-item {
  margin: 0
}

pixie-editor .floating-panel-item {
  display: flex;
  align-items: center;
  height: 48px;
  margin: 10px;
  padding: 0 5px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: box-shadow .3s ease;
  text-transform: capitalize
}

pixie-editor .floating-panel-item.active {
  border-color: #1976d2
}

pixie-editor .floating-panel-item.disabled {
  cursor: default;
  pointer-events: none;
  opacity: .7
}

pixie-editor .floating-panel-item:hover {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

pixie-editor .floating-panel-item .list-icon {
  margin-right: 5px
}

pixie-editor.mode-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999
}

pixie-editor.mode-overlay image-editor {
  position: fixed;
  left: 5%;
  top: 5%;
  bottom: 5%;
  right: 5%;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
  z-index: 10;
  box-shadow: inset 0 -2px 5px rgba(61, 61, 61, .5), 0 6px 44px rgba(0, 0, 0, .7);
  border-radius: 6px;
  overflow: hidden
}

pixie-editor.mode-overlay .overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5)
}

pixie-editor.ui-compact navigation-bar .scroll-container {
  justify-content: flex-start
}

pixie-editor.ui-compact navigation-bar .scroll-container .separator {
  display: none
}

pixie-editor .drawer-wrapper {
  overflow: auto;
  margin: 0 10px
}

pixie-editor .tool-panel-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px
}

pixie-editor .tool-panel-container .controls-drawer {
  display: block;
  flex: 1 1 auto;
  min-width: 0;
  height: 100%
}

pixie-editor .tool-panel-container .content {
  display: flex;
  align-items: center;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative
}

pixie-editor .tool-panel-container .content> :first-child {
  margin-left: auto
}

pixie-editor .tool-panel-container .content> :last-child {
  margin-right: auto
}

pixie-editor .tool-panel-container .apply-button,
pixie-editor .tool-panel-container .cancel-button {
  width: auto;
  flex-shrink: 0
}

@media only screen and (max-width: 800px) {
  pixie-editor .floating-panel {
    width: 90%
  }

  pixie-editor .tool-panel-container {
    padding: 0
  }

  pixie-editor .tool-panel-container .apply-button,
  pixie-editor .tool-panel-container .cancel-button {
    display: none
  }
}

pixie-editor.theme-dark .canvas-wrapper {
  background-image: none !important;
  background-color: #1b2024
}

pixie-editor.theme-dark toolbar {
  background: #2e333c;
  color: #dae5ef
}

pixie-editor.theme-dark editor-controls {
  background-color: #23272e;
  color: #dae5ef
}

pixie-editor.theme-dark editor-controls .control-button mat-icon {
  color: inherit;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6))
}

pixie-editor.theme-dark editor-controls .control-button .name {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, .6)
}

pixie-editor.theme-dark editor-controls .round-action-button {
  border-color: #39404b
}

pixie-editor.theme-dark editor-controls color-widget .color {
  border-color: transparent
}

pixie-editor.theme-dark editor-controls .button-with-image {
  background-color: #2e333c;
  border-color: #39404b;
  color: inherit
}

pixie-editor.theme-dark editor-controls .separator {
  background-color: #39404b
}

pixie-editor.theme-dark .drawer-wrapper .back-button {
  background-color: #2e333c;
  border: 1px solid #39404b;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .6);
  color: inherit
}

pixie-editor.theme-dark .drawer-wrapper .mat-raised-button[disabled] {
  color: rgba(255, 255, 255, .3);
  background-color: #2e333c
}

pixie-editor.theme-dark .drawer-wrapper .input-container input,
pixie-editor.theme-dark .drawer-wrapper .select-font-button {
  border-color: #39404b
}

pixie-editor.theme-dark .drawer-wrapper .mat-checkbox-frame {
  border-color: #4f5868
}

pixie-editor.theme-dark floating-object-controls {
  background-color: transparent;
  box-shadow: none;
  color: #dae5ef
}

pixie-editor.theme-dark floating-object-controls .mat-icon-button {
  background-color: #2e333c
}

pixie-editor.theme-dark floating-object-controls .mat-icon-button:disabled {
  color: rgba(255, 255, 255, .3)
}

pixie-editor.theme-dark .floating-panel {
  background-color: #23272e;
  color: #dae5ef
}

pixie-editor.theme-dark .floating-panel .header {
  background-color: #2e333c;
  color: inherit;
  border-color: #39404b
}

pixie-editor.theme-dark .floating-panel .item {
  border-color: #2e333c;
  background-color: #272c34
}

pixie-editor.theme-dark .floating-panel .item.active {
  color: #fefefe;
  font-weight: 500
}

pixie-editor.theme-dark open-sample-image-panel .separator hr {
  background-color: #39404b
}

pixie-editor.theme-dark open-sample-image-panel .separator .text {
  background-color: #23272e
}

pixie-editor.theme-dark .mat-menu-panel {
  background-color: #2e333c
}

pixie-editor.theme-dark .mat-menu-panel .mat-menu-item {
  color: #dae5ef
}

pixie-editor.theme-dark .ps__rail-x:focus>.ps__thumb-x,
pixie-editor.theme-dark .ps__rail-x:hover>.ps__thumb-x,
pixie-editor.theme-dark .ps__thumb-x {
  background-color: #444c59
}

pixie-editor.theme-dark .ps__rail-x:focus,
pixie-editor.theme-dark .ps__rail-x:hover,
pixie-editor.theme-dark .ps__rail-y:focus,
pixie-editor.theme-dark .ps__rail-y:hover {
  background-color: transparent
}

pixie-editor.theme-dark ::-webkit-scrollbar:hover {
  background-color: transparent
}

pixie-editor.theme-dark ::-webkit-scrollbar:hover ::-webkit-scrollbar-thumb {
  background-color: #2e333c
}

pixie-editor.theme-dark ::-webkit-scrollbar-thumb {
  background-color: #444c59
}

pixie-editor .scroll-container,
pixie-editor .scroll-container-x {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden
}

pixie-editor .scroll-container-x.ps,
pixie-editor .scroll-container.ps {
  position: relative;
  overflow: hidden
}

pixie-editor .scroll-container-x {
  overflow-y: hidden;
  overflow-x: auto
}

pixie-editor .block,
pixie-editor img {
  display: block
}

pixie-editor a {
  text-decoration: none;
  color: #1565c0
}

pixie-editor a.hover-underline {
  color: inherit
}

pixie-editor a.hover-underline:hover {
  text-decoration: underline
}

pixie-editor h1,
pixie-editor h2,
pixie-editor h3,
pixie-editor h4,
pixie-editor h5 {
  font-weight: 500
}

pixie-editor .hidden {
  display: none !important;
  visibility: hidden
}

pixie-editor .visually-hidden {
  visibility: hidden;
  pointer-events: none
}

pixie-editor .error {
  color: #f44336
}

pixie-editor .error.margin-bottom {
  margin-bottom: 20px
}

pixie-editor .error.margin-top {
  margin-top: 20px
}

pixie-editor .file-over-dropzone * {
  pointer-events: none !important
}

pixie-editor .material-panel {
  border: none;
  box-shadow: 1px 0 20px rgba(0, 0, 0, .05);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  padding: 15px
}

pixie-editor ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent
}

pixie-editor ::-webkit-scrollbar:hover {
  background-color: #f1f1f1
}

pixie-editor ::-webkit-scrollbar:hover ::-webkit-scrollbar-thumb {
  background-color: #999696
}

pixie-editor ::-webkit-scrollbar-thumb {
  background-color: #d8d7d7;
  border-radius: 3px
}

pixie-editor *,
pixie-editor :after,
pixie-editor :before {
  box-sizing: inherit
}

image-editor,
pixie-editor {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box
}

pixie-editor {
  touch-action: manipulation
}

image-editor {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: rgba(0, 0, 0, .87);
  line-height: 1.42857143;
  font-size: 14px;
  overflow: hidden
}

/*# sourceMappingURL=styles.092790e6fc8a87ec6ada.css.map*/
</style>
