export const currency = {
  KZT: '₸',
  RUB: '₽',
  EUR: '€',
  BYN: 'Br',
  USD: '$'
}

export const currencyCode = {
  ru: 'RUB',
  kz: 'KZT',
  by: 'BYN',
  es: 'EUR',
  us: 'USD'
}

export const regionCloudPaymentsPublicId = {
  ru: 'VUE_APP_GATE_CLOUDPAYMENTS_PUBLIC_ID_RU',
  kz: 'VUE_APP_GATE_CLOUDPAYMENTS_PUBLIC_ID_KZ',
  by: 'VUE_APP_GATE_CLOUDPAYMENTS_PUBLIC_ID_BY'
}

export const paymentGate = {
  cloud: 'cloudpayments',
  cloudkz: 'cloudpayments-kz',
  cloudby: 'cloudpayments-by',
  sbp: 'tinkoffsbp',
  sberbank: 'sberbank'
}

export const timeZone = {
  kz: {
    zone: 'UTC+5',
    zoneId: 'Asia/Almaty',
  },
  ru: {
    zone: 'UTC+3',
    zoneId: 'Europe/Moscow',
  },
  es: {
    zone: 'UTC+1',
    zoneSummer: 'UTC+2',
    zoneId: 'CET',
  },
  by: {
    zone: 'UTC+3',
    zoneId: 'Europe/Minsk',
  },
  us: {
    zone: 'UTC-5',
    zoneSummer: 'UTC-4',
    zoneId: 'ET',
  },
}

export const domains = [
  'yahoo.com', 'hotmail.com', 'gmail.com', 'me.com', 'aol.com', 'mac.com', 'live.com',
  'comcast.net', 'googlemail.com', 'msn.com', 'hotmail.co.uk', 'yahoo.co.uk', 'facebook.com',
  'verizon.net', 'sbcglobal.net', 'att.net', 'gmx.com', 'outlook.com', 'icloud.com', 'mail.ru',
  'yandex.ru', 'ya.ru', 'yandex.com', 'rambler.ru', 'bk.ru', 'inbox.ru', 'list.ru',
  'protonmail.com', 'pm.me', 'narod.ru'
]

export const defaultDomains = {
  kz: ['mail.ru', 'gmail.com', 'icloud.com', 'yandex.ru', 'outlook.com', 'rambler.ru'],
  ru: ['mail.ru', 'gmail.com', 'icloud.com', 'yandex.ru', 'outlook.com', 'rambler.ru'],
  by: ['mail.ru', 'gmail.com', 'icloud.com', 'yandex.ru', 'outlook.com', 'rambler.ru'],
  es: ['gmail.com', 'icloud.com', 'outlook.com', 'hotmail.com', 'yahoo.com', 'live.com'],
  us: ['gmail.com', 'icloud.com', 'outlook.com', 'hotmail.com', 'yahoo.com', 'live.com'],
}

export const widgetParams = ['productId', 'code', 'face', 'color', 'isDemo', 'locale', 'key']
