<template>
  <default-modal disableClose class="modal-pay-fail" @close="$emit('close')">
    <template #title>
      {{ title }}
    </template>
    <div class="modal-pay-fail__desc" v-html="text"></div>
    <div class="modal-pay-fail__btns">
      <default-btn @click="$emit('close')" small>
        {{ buttonOk }}
      </default-btn>
    </div>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'

export default Vue.extend({
  name: 'ModalPayFail',
  components: { DefaultModal, DefaultBtn },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonOk: {
      type: String,
      default: ''
    },
    store: {
      type: Object
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-pay-fail {
  &__desc {
    font-size: rem(13);
    line-height: 140%;
    color: #6E7882;
    text-align: center;
    margin-bottom: rem(30);

    p {
      margin-top: 0;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
