<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7941 3.29494C13.2295 3.10345 12.6257 3 11.9999 3C9.15341 3 6.76211 5.14032 6.44776 7.96942L6.19596 10.2356L6.18951 10.2933C6.06056 11.417 5.6948 12.5005 5.11636 13.4725L5.08658 13.5222L4.50854 14.4856C3.98399 15.3599 3.72171 15.797 3.77833 16.1559C3.81601 16.3946 3.93889 16.6117 4.12426 16.7668C4.40283 17 4.9126 17 5.93215 17H18.0677C19.0872 17 19.597 17 19.8756 16.7668C20.0609 16.6117 20.1838 16.3946 20.2215 16.1559C20.2781 15.797 20.0159 15.3599 19.4913 14.4856L18.9133 13.5222L18.8835 13.4725C18.4272 12.7059 18.1033 11.8698 17.9235 10.9994C15.1973 10.9586 12.9999 8.73592 12.9999 6C12.9999 5.00331 13.2915 4.07473 13.7941 3.29494ZM16.274 4.98883C16.0998 5.28551 15.9999 5.63109 15.9999 6C15.9999 6.94979 16.662 7.74494 17.5498 7.94914C17.4204 6.82135 16.9607 5.80382 16.274 4.98883Z" fill="url(#paint0_linear_83_4817)"/>
    <path d="M9 17C9 17.394 9.0776 17.7841 9.22836 18.1481C9.37913 18.512 9.6001 18.8427 9.87868 19.1213C10.1573 19.3999 10.488 19.6209 10.8519 19.7716C11.2159 19.9224 11.606 20 12 20C12.394 20 12.7841 19.9224 13.1481 19.7716C13.512 19.6209 13.8427 19.3999 14.1213 19.1213C14.3999 18.8427 14.6209 18.512 14.7716 18.1481C14.9224 17.7841 15 17.394 15 17L12 17H9Z" fill="url(#paint1_linear_83_4817)"/>
    <circle cx="18" cy="6" r="2.5" fill="url(#paint2_linear_83_4817)" stroke="url(#paint3_linear_83_4817)"/>
    <defs>
      <linearGradient id="paint0_linear_83_4817" x1="2.5585" y1="4.55292" x2="22.3224" y2="16.6945" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF944D"/>
        <stop offset="0.480912" stop-color="#FA6400"/>
        <stop offset="1" stop-color="#FF944D"/>
      </linearGradient>
      <linearGradient id="paint1_linear_83_4817" x1="15.4419" y1="14.6655" x2="7.64645" y2="18.7392" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF944D"/>
        <stop offset="0.480912" stop-color="#FA6400"/>
        <stop offset="1" stop-color="#FF944D"/>
      </linearGradient>
      <linearGradient id="paint2_linear_83_4817" x1="15.7054" y1="4.44369" x2="20.9024" y2="7.15947" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF944D"/>
        <stop offset="0.480912" stop-color="#FA6400"/>
        <stop offset="1" stop-color="#FF944D"/>
      </linearGradient>
      <linearGradient id="paint3_linear_83_4817" x1="15.7054" y1="4.44369" x2="20.9024" y2="7.15947" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF944D"/>
        <stop offset="0.480912" stop-color="#FA6400"/>
        <stop offset="1" stop-color="#FF944D"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconBell'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(24);
  height: rem(24);
}
</style>
