<template>
  <default-modal disableClose class="modal-send-test" @close="$emit('close')">
    <template #title>
      <span v-html="title"></span>
    </template>
    <default-btn @click="$emit('close')">
      {{ buttonOk }}
    </default-btn>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'

export default Vue.extend({
  name: 'ModalSendTest',
  components: { DefaultModal, DefaultBtn },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonOk: {
      type: String,
      default: ''
    },
  },
});
</script>

<style scoped>

</style>
