const module = {
  namespaced: true,
  state: {
    region: null,
    macroregion: null,
    country: null,
    currency: null,
    currencyCode: null,
    timezone: null,
  },
  getters: {},
  mutations: {
    setRegion (state, data) {
      state.region = data
    },
    setMacroregion (state, data) {
      state.macroregion = data
    },
    setCountry (state, data) {
      state.country = data
    },
    setCurrency (state, data) {
      state.currency = data
    },
    setCurrencyCode (state, data) {
      state.currencyCode = data
    },
  },
  actions: {
    setRegion: ({ commit }, payload) => {
      commit('setRegion', payload)
    },
    setMacroregion: ({ commit }, payload) => {
      commit('setMacroregion', payload)
    },
    setCountry: ({ commit }, payload) => {
      commit('setCountry', payload)
    },
    setCurrency: ({ commit }, payload) => {
      commit('setCurrency', payload)
    },
    setCurrencyCode: ({ commit }, payload) => {
      commit('setCurrencyCode', payload)
    },
  }
}

export default module
