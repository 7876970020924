<template>
  <div class="slider-sic">
    <Flicking :options="options" :plugins="plugins">
      <template v-if="product.id === 705">
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'any'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <ComputerCart />
          <Printer />
        </template>
      </template>
      <template v-else>
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'no'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <OfflineStore />
          <Printer />
        </template>
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'any'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <ComputerCart />
          <Smartphone />
        </template>
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'eshop'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <OnlineStore />
          <ComputerCart />
        </template>
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'offline'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <OfflineStore />
          <Smartphone />
        </template>
        <template v-if="$store.state.main.product.properties.digital_acceptance === 'exchange'">
          <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
          <Exchange />
          <Mail />
        </template>
      </template>
      <div slot="viewport" class="flicking-pagination"></div>
      <Pagination v-if="false" />
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from '@egjs/vue-flicking'
import { Pagination } from '@egjs/flicking-plugins'

import Calendar from '../blocks/Calendar.vue'
import ComputerCart from '../blocks/ComputerCart.vue'
import Exchange from '../blocks/Exchange.vue'
import OfflineStore from '../blocks/OfflineStore.vue'
import OnlineStore from '../blocks/OnlineStore.vue'
import Printer from '../blocks/Printer.vue'
import Mail from '../blocks/Mail.vue'
import Smartphone from '../blocks/Smartphone.vue'

import '@egjs/vue-flicking/dist/flicking.css';

import { scrollIntoView } from 'seamless-scroll-polyfill'

export default {
  components: {
    Flicking,
    Pagination,
    Calendar,
    ComputerCart,
    Exchange,
    OfflineStore,
    OnlineStore,
    Printer,
    Mail,
    Smartphone,
  },

  data: () => ({
    options: {
      circular: false,
      bound: false,
      horizontal: true,
      adaptive: true,
      renderOnlyVisible: true,
      resizeOnContentsReady: false,
      duration: 600,
      threshold: 0,
      changeOnHold: false,
      moveType: "snap",
      useFractionalSize: true
    },
    plugins: [new Pagination({ type: 'bullet' })],
    isVisible: true
  }),
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    hasCustomConditions() {
      return typeof this.$store.state.main.options.pages !== 'undefined' && typeof this.$store.state.main.options.pages.conditions !== 'undefined'
    }
  },
  methods: {
    showHideConditions() {
      this.isVisible = !this.isVisible
    },
    scrollToText() {
      if (this.$store.state.main.isMobile) {
        this.isVisible = true

        this.$nextTick(() => {
          const basketBottomElement = document.querySelector('.conditions__text')

          if (basketBottomElement) {
            scrollIntoView(basketBottomElement, {
              block: 'start',
              behavior: 'smooth'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-sic {
  width: rem(300);
  min-height: rem(190);
  overflow: hidden;
  padding-bottom: rem(16);

  &__panel {
    width: rem(300);
    padding-bottom: rem(40);
  }

  &::v-deep {
    .flicking-viewport {
      background-color: #FFFFFF;
      overflow: visible;
      border-radius: rem(20);
      transition: height 500ms;
    }

    .flicking-pagination {
      width: 100%;
      font-size: 0;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: rem(-16);
      z-index: 2;

      // bottom: rem(-40);
      .flicking-pagination-scroll {
        width: rem(6);
        height: rem(6);
        background-color: #666666;
        font-size: 0;
      }

      .flicking-pagination-bullet {
        width: rem(6);
        height: rem(6);
        background-color: #666666;
        display: inline-block;
        margin: 0 rem(3);
        cursor: pointer;
        font-size: 0;
        border-radius: 50%;
      }

      .flicking-pagination-bullet-active {
        width: rem(16);
        background: var(--theme-primary-background-component);
        border-radius: rem(100);
      }

      .flicking-pagination-bullets,
      .flicking-pagination-scroll {
        font-size: 0;
      }
    }
  }

  .short-info-block {
    &__item {
      max-width: rem(300);
      width: 100%;
      min-height: rem(190);
      position: relative;
      text-align: center;
      box-sizing: border-box;
      padding: rem(30) rem(20);

      @include mobile {
        min-height: rem(173);
      }
    }
  }
}

.slider-sic::v-deep {
  .short-info-block__icon {
    height: rem(40);
    width: rem(40);
    background: var(--theme-primary-background-component);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto rem(16);

    svg {
      width: rem(16);
      height: rem(16);
    }
  }

  .short-info-block__text {
    font-size: rem(13);
    line-height: 160%;
    color: #666666;
  }

  .short-info-block__text {
    .btn {
      font-size: rem(13);
    }
  }
}
</style>
