import Vue from 'vue'
import axios from 'axios'

export default {
  getVideoUrl(uid) {
    const key = process.env.VUE_APP_LIVSTICK_KEY
    const url = `${process.env.VUE_APP_LIVSTICK_API}crid/${key}/${uid}/contentUrl`

    return axios
      .get(url)
      .then((response) => {
        return response.data.videoUrl
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Vue.$logger.error('video invalid uid', error.request)
          throw new Error('invalid_uid_error')
        }
        if (error.response.status >= 500) {
          throw new Error('server_error')
        }
        if (error.request) {
          Vue.$logger.error('video', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
}
