<template>
  <div v-if="allowVideo" class="video-block">
    <div class="video-block-info">
      <div class="video-block-info__wrapper">
        <div class="video-block-info__top">
          <div class="video-block-info__left">
            <div class="video-block-info__title">
              <span v-html="$t('video.info_block.title')"></span>
            </div>
            <div class="video-block-info__text">
              <span v-html="$t('video.info_block.desc')"></span>
            </div>
            <div class="video-block-info__checkbox">
              <div class="widget-input-checkbox">
                <div class="widget-input-checkbox__wrapper">
                  <input class="widget-input-checkbox__input" :class="{
                    'widget-input-checkbox__input--active': addVideo,
                    'widget-video-block--active': addVideo,
                    'widget-video-block--not-active': !addVideo
                  }" id="checkedPhoneNumberBlockEmailEdit" v-model="addVideo" type="checkbox" />
                  <label for="checkedPhoneNumberBlockEmailEdit">
                    <div class="widget-input-checkbox__icon"
                      :class="{ 'widget-input-checkbox__icon--checked': addVideo }">
                      <IconCheckEs v-if="addVideo" />
                    </div>
                    <span>{{ $t('video.add') }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="video-block-info__right">
            <div class="video-block-info__image">
              <img
                :src="$store.state.main.isMobile ? require('../assets/img/video-block-es.png') : require('../assets/img/video-block-es.png')" />
            </div>
          </div>
        </div>

        <div class="video-block__video" v-if="addVideo">
          <div v-if="isShowingRecordingWindow">
            <div v-if="isActiveCamera">
              <div id="livstick-plugin" v-if="isShowingPlugin"></div>
              <div v-else-if="hasError">{{ $t('video.upload_error') }}</div>
              <div v-else-if="isGettingUrl">
                <div class="sc-bdvvtL fFSFRs">
                  <div class="spinner"></div>
                  <div id="progress"></div>
                </div>
              </div>
              <div v-else>{{ $t('video.is_saved') }}</div>
            </div>
            <div v-else>{{ $t('video.prohibited_access') }}</div>
          </div>
          <div class="preview" v-else-if="hasPreview">
            <div class="preview__video">
              <video :src="currentCard.videoUrl" />
              <default-btn class="preview__video-remove" round @click="removeSavedVideo">
                <IconPlus />
              </default-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IconCheckEs from './icons/IconCheckEs'
import IconPlus from './icons/IconPlus'
import DefaultBtn from './default/DefaultBtn'

import Vue from 'vue'
import * as uuid from 'uuid'
import video from '../../../api/video'
import ModalVideo from './modals/ModalVideo.vue'

const defaultState = () => ({
  script: null,
  videoUid: null,
  isRecorded: false,
  isActiveCamera: true,
  hasError: false,
  isGettingUrl: false,
})
export default {
  components: {
    DefaultBtn,
    IconPlus,
    IconCheckEs,
  },
  props: {
    senderEmail: { type: String, required: true },
    recipientEmail: { type: String, default: '' },
    recipientPhone: { type: String, default: '' },
  },
  data: () => ({
    ...defaultState(),
  }),
  computed: {
    addVideo: {
      get() {
        return this.$store.state.card.addVideo
      },
      set(val) {
        this.$store.dispatch('card/setAddVideo', val)
      },
    },
    isShowingRecordingWindow() {
      return this.addVideo && !this.hasPreview
    },
    allowVideo() {
      return this.$store.state.main.allowVideo
    },
    isShowingPlugin() {
      return !this.isRecorded && !this.hasError && !this.isGettingUrl
    },
    currentCard() {
      return this.$store.state.card.current
    },
    hasPreview() {
      return !!this.currentCard.videoUrl
    },
  },
  methods: {
    removeSavedVideo() {
      this.$modal.show(ModalVideo, {
        store: this.$store,
        title: this.$t('video.modal_delete.title'),
        buttonCancel: this.$t('video.modal_delete.cancel'),
        buttonDelete: this.$t('video.modal_delete.confirm'),
      })
    },
    appendScript() {
      // let livstickPlugin = document.createElement('div')
      // livstickPlugin.id = 'livstick-plugin'
      // livstickPlugin.style.width = '100%'
      // livstickPlugin.style.height = 'auto'

      // this.$el.append(livstickPlugin)

      this.script = document.createElement('script')
      this.script.src = 'https://s3.livstick.com/livstick-widget/js/bundle.js'
      this.script.async = true
      this.videoUid = uuid.v4()
      //setting attributes for the script
      this.script.setAttribute('id', 'livstick-plugin-script')
      this.script.setAttribute('data-uid', this.videoUid)
      this.script.setAttribute('data-email', this.senderEmail)
      if (this.recipientEmail) {
        this.script.setAttribute('data-email-receiver', this.recipientEmail)
      }
      if (this.recipientPhone) {
        this.script.setAttribute('data-phone-receiver', this.recipientPhone)
      }
      this.script.setAttribute(
        'data-lang',
        this.$store.state.main?.locale || 'ru'
      )

      //appending it to the DOM
      document.body.appendChild(this.script)
    },
    async recorded() {
      this.isGettingUrl = true
      try {
        await this.getVideoUrl()
        this.$store.dispatch('card/setVideoUid', this.videoUid)
        this.isRecorded = true
      } catch (error) {
        Vue.$logger.debug('Video | video.getVideoUrl | ошибка: ', error)
        this.hasError = true
      } finally {
        this.isGettingUrl = false
      }
    },
    getVideoUrl() {
      if (!this.videoUid) return
      return video.getVideoUrl(this.videoUid).then((res) => {
        this.$store.dispatch('card/setVideoUrl', res)
      })
    },
    init() {
      this.appendScript()
      document.addEventListener('livstick-message-recorded', this.recorded)
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {
          this.isActiveCamera = true
        })
        .catch(() => {
          this.isActiveCamera = false
        })
    },
    destroy() {
      if (!this.script) return
      document.body.removeChild(this.script)
      document.removeEventListener('livstick-message-recorded', this.recorded)
    },
  },
  async mounted() {
    const { videoUrl, videoUid } = this.currentCard
    if (videoUrl && videoUid) {
      this.preview = true
      this.addVideo = true
    }
    if (this.isShowingRecordingWindow) {
      this.init()
    }
  },
  beforeDestroy() {
    if (this.isShowingRecordingWindow) {
      this.destroy()
    }
  },
  watch: {
    isShowingRecordingWindow(val) {
      if (val) {
        this.init()
      } else {
        this.destroy()
      }
    },
    hasPreview(val) {
      if (!val) {
        this.isRecorded = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.video-block::v-deep {
  font-family: $font-raleway;

  .Ztlnk {
    width: 100%;
    height: 100%;
  }

  button {
    margin-left: 0;
    margin-right: rem(5) !important;

    @include mobile {
      width: 100% !important;
      font-size: rem(15) !important;
      margin-right: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  button.button-mobile,
  button.button-pause,
  button.button-stop,
  button.button-play,
  button.button-retry {
    height: rem(40) !important;
    font-family: $font-raleway;
    font-weight: 600;
    font-size: rem(15);
    letter-spacing: 0;
    text-indent: unset !important;
    text-transform: lowercase !important;
    position: relative !important;
    display: block !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: rem(10) !important;
    border: none !important;
    margin-top: rem(10) !important;
    margin-bottom: 0 !important;
    padding: 0 rem(24) !important;
    -webkit-box-align: center;
    @include mobile {
      width: 100%;
      font-size: rem(15) !important;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .button-record,
  .button-send,
  .button-pause,
  .button-play {
    color: var(--theme-primary-text-color-component) !important;
    background: var(--theme-primary-background-component);

    &:hover {
      background: var(--theme-primary-background-component) !important;
    }
  }

  .sc-egiyK {

    button.button-record.button-mobile,
    button.button-play,
    button.button-retry {
      letter-spacing: 0;
      text-indent: unset !important;
      text-transform: initial !important;
      // width: auto !important;
    }
  }

  .kDpoWU {
    display: flex !important;
    justify-content: flex-start !important;
    position: relative !important;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;

    button {
      width: auto !important;
      margin: rem(10) rem(5) 0 !important;
      @include mobile {
        width: 100% !important;
        margin: rem(5) 0 !important;
      }

      &:first-child {
        margin-left: 0 !important;
        @include mobile {
          margin-top: rem(10) !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .button-play {
      display: none !important;
    }
  }

  .button-import,
  .button-stop,
  .button-retry {
    color: #0E1E0E !important;
    background: #E9EBEC !important;
    @include mobile {
      color: #ffffff !important;
    }

    &:hover {
      background: #E9EBEC !important;
    }
  }

  button.button-import {
    @include mobile {
      text-transform: lowercase !important;

      &:first-letter {
        text-transform: uppercase !important;
      }
    }
  }

  .button-import {
    @include mobile {
      background: var(--theme-primary-background-component) !important;

      &:hover {
        background: var(--theme-primary-background-component) !important;
      }
    }
  }

  .sc-ksdxgE {
    // width: auto;
    height: auto;

    @media (max-width: 991px) {
      width: inherit;
    }
  }

  .sc-egiyK {
    position: relative !important;
    display: flex;
    -webkit-box-pack: center !important;
    justify-content: flex-end !important;
    bottom: 0;
    margin-top: rem(20) !important;
    // flex-wrap: nowrap;

    @include mobile {
      display: block;
    }
  }

  .sc-bdvvtL {
    position: relative;
    // color: transparent;

    .spinner:before {
      left: 0;
    }

    #progress {
      color: transparent;
      position: absolute;
      width: 100%;

      // &::after {
      //   content: 'Сохраняем видео ...';
      //   position: absolute;
      //   background: #fff;
      //   width: 100%;
      //   color: #000;
      //   left: 0;
      //   text-align: left;
      // }
    }

    &.fFSFRs {
      &::after {
        display: none;
      }
    }
  }

  h3 {
    font-family: $font-raleway;
    font-weight: 600;
    font-size: rem(13);
    line-height: 140%;
    color: #0b132a;
    margin-top: 0;
    margin-bottom: rem(10);

    @include mobile {
      background-color: #ffffff;
      font-size: rem(12);
      line-height: 120%;
      text-align: center;
      color: #6E7882;
      border-radius: rem(10);
      padding: rem(10);
      margin-bottom: rem(20);
    }
  }

  p {
    @include mobile {
      font-size: rem(15);
      margin: rem(10) 0;
    }
  }
}

.video-block {
  font-family: $font-raleway, sans-serif;
  font-size: rem(14);
  background-color: #F6F6F6;
  border-radius: rem(10);
  position: relative;
  margin-top: rem(20);
  margin-bottom: rem(2);

  @include mobile {
    background-color: #ffffff;
    border-radius: 0;
    margin: 0 0 rem(-40) 0;
  }

  &__video {
    overflow: hidden;
    padding: 0 rem(20) rem(20) rem(20);

    @include mobile {
      max-width: 100%;
      overflow: hidden;
      padding: 0 rem(10) rem(10) rem(10);
    }
  }
}

.widget-input-checkbox {
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  input {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
  }

  label {
    font-size: rem(13);
    line-height: 140%;
    color: #6e7882;
    position: relative;
    padding-top: rem(1);
    padding-left: rem(31);
    transition: 0.3s;
    cursor: pointer;

    @include mobile {
      font-size: rem(12);
      line-height: 120%;
      color: #0E1E0E;
    }
  }

  &__icon {
    width: rem(18);
    height: rem(18);
    background-color: #E9EBEC;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    transition: 0.1s;
    transform: translateY(-50%);

    &--checked {
      background: var(--theme-primary-background-component);
    }
  }
}

.preview {
  &__video {
    position: relative;
    max-width: rem(100);

    video {
      width: 100%;
    }

    &-remove {
      position: absolute;
      top: 0;
      right: 0;
      width: rem(20);
      height: rem(20);
      cursor: pointer;

      svg {
        transform: rotate(45deg);
      }
    }
  }
}

.video-block-info {
  @include mobile {
    background-color: transparent;
    box-sizing: border-box;
    padding: rem(16) rem(10) rem(10);
    margin-bottom: 0;
  }

  &__wrapper {
    background-color: #F6F6F6;
    border-radius: rem(10);
    @include mobile {
      padding: rem(20) 0 0;
    }
  }

  &__top {
    display: grid;
    grid-template-columns: rem(250) rem(190);
    padding: 0;

    @include mobile {
      grid-template-columns: auto;

    }
  }

  &__right {
    display: flex;
    align-items: flex-end;
    padding-right: rem(20);

    @include mobile {
      padding-right: 0;
      padding-left: rem(78);
      margin-top: rem(-10);
    }
  }

  &__left {
    padding: rem(20);

    @include mobile {
      padding: 0 rem(10);
    }

    .widget-input-checkbox label {
      color: #0E1E0E;

      span {
        border-bottom: rem(1) solid #0E1E0E;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 140%;
    color: #0B132A;
    margin-bottom: rem(10);

    @include mobile {
      font-size: rem(15);
      line-height: 120%;
    }
  }

  &__text {
    font-size: rem(13);
    line-height: 160%;
    color: #6E7882;
    margin-bottom: rem(23);

    @include mobile {
      font-size: rem(12);
      line-height: 120%;
      margin-bottom: rem(17);
    }
  }

  &__image {
    display: flex;
    align-items: flex-end;

    img {
      max-width: 100%;
    }
  }
}
</style>
