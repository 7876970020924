<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="gc-modal"
    content-class="gc-modal-content"
    v-on="$listeners"
  >
    <div class="gc-modal__wrapper">
      <span class="gc-modal__title">
        <slot name="title"></slot>
      </span>
      <div class="gc-modal__content">
        <slot v-bind:params="params"></slot>
      </div>
      <div class="gc-modal__actions">
        <slot name="actions"></slot>
        <!-- <button @click="$emit('confirm', close)">confirm</button>
        <button @click="$emit('cancel', close)">cancel</button> -->
      </div>
      <div class="gc-modal-close" @click="close">
        <div class="gc-modal-close__btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: 'CustomModal',
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
::v-deep {
  .gc-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vfm--overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .gc-modal-content {
    min-width: rem(340);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: rem(20);
    @include mobile {
      height: 100%;
      width: 100%;
      min-width: rem(320);
      border-radius: 0;
      padding: 0;

      img {
        max-width: 100%;
      }
    }
  }
}

.gc-modal {
  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: rem(40) rem(20);
    @include mobile {
      padding: rem(90) rem(10) rem(50);
    }
  }

  &__title {
    font-size: rem(22);
    line-height: 120%;
    font-weight: 500;
    text-align: center;
    margin-bottom: rem(20);
    @include mobile {
      font-size: rem(15);
      margin-bottom: rem(40);
    }
  }

  &__content {

  }
}

.gc-modal-close {
  width: rem(30);
  height: rem(30);
  background: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: rem(-26);
  right: rem(-26);
  border: none;
  @include mobile {
    width: 100%;
    height: rem(20);
    background-color: #333333;
    justify-content: flex-end;
    top: 0;
    right: 0;
    padding: rem(10);
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: rotate(90deg);
      transition: 0.2s;
    }

    svg {
      width: rem(24);
      height: rem(24);
      @include mobile {
        width: rem(20);
        height: rem(20);
      }
    }
  }
}
</style>
