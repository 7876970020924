<template>
  <div class="page corporate-order">
    <div class="corporate-order__container">
      <div class="page__header">
        <span class="page__title" v-html="$t('corporate_order.title')"></span>
      </div>
      <div class="corporate-order__content">
        <div class="subscribe-form">
          <btx-form>
            <template #form>
              <script v-if="macroregion === 'ru'" data-b24-form="inline/253/hj7dae" data-skip-moving="true" type="application/javascript">
                (function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b15111006/crm/form/loader_253.js');
              </script>
              <script v-if="macroregion === 'kz'" data-b24-form="inline/257/a4yk18" data-skip-moving="true" type="application/javascript">
                (function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b15111006/crm/form/loader_257.js');
              </script>
              <script v-if="macroregion === 'by'" data-b24-form="inline/259/1ctfgw" data-skip-moving="true" type="application/javascript">
                (function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b15111006/crm/form/loader_259.js');
              </script>
              <script v-if="macroregion === 'es'" data-b24-form="inline/33/elayt1" data-skip-moving="true" type="application/javascript">
                (function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b20206923/crm/form/loader_33.js');
              </script>
            </template>
          </btx-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtxForm from '../components/BtxForm'
import queryString from 'qs'

const ids = ['253', '257', '259', '33']

export default {
  components: { BtxForm },
  data() {
    return {
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    macroregion() {
      if (typeof this.$store.state.region.macroregion !== 'undefined') {
        return this.$store.state.region.macroregion
      } else {
        return {}
      }
    },
  },
  mounted() {
    // window.addEventListener('b24:form:show', this.sendB24)
    window.addEventListener('b24:form:init', this.sendB24)
  },
  destroyed() {
    window.removeEventListener('b24:form:init', this.sendB24)
  },
  methods: {
    sendB24(e) {
      let form = e.detail.object

      if (ids.includes(form.identification.id)) {
        form.setProperty('code', this.$store.state.main.code)
        form.setProperty('productId', this.$store.state.main.productId)

        if (queryString.parse(location.search).analytics) {
          const analytics = queryString.parse(location.search).analytics

          for (let key in analytics) {
            form.setProperty('analytics_' + key, '')
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: rem(40);
    @include mobile {
      margin-bottom: rem(20);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 120%;
    color: #0E1E0E;

    @include mobile {
      font-size: rem(15);
    }

    &::v-deep {

      .weight-400 {
        font-weight: 400;
      }
    }
  }
}

.corporate-order {
  &__content {
    max-width: rem(440);
    font-size: rem(13);
    line-height: rem(18);
    color: #6E7882;
    background-color: #ffffff;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(10);
    padding: rem(40);
    margin: 0 auto;

    @include mobile {
      padding: rem(24) rem(16);
    }
  }

  a {
    color: #0E1E0E;
  }
}

.corporate-order::v-deep {
  a {
    color: #0E1E0E;
  }

  h3 {
    font-weight: 600;
    font-size: rem(13);
    margin: 0 0 rem(14);
  }

  p {
    margin: 0;
  }

  p+p {
    margin-top: rem(14);
  }

  ol,
  ul {
    margin: 0;
    list-style: none;
  }

  ol {
    padding: 0 0 0 rem(26);

    @include mobile {
      padding-left: rem(16);
    }
  }

  ul {
    padding: 0 0 0 rem(18);
  }

  ol li+li,
  ul li+li {
    margin-top: rem(14);
  }

  ol li,
  ul li {
    position: relative;
  }

  ol>li {
    counter-increment: item;
  }

  ol li::before,
  ul li::before {
    position: absolute;
  }

  ol li::before {
    content: counter(item) '.';
    font-weight: 400;
    left: rem(-26);

    @include mobile {
      left: rem(-16);
    }
  }

  ul li::before {
    content: '';
    left: rem(-20);
    top: rem(7);
    width: rem(6);
    height: rem(6);
    border-radius: 50%;
    background: var(--theme-primary-background-component);
  }
}
</style>
