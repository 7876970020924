import { render, staticRenderFns } from "./ModalVideo.vue?vue&type=template&id=6af5a824&scoped=true&"
import script from "./ModalVideo.vue?vue&type=script&lang=js&"
export * from "./ModalVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af5a824",
  null
  
)

export default component.exports