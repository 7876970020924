<template>
  <div class="short-info-block__item">
    <div class="short-info-block__icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_658_4249)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.00033 2.00033C4.68662 2.00033 2.00033 4.68662 2.00033 8.00033C2.00033 11.314 4.68662 14.0003 8.00033 14.0003C11.314 14.0003 14.0003 11.314 14.0003 8.00033C14.0003 4.68662 11.314 2.00033 8.00033 2.00033ZM0.666992 8.00033C0.666992 3.95024 3.95024 0.666992 8.00033 0.666992C12.0504 0.666992 15.3337 3.95024 15.3337 8.00033C15.3337 12.0504 12.0504 15.3337 8.00033 15.3337C3.95024 15.3337 0.666992 12.0504 0.666992 8.00033Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.666992 7.99967C0.666992 7.63148 0.965469 7.33301 1.33366 7.33301H14.667C15.0352 7.33301 15.3337 7.63148 15.3337 7.99967C15.3337 8.36786 15.0352 8.66634 14.667 8.66634H1.33366C0.965469 8.66634 0.666992 8.36786 0.666992 7.99967Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.00048 8.00007C6.04617 10.05 6.75116 12.0235 8.00032 13.6329C9.24949 12.0235 9.95448 10.05 10.0002 8.00007C9.95448 5.95018 9.24949 3.97663 8.00033 2.36727C6.75116 3.97663 6.04617 5.95018 6.00048 8.00007ZM8.00033 1.3334L7.5081 0.883789C5.73159 2.82868 4.722 5.35264 4.66714 7.98619C4.66694 7.99544 4.66694 8.0047 4.66714 8.01396C4.722 10.6475 5.73159 13.1715 7.5081 15.1164C7.6344 15.2546 7.81305 15.3334 8.00033 15.3334C8.18761 15.3334 8.36625 15.2546 8.49256 15.1164C10.2691 13.1715 11.2786 10.6475 11.3335 8.01396C11.3337 8.0047 11.3337 7.99544 11.3335 7.98619C11.2786 5.35264 10.2691 2.82868 8.49256 0.883789L8.00033 1.3334Z"
            fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_658_4249">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="short-info-block__text" v-html="$t(`conditions.online_store.${ $store.state.main.product.kind }`)"></div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
