<template>
  <div class="short-info-block__item">
    <div class="short-info-block__icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4V10H7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23 20V14H17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M20.49 8.99995C19.9828 7.56674 19.1209 6.28536 17.9845 5.27537C16.8482 4.26539 15.4745 3.55972 13.9917 3.22421C12.5089 2.8887 10.9652 2.93429 9.50481 3.35673C8.04437 3.77916 6.71475 4.56467 5.64 5.63995L1 9.99995M23 14L18.36 18.36C17.2853 19.4352 15.9556 20.2207 14.4952 20.6432C13.0348 21.0656 11.4911 21.1112 10.0083 20.7757C8.52547 20.4402 7.1518 19.7345 6.01547 18.7245C4.87913 17.7145 4.01717 16.4332 3.51 15"
          stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="short-info-block__text">
      <span v-html="$t(`conditions.exchange.${ $store.state.main.product.kind }`)"></span>
      <default-btn v-if="$t('conditions.exchange_ex') !== ''" is-link :target="'_blank'"
        :href="'https://www.giftery.ru/info/gifterycard'" v-html="$t('conditions.exchange_ex')"></default-btn>
    </div>
  </div>
</template>

<script>
import DefaultBtn from '../default/DefaultBtn'
export default {
  components: { DefaultBtn },
  methods: {
    openLink() {
      this.$emit('click-on-link')
    }
  }
}
</script>

<style scoped>

</style>
