<template>
  <default-modal disableClose class="modal-face" @close="$emit('close')">
    <template #title>
      {{ title }}
    </template>
    <div class="modal-face__info">
      <span
        v-html="locale.$t('form.enter_manually_range', { step: step ,from: numberFormat(min, 0, ',', ' ') + '&nbsp;' + store.state.region.currency, to: numberFormat(max, 0, ',', ' ') + '&nbsp;' + store.state.region.currency })"></span>

    </div>
    <div class="modal-face__input">
      <div class="widget-input" :class="{ 'widget-input--error': errorWidgetInput }">
        <div class='widget-input__wrapper'>
          <input v-model="selectedFace" ref="input" type="number" inputmode="numeric" id="widget-input-face" min="0"
            step="1" maxlength="6" @keypress="[isNumber($event), resetError()]" @blur="checkFace()">
          <div class="widget-input-error" v-if="errorWidgetInput">
            <IconError />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-face__btns">
      <default-btn @click="onClickCancel()" grey small>
        {{ buttonCancel }}
      </default-btn>
      <default-btn @click="onClickOk()" small>
        {{ buttonOk }}
      </default-btn>
    </div>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'
import { closest } from 'app/modules/utils'
import { numberFormat } from 'app/modules/utils'
import IconError from '../icons/IconError'

function setDemoFaces(store) {
  if (store.state.main.isDemo &&
    store.state.main.product.properties &&
    store.state.main.product.properties.faces &&
    store.state.main.product.properties.faces.length === 0
  ) {
    store.dispatch('main/setDemoFaces', [1000, 2000, 3000, 5000])
  }
}

export default Vue.extend({
  name: 'ModalFace',
  components: { DefaultModal, DefaultBtn, IconError },
  props: {
    id: {
      type: String,
      default: ''
    },
    store: {
      type: Object
    },
    locale: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    buttonOk: {
      type: String,
      default: ''
    },
    buttonCancel: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      errorWidgetInput: false,
      selectedFace: null
    }
  },
  computed: {
    product() {
      setDemoFaces(this.store)

      return this.store.state.main.product
    },
    min() {
      if (typeof this.product.properties !== 'undefined' && typeof this.product.properties.faces !== 'undefined') {
        return parseInt(this.product.properties.face_min)
      } else {
        return 0
      }
    },
    max() {
      if (typeof this.product.properties !== 'undefined' && typeof this.product.properties.faces !== 'undefined') {
        return parseInt(this.product.properties.face_max)
      } else {
        return 0
      }
    },
    step() {
      if (typeof this.product.properties !== 'undefined' && typeof this.product.properties.face_step !== 'undefined') {
        if (this.product.properties.face_step < this.quant) {
          return this.quant
        } else {
          return this.product.properties.face_step
        }
      } else {
        return this.quant
      }
    },
    hasCustom() {
      return !!~this.store.state.main.faces.indexOf(0)
    }
  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onClickOk() {
      if (this.selectedFace !== null && this.selectedFace > 0) {
        this.store.commit('card/setFace', this.selectedFace)
        this.$refs.input.value = ''
        this.$emit('close')
      } else {
        this.errorWidgetInput = true
      }
    },
    onClickCancel() {
      this.$refs.input.value = ''
      this.$nextTick(() => {
        this.$emit('close')
      })
    },
    checkFace() {
      let newFace

      if (this.$refs.input.value && this.hasCustom) {
        newFace = Math.round(this.selectedFace / this.step) * this.step

        if (newFace < this.min) {
          newFace = this.min
        } else if (newFace > this.max) {
          newFace = this.max
        }
      } else {
        newFace = this.closest(this.full, this.selectedFace)
      }

      this.selectedFace = newFace
    },
    resetError() {
      this.errorWidgetInput = false
    },
    closest(array, num) {
      return closest(array, num)
    },
    numberFormat(...args) {
      return numberFormat.apply(this, args)
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-face {
  &__info {
    line-height: 140%;
    color: #6E7882;
    text-align: center;
    margin-bottom: rem(20);
  }

  &__input {
    margin-bottom: rem(20);
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;

    &::v-deep {
      .btn {
        width: 50%;
      }
    }
  }
}

.widget-input {
  &__wrapper {
    position: relative;
  }

  label {
    font-size: rem(13);
    line-height: rem(16);
    color: #6E7882;
    display: block;
    margin-bottom: rem(7);
  }

  input {
    width: 100%;
    height: rem(44);
    background-color: #F6F6F6;
    font-weight: 600;
    font-size: rem(16);
    line-height: 160%;
    text-align: center;
    color: #0B132A;
    border: none;
    border-radius: rem(10);
    outline: none;
    padding: rem(3) 0;
    box-shadow: none;

    @include mobile {
      font-weight: 600;
      font-size: rem(16);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  &--error {
    input {
      border-bottom-color: #FA6400;
    }
  }
}

.widget-input-error {
  position: absolute;
  bottom: 0;
  right: rem(4);
  cursor: help;

  &__tip {
    max-width: rem(200);
    min-width: rem(200);
    background-color: #FA6400;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: rem(30);
    opacity: 0;
    border-radius: rem(4);
    padding: rem(10) rem(20);
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #FA6400;
      transform: translate(-50%, 0);
    }
  }

  &:hover {
    .widget-input-error__tip {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &--check {
    cursor: default;
  }
}

.widget-label-tip {
  opacity: 0;
  position: absolute;
}
</style>
