import { render, staticRenderFns } from "./PayFail.vue?vue&type=template&id=165f8f21&scoped=true&"
import script from "./PayFail.vue?vue&type=script&lang=js&"
export * from "./PayFail.vue?vue&type=script&lang=js&"
import style0 from "./PayFail.vue?vue&type=style&index=0&id=165f8f21&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165f8f21",
  null
  
)

export default component.exports