<template>
  <div class="page-cart">
    <CartRu v-if="product.region === 'ru'" />
    <!-- <Card v-if="$store.state.main.showPreload" /> -->
    <Cart v-else />
  </div>
</template>


<script>
import Cart from '../components/Cart'
import CartRu from '../components/CartRu'

export default {
  components: { Cart, CartRu },
  data () {
    return {
      isProcessing: false,
    }
  },
  computed: {
    product () {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
