import Vue from 'vue'
import axios from 'axios'

export default {
  check (code, productId) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/check-promocode'

    return axios.post(ACTION_URL, {
      promocode: code,
      product_id: productId
    })
      .then((response) => {
        if (response.data.status === 'ok' && typeof response.data.data !== 'undefined') {
          return response.data.data
        } else {
          throw new Error(response.data.code)
        }
      }).catch((error) => {
        if (error.message === 'promocode_error') {
          throw new Error(error.message)
        }
        if (error.response.status >= 500) {
          throw new Error('server_error')
        } else if (error.request) {
          Vue.$logger.error('promocode', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
