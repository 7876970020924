/* eslint-disable */
import { widgetParams } from '../constants'

const padLeft = function (string, length, char) {
  char = char || '0'
  string = '' + string
  while (string.length < length) {
    string = char + '' + string
  }
  return string
}

const months = [
  'янв',
  'фев',
  'мар',
  'апр',
  'мая',
  'июня',
  'июля',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек'
]

export function decodeBase64 (s) {
  let e = {}
  let i
  let b = 0
  let c
  let x
  let l = 0
  let a
  let r = ''
  let w = String.fromCharCode
  let L = s.length
  const A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i
  }

  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)]
    b = (b << 6) + c
    l += 6;
    while (l >= 8) {
      ((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a))
    }
  }

  return r
}

export function formatDate (date) {
  return padLeft(date.getDate(), 2, '0') + '.' + padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '.' + date.getFullYear()
}

export function formatDateTime (date) {
  return padLeft(date.getDate(), 2, '0') + ' ' + months[parseInt(date.getMonth())] + ' ' + date.getFullYear() + ', ' + padLeft(date.getHours(), 2, '0') + ':' + padLeft(date.getMinutes(), 2, '0')
}

export function formatDateForRequest (date) {
  return date.getFullYear() + '-' + padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '-' + padLeft(date.getDate(), 2, '0') + 'T00:00:00Z'
}

export function formatDateForLog (date) {
  return date.getFullYear() + '-' + padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '-' + padLeft(date.getDate(), 2, '0') + ' ' + padLeft(date.getHours(), 2, '0') + ':' + padLeft(date.getMinutes(), 2, '0') + ':' + padLeft(date.getSeconds(), 2, '0') + '.' + padLeft(date.getMilliseconds(), 3, '0')
}

export { padLeft }

export function validatePhone (phone, region) {
  phone = phone.replace(/[^0-9]/g ,'')
  const format = {
    'ru': '79',
    'kz': '77'
  }
  return phone.length == 11 && phone.substr(0, 2) === format[region]
}

export function validateEmail (email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function numberFormat (number, decimals, dec_point, thousands_sep) {
  // Format a number with grouped thousands
  //
  // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +   bugfix by: Michael White (http://crestidg.com)

  var i, j, kw, kd, km, s

  s = number < 0 ? '-' : ''

  number = Math.abs(number)

  // input sanitation & defaults
  if( isNaN(decimals = Math.abs(decimals)) ){
    decimals = 2
  }
  if( dec_point == undefined ){
    dec_point = ","
  }
  if( thousands_sep == undefined ){
    thousands_sep = "."
  }

  i = parseInt(number = (+number || 0).toFixed(decimals)) + ""

  if( (j = i.length) > 3 ){
    j = j % 3
  } else{
    j = 0
  }

  km = (j ? i.substr(0, j) + thousands_sep : "")
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep)
  // kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "")
  kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "")

  return s + km + kw + kd
}

export function pluralize (number, one, two, five) {
  let n = Math.abs(number)
  n %= 100
  if (n >= 5 && n <= 20) {
    return five
  }
  n %= 10
  if (n === 1) {
    return one
  }
  if (n >= 2 && n <= 4) {
    return two
  }
  return five
}

export function closest (array, num) {
  var i = 0
  var minDiff = 10000000
  var ans
  for (i in array) {
    var m = Math.abs(num - array[i])
    if (m < minDiff) {
      minDiff = m
      ans = array[i]
    }
  }
  return ans
}

export function fixedEncodeURI (str) {
  return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']')
}

export function parseUrlParams (paramsString) {
  let params = {}
  if (paramsString.substr(0, 1) === '?') {
    paramsString = paramsString.substr(1)
  }
  if (paramsString !== null && paramsString !== '') {
    params = JSON.parse(
      '{"' + paramsString.replace(/&/g, '","').replace(/=/g,'":"') + '"}',
      (key, value) => {
        return key === '' ? value:decodeURIComponent(value)
      }
    )
  }

  return params
}

export function hexToHSL (hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  let r = parseInt(result[1], 16)
  let g = parseInt(result[2], 16)
  let b = parseInt(result[3], 16)

  r /= 255
  g /= 255
  b /= 255;

  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h, s, l = (max + min) / 2
  if (max == min) {
    h = s = 0 // achromatic
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  return [ h, s, l ]
}

export function removeEmoji(text) {
  return text ? text.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : text
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 1024

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function isSupportedStorage(getStorage) {
  try {
    const key = "__some_random_key_you_are_not_going_to_use__";
    getStorage().setItem(key, key);
    getStorage().removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}


export function sanitizeUrl(url) {
  const uniqueParams = new Set()
  
  const [path, searchParams] = url.split('?');
  const newSearchParams = searchParams?.split('&')
    .filter((p) => {
      const currentParam = p.includes('=') ? p.split('=')[0] : p  

      if (uniqueParams.has(currentParam)) return
      uniqueParams.add(currentParam)

      return widgetParams.includes(currentParam)
    })
    .join('&');
  
  return newSearchParams ? `${path}?${newSearchParams}` : path;
}

export function getSuccessUrl(url) {
  return sanitizeUrl(url) + '&success=1'
}

export function getFailUrl(url) {
  return sanitizeUrl(url) + '&fail=1'
}