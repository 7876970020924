<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="svg-content" fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.76822 12.6402L13.7682 6.64018L12.2318 5.35982L7.9328 10.5186L5.70711 8.29289L4.29289 9.70711L7.29289 12.7071L8.0672 13.4814L8.76822 12.6402Z" fill="#59D42D"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheck'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(18);
  height: rem(18);
}

.svg-content {
  stroke: var(--theme-svg-icon-content);
}
</style>
