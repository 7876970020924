import Vue from 'vue'
import Router from 'vue-router'
import AddCard from './views/AddCard.vue'
import Cart from './views/Cart.vue'
import PaySuccess from './views/PaySuccess.vue'
import PayFail from './views/PayFail.vue'
import Help from './views/Help.vue'
import Offer from './views/Offer.vue'
import Conditions from './views/Conditions.vue'
import CorporateOrder from './views/CorporateOrder.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'AddCard',
      component: AddCard
    },
    {
      path: '/cart',
      name: 'Cart',
      component: Cart
    },
    {
      path: '/pay-success',
      name: 'PaySuccess',
      component: PaySuccess
    },
    {
      path: '/pay-fail',
      name: 'PayFail',
      component: PayFail
    },
    {
      path: '/help',
      name: 'Help',
      component: Help
    },
    {
      path: '/offer',
      name: 'Offer',
      component: Offer
    },
    {
      path: '/conditions',
      name: 'Conditions',
      component: Conditions
    },
    {
      path: '/corporate-order',
      name: 'CorporateOrder',
      component: CorporateOrder
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  mode: 'abstract',
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, document.getElementById('app').offsetTop)
  next()
})

//router.replace({ path: '*', redirect: '/' })

export default router
