<template>
  <svg width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <rect width="40" height="26.6647" fill="#F9FAFB"/>
      <path d="M0 3.33309C0 1.49228 1.49239 0 3.33333 0H36.6667C38.5077 0 40 1.49228 40 3.33309V23.3317C40 25.1725 38.5077 26.6647 36.6667 26.6647H3.33333C1.49238 26.6647 0 25.1725 0 23.3317V3.33309Z" fill="#6E7882" fill-opacity="0.1"/>
      <path d="M24.1734 5.43939L15.5659 5.46899L15.8268 21.2261L24.4343 21.1965L24.1734 5.43939Z" fill="#FF5F00"/>
      <path d="M16.1325 13.3793C16.0792 10.1744 17.515 7.32677 19.7638 5.47965C18.0663 4.14771 15.9383 3.34677 13.6314 3.35467C8.16617 3.37339 3.81915 7.87533 3.91132 13.4211C4.00349 18.9669 8.49964 23.4386 13.9648 23.4199C16.2717 23.4119 18.373 22.5965 20.026 21.2531C17.7165 19.4495 16.1857 16.5841 16.1325 13.3793Z" fill="#EB001B"/>
      <path d="M36.089 13.2441C36.1811 18.79 31.8341 23.2918 26.369 23.3106C24.062 23.3185 21.9338 22.5175 20.2365 21.1856C22.5128 19.3384 23.9211 16.4908 23.8678 13.286C23.8145 10.0811 22.2843 7.24362 19.9743 5.41212C21.6273 4.0687 23.7285 3.25327 26.0355 3.24536C31.5006 3.22664 35.9973 7.72619 36.089 13.2441Z" fill="#F79E1B"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="26.6647" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImgMastercard'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(40);
  height: rem(27);
}
</style>
