<template>
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.91107 11.5893C0.585633 11.2638 0.585633 10.7362 0.91107 10.4108L5.32181 6.00001L0.91107 1.58926C0.585633 1.26383 0.585633 0.736191 0.91107 0.410754C1.23651 0.0853167 1.76414 0.0853167 2.08958 0.410754L7.08958 5.41075C7.41502 5.73619 7.41502 6.26383 7.08958 6.58927L2.08958 11.5893C1.76414 11.9147 1.23651 11.9147 0.91107 11.5893Z" class="svg-content" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrow',
  props: {
    fill: {
      type: String,
      default: '#0E1E0E'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(8);
  height: rem(12);
}
</style>
