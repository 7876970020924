<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 4H3.33333H14" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.33333 3.99998V2.66665C5.33333 2.31302 5.47381 1.97389 5.72386 1.72384C5.97391 1.47379 6.31304 1.33331 6.66667 1.33331H9.33333C9.68695 1.33331 10.0261 1.47379 10.2761 1.72384C10.5262 1.97389 10.6667 2.31302 10.6667 2.66665V3.99998M12.6667 3.99998V13.3333C12.6667 13.6869 12.5262 14.0261 12.2761 14.2761C12.0261 14.5262 11.687 14.6666 11.3333 14.6666H4.66667C4.31304 14.6666 3.9739 14.5262 3.72386 14.2761C3.47381 14.0261 3.33333 13.6869 3.33333 13.3333V3.99998H12.6667Z" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66667 7.33331V11.3333" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.33333 7.33331V11.3333" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDelete'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(16);
  height: rem(16);
}
</style>
