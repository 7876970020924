<template>
  <div class="page conditions">
    <div class="conditions__short-info-block" v-if="!hasCustomConditions">
      <div class="page__header">
        <span class="page__title"
          v-html="$t(`conditions.info.${ $store.state.main.product.kind }`)"></span>
      </div>
      <div class="conditions__blocks conditions__blocks--mobile">
        <ShortInfoConditions />
      </div>
      <div class="conditions__blocks conditions__blocks--pc">
        <div class="short-info-block">
          <!-- <template v-if="product.id === 705">
            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'any'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <ComputerCart />
              <Printer />
            </div>
          </template> -->
          <template>
            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'no'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <OfflineStore />
              <Printer />
            </div>

            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'any'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <ComputerCart />
              <Smartphone />
            </div>

            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'eshop'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <OnlineStore />
              <ComputerCart />
            </div>

            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'offline'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <OfflineStore />
              <Smartphone />
            </div>

            <div class="short-info-block__container"
              v-if="$store.state.main.product.properties.digital_acceptance === 'exchange'">
              <Calendar @click-on-link="[showHideConditions(), scrollToText()]" />
              <Exchange />
              <Mail />
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="conditions__text">
      <div v-if="hasCustomConditions">
        <div class="page__header">
          <span class="page__title" v-html="$store.state.main.options.pages.conditions.name"></span>
        </div>
        <div class="conditions__content" v-html="$store.state.main.options.pages.conditions.content"></div>
      </div>
      <div v-else>
        <div class="page__header page__header--with-handler">
          <span class="page__title" v-html="$t('conditions.title')"></span>
        </div>
        <div class="conditions__content" :style="{ height: isVisible ? 'auto' : '0px' }"
          :class="{ 'conditions__content--show': isVisible }" v-html="$store.state.main.product.properties.disclaimer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '../components/blocks/Calendar.vue'
import ComputerCart from '../components/blocks/ComputerCart.vue'
import Exchange from '../components/blocks/Exchange.vue'
import OfflineStore from '../components/blocks/OfflineStore.vue'
import OnlineStore from '../components/blocks/OnlineStore.vue'
import Printer from '../components/blocks/Printer.vue'
import Mail from '../components/blocks/Mail.vue'
import Smartphone from '../components/blocks/Smartphone.vue'
import ShortInfoConditions from '../components/sliders/ShortInfoConditions'

import { scrollIntoView } from 'seamless-scroll-polyfill'

export default {
  components: {
    Calendar,
    ComputerCart,
    Exchange,
    OfflineStore,
    OnlineStore,
    Printer,
    Mail,
    Smartphone,
    ShortInfoConditions
  },
  data() {
    return {
      isVisible: true
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    hasCustomConditions() {
      return typeof this.$store.state.main.options.pages !== 'undefined' && typeof this.$store.state.main.options.pages.conditions !== 'undefined'
    }
  },
  methods: {
    showHideConditions() {
      this.isVisible = !this.isVisible
    },
    scrollToText() {
      if (this.$store.state.main.isMobile) {
        this.isVisible = true

        this.$nextTick(() => {
          const basketBottomElement = document.querySelector('.conditions__text')

          if (basketBottomElement) {
            scrollIntoView(basketBottomElement, {
              block: 'start',
              behavior: 'smooth'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: rem(40);
    @include mobile {
      margin-bottom: rem(20);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 120%;
    color: #0E1E0E;

    @include mobile {
      font-size: rem(15);
    }

    &::v-deep {
      br {
        display: none;

        @include mobile {
          display: block;
        }
      }

      .weight-400 {
        font-weight: 400;
      }
    }
  }
}

.short-info-block::v-deep {
  .short-info-block__icon {
    height: rem(50);
    width: rem(50);
    background: var(--theme-primary-background-component);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto rem(16);

    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  .short-info-block__text {
    font-size: rem(13);
    line-height: 160%;
    color: #666666;
  }

  .short-info-block__text {
    .btn {
      font-size: rem(13);
    }
  }
}

.short-info-block {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: rem(30);

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: rem(13);
    }
  }

  &__item {
    min-height: rem(190);
    position: relative;
    background-color: #FFFFFF;
    text-align: center;
    box-sizing: border-box;
    border-radius: rem(20);
    padding: rem(30) rem(20);

    @include mobile {
      min-height: rem(174);
    }
  }
}

.conditions {
  &__text {
    margin-top: rem(40);
  }

  &__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    font-size: rem(13);
    line-height: 160%;
    color: #6E7882;
    background-color: #ffffff;
    border-radius: rem(20);
    padding: rem(30) rem(20);
    transition: 0.3s;

    @include mobile {
      padding: rem(24) rem(16);
    }

    &--show {
      height: auto;
      opacity: 1;
      overflow: auto;
      transition: 0.3s;
    }
  }

  &__blocks {
    &--pc {
      display: block;

      @include mobile {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include mobile {
        display: block;
      }
    }
  }
}


.conditions::v-deep {
  a {
    color: #0E1E0E;
  }

  h3 {
    font-weight: 600;
    font-size: rem(13);
    margin: 0 0 rem(14);
  }

  p {
    margin: 0;
  }

  p+p {
    margin-top: rem(14);
  }

  ol,
  ul {
    margin: 0;
    list-style: none;
  }

  ol {
    padding: 0 0 0 rem(20);

    @include mobile {
      padding-left: rem(16);
    }
  }

  ul {
    padding: 0 0 0 rem(18);
  }

  ol li+li,
  ul li+li {
    margin-top: rem(14);
  }

  ol li,
  ul li {
    position: relative;
  }

  ol>li {
    counter-increment: item;
  }

  ol li::before,
  ul li::before {
    position: absolute;
  }

  ol li::before {
    content: counter(item) '.';
    font-weight: 600;
    color: #0E1E0E;
    left: rem(-20);
    top: rem(-1);

    @include mobile {
      left: rem(-16);
    }
  }

  ul li::before {
    content: '';
    left: rem(-20);
    top: rem(7);
    width: rem(6);
    height: rem(6);
    border-radius: 50%;
    background-color: #0E1E0E;
  }
}
</style>
