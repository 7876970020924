<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13643 2.20125C8.10232 1.74048 6.94696 1.62633 5.84267 1.87583C4.73839 2.12533 3.74434 2.72511 3.00877 3.58571C2.27321 4.44632 1.83555 5.52165 1.76106 6.65132C1.68657 7.78098 1.97924 8.90447 2.59543 9.85421C3.21162 10.804 4.11831 11.5291 5.18027 11.9214C6.24224 12.3137 7.40258 12.3523 8.48824 12.0313C9.5739 11.7103 10.5267 11.047 11.2046 10.1402C11.8824 9.23346 12.249 8.13187 12.2497 6.99975V6.46342C12.2497 6.14125 12.5108 5.88008 12.833 5.88008C13.1552 5.88008 13.4163 6.14125 13.4163 6.46342V7.00008C13.4156 8.38379 12.9675 9.7305 12.139 10.8388C11.3105 11.947 10.1459 12.7578 8.81903 13.1501C7.49211 13.5424 6.07392 13.4953 4.77596 13.0158C3.478 12.5362 2.36983 11.65 1.61671 10.4892C0.863587 9.32841 0.505874 7.95526 0.596917 6.57455C0.68796 5.19385 1.22288 3.87956 2.1219 2.82771C3.02093 1.77585 4.23588 1.04279 5.58556 0.737845C6.93525 0.432901 8.34735 0.572417 9.61126 1.13558C9.90554 1.26671 10.0378 1.61156 9.90668 1.90583C9.77556 2.20011 9.4307 2.33237 9.13643 2.20125Z" fill="#FA6400"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2459 1.92065C13.4739 2.14834 13.474 2.51769 13.2463 2.74561L7.41301 8.58477C7.30363 8.69426 7.15523 8.75579 7.00047 8.75583C6.84571 8.75587 6.69728 8.69441 6.58785 8.58498L4.83785 6.83498C4.61004 6.60717 4.61004 6.23783 4.83785 6.01002C5.06565 5.78222 5.435 5.78222 5.6628 6.01002L7.00012 7.34734L12.421 1.92106C12.6487 1.69314 13.018 1.69296 13.2459 1.92065Z" fill="#FA6400"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckboxAroundChecked'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(14);
  height: rem(14);
}
</style>
