<template>
  <div class="send-time">
    <div class="widget-input" :class="{
      'widget-input--error': $v.sendTime.$error && isOpenedCalendar
    }">
      <div class='widget-input__wrapper'>
        <label>{{ timeZoneText }}</label>
        <div
          class="send-time__content-mobile"
          v-if="!sendTime || $v.sendTime.$error"
        >
          {{ $v.sendTime.$error ? $t('form.required_field') : getPlaceholderText() }}
        </div>
        <datetime
          type="datetime"
          class="calendar"
          v-model="sendTime"
          input-class=""
          :value-zone="timeZone"
          :zone="timeZone"
          :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
          :phrases="{ ok: $t('form.date.calendar.ok'), cancel: $t('form.date.calendar.close') }"
          :minute-step="15"
          :week-start="1"
          :placeholder="$v.sendTime.$error ? $t('form.required_field') : getPlaceholderText()"
          @click.native="blockGlobalScroll" @close="unblockGlobalScroll" ref="calendar" auto></datetime>

        <div class="widget-input__icon" @click="openCalendar()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { requiredIf } from 'vuelidate/lib/validators'
import { Settings } from 'luxon'
import { timeZone } from 'app/modules/constants'
import { isInDST } from 'app/modules/datetime'

Vue.component('datetime', Datetime)

export default {
  data() {
    return {
      isInDST: false,
      isOpenedCalendar: false
    }
  },
  validations: {
    sendTime: {
      required: requiredIf(function () {
        return this.$store.state.card.current.sendExactTime
      })
    }
  },
  computed: {
    mode: {
      set(mode) {
        this.$store.commit('card/setSendExactTime', mode === 'exact')
      },
      get() {
        return this.$store.state.card.current.sendExactTime ? 'exact' : 'now'
      }
    },
    sendTime: {
      set(sendTime) {
        this.setDST(sendTime)
        this.$store.commit('card/setSendTime', sendTime)
      },
      get() {
        return this.$store.state.card.current.sendTime
      }
    },
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    timeZone() {
      if (this.isInDST) {
        return timeZone[this.$store.state.region.macroregion].zoneSummer
      }

      return timeZone[this.$store.state.region.macroregion].zone
    },
    timeZoneText() {
      const cities = this.$t('form.date.timezones')

      console.log(this.$store.state.region.macroregion)

      if (this.$store.state.region.macroregion === 'es') {
        const currentCity = this.isInDST ? 'CEST ' : 'CET '
        const currentZone = this.isInDST ? timeZone[this.$store.state.region.macroregion].zoneSummer : timeZone[this.$store.state.region.macroregion].zone

        return currentCity + '(' + currentZone + ')'
      }

      return cities[this.$store.state.region.macroregion] + '(' + timeZone[this.$store.state.region.macroregion].zone + ')'
    }
  },
  mounted() {
    this.isOpenedCalendar = false
    Settings.defaultLocale = this.$store.state.main.locale
    this.setDST()
  },
  destroyed() {
    this.$nextTick(() => {
      this.isOpenedCalendar = false
      this.unblockGlobalScroll()
      this.$v.$reset()
    })
  },
  methods: {
    openCalendar() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.calendar.isOpen = true
        }, 100)
      })
    },
    getPlaceholderText() {
      return this.$t('form.date.placeholder') + ' ' + this.timeZoneText
    },
    setDST(date) {
      if (typeof date === 'undefined' || date === '') {
        const dateNow = new Date()
        date = dateNow.toISOString()
      }
      this.isInDST = isInDST(date, this.$store.state.region.macroregion)
    },

    blockGlobalScroll() {
      if (this.$refs.calendar.isOpen) {
        document.body.style.overflow = 'hidden'
        this.isOpenedCalendar = true
      }
    },
    unblockGlobalScroll() {
      document.body.style.overflow = 'auto'
    },
  }
}
</script>

<style lang="scss" scoped>
.calendar::v-deep {
  .vdatetime-popup {
    overflow: hidden;
    border-radius: rem(22);
  }

  .vdatetime-popup__header {
    background: var(--theme-primary-background-component);
  }

  .vdatetime-calendar__month__day--selected>span>span,
  .vdatetime-calendar__month__day--selected:hover>span>span {
    background: var(--theme-primary-background-component);
  }

  .vdatetime-popup__actions__button {
    color: #0E1E0E;
  }

  .vdatetime-time-picker__item--selected,
  .vdatetime-month-picker__item--selected {
    color: var(--theme-reserve-primary-background-component);
  }
}

.send-time {
  position: relative;

  &__content-mobile {
    width: 100%;
    display: none;
    align-items: center;
    position: absolute;
    top: rem(22);
    left: 0;
    bottom: 0;
    pointer-events: none;

    @include mobile {
      display: flex;
    }
  }
}


.widget-input::v-deep {
  input {
    height: initial;
    width: 100%;
    font-size: rem(16);
    line-height: 140%;
    color: #0B132A;
    border: none;
    outline: none;
    padding: rem(6) 0;
    border-bottom: rem(1) solid rgb(110 120 130 / 20%);
    @include mobile {
      height: rem(50);
    }

    &::placeholder {
      font-size: rem(16);
      color: #0B132A !important;
      @include mobile {
        color: transparent !important;
      }
    }
  }

  &.widget-input--error {
    input {
      border-bottom-color: #FF0000;

      &::placeholder {
        color: #FF0000 !important;
        @include mobile {
          color: transparent !important;
        }
      }
    }

    .send-time__content-mobile {
      color: #FF0000 !important;
    }
  }
}


.widget-label-tip {
  opacity: 0;
  position: absolute;
}
</style>
