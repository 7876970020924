<template>
  <default-modal disableClose class="modal-pay-sbp" @close="$emit('close')">
    <template #title>
      <div class="modal-pay-sbp__title">
        Заказ {{ paymentCode }} на сумму {{ total() }}
      </div>
    </template>

    <div class="modal-pay-sbp__desc">
      <template v-if="!store.state.main.isMobile">
        Отсканируйте QR-код<br>
        в приложении банка или камерой
      </template>
      <template v-else>
        Подтвердите оплату<br>
        в&nbsp;приложении банка
      </template>
    </div>

    <div
      class="modal-pay-sbp__image"
      v-html="gateResponse.QrSvg"
       v-if="!store.state.main.isMobile"
    ></div>

    <div class="modal-pay-sbp__pay-info --success" v-if="false">
      Платеж прошел успешно.
    </div>
    <div class="modal-pay-sbp__pay-info --fail" v-if="failPay">
      Платеж не прошел.<br> Попробуйте снова или вернитесь к&nbsp;заказу.
    </div>

    <div class="modal-pay-sbp__btns">
      <template v-if="store.state.main.isMobile">
        <default-btn :href="gateResponse.formUrl" small>
          В приложение банка
        </default-btn>
      </template>
      <default-btn @click="closeModal()" :grey="store.state.main.isMobile" small>
        Вернуться в корзину
      </default-btn>
    </div>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'
import axios from 'axios'

export default Vue.extend({
  name: 'ModalPaySBP',
  components: { DefaultModal, DefaultBtn },
  props: {
    id: {
      type: String,
      default: ''
    },
    gateResponse: {
      type: Object
    },
    amount: {
      type: Number,
      default: 0
    },
    paymentCode: {
      type: String,
      default: ''
    },
    buttonCancel: {
      type: String,
      default: ''
    },
    buttonDelete: {
      type: String,
      default: ''
    },
    router: {
      type: Object
    },
    store: {
      type: Object
    }
  },
  data() {
    return {
      timer: 0,
      maxTimeout: 3000 * 60 * 5,
      successPay: false,
      failPay: false,
      localRouter: this.router
    }
  },
  computed: {
    product() {
      if (typeof this.store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.store.state.main.product
      }
    },
  },
  mounted() {
    this.getStatus()
  },
  destroyed() {
    this.timer = this.maxTimeout
  },
  methods: {
    onClick() {
      this.store.dispatch('cart/removePosition', this.id)
      this.$emit('close')
    },
    closeModal() {
      this.timer = this.maxTimeout
      this.$emit('close')
    },
    total() {
      return this.amount + ' ' + this.store.state.region.currency
    },
    async getStatus() {
      axios.get('https://payment.giftery.ru/invoice/status', {
        params: {
          paymentCode: this.paymentCode
        }
      })
      .then((response) => {
        if (this.timer >= this.maxTimeout) {
          this.failPay = true

          return
        }

        if (response.data.status === 'ok' && typeof response.data !== 'undefined') {
          if (response.data.payed === 1) {
            this.timer = this.maxTimeout

            this.$emit('close')
            this.localRouter.push({ name: 'PaySuccess' })
          } else {
            setTimeout(() => this.getStatus(), 3000)
          }
        } else {
          setTimeout(() => this.getStatus(), 3000)
        }


        this.timer += 3000
      }).catch((error) => {
        console.log(error)
      })
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-pay-sbp {
  min-width: rem(450);
  @include mobile {
    min-width: rem(300);
  }

  &__image,
  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    padding: 0 rem(20);
  }

  &__image {
    margin-bottom: rem(20);
  }

  &__desc {
    text-align: center;
    margin-bottom: rem(20);
  }

  &__pay-info {
    text-align: center;
    padding: rem(14) rem(20);
    margin-bottom: rem(20);

    &.--success {
      background-color: rgba(39, 174, 96, 0.1);
      color: #27AE60;
    }

    &.--fail {
      background-color: rgba(255, 0, 0, 0.1);
      color: #FF0000;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;

    &::v-deep {
      .btn {
        @include mobile {
          margin-right: 0;
          margin-bottom: rem(15);
        }
      }
    }
  }
}
</style>
