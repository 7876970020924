import Vue from 'vue'
import { Position } from '../../models'

const module = {
  namespaced: true,
  state: {
    current: new Position(),
    uploadedImages: {},
    addVideo: false
  },
  getters: {
    current: state => {
      return state.current
    }
  },
  mutations: {
    setAddVideo: (state, payload) => {
      state.addVideo = payload
    },
    setVideoUid: (state, payload) => {
      state.current.videoUid = payload
    },
    setVideoUrl: (state, payload) => {
      state.current.videoUrl = payload
    },
    setAll: (state, payload) => {
      state.current = payload
    },
    addAnotherCard: state => {
      const { senderEmail, sendExactTime, sendTime, type, typeToSend, senderName } = state.current
      state.current = new Position()
      state.current.senderEmail = senderEmail
      state.current.sendExactTime = sendExactTime
      state.current.sendTime = sendTime
      state.current.type = type
      state.current.typeToSend = typeToSend
      state.current.senderName = senderName
    },
    setProductId: (state, payload) => {
      state.current.productId = payload
    },
    setFace: (state, payload) => {
      state.current.face = payload
    },
    setType: (state, payload) => {
      state.current.type = payload
    },
    setTypeToSend: (state, payload) => {
      state.current.typeToSend = payload
    },
    setGreeting: (state, payload) => {
      state.current.greeting = payload
    },
    setSenderName: (state, payload) => {
      state.current.senderName = payload
    },
    setSenderEmail: (state, payload) => {
      state.current.senderEmail = payload
    },
    setSenderPhone: (state, payload) => {
      state.current.senderPhone = payload
    },
    setRecipientName: (state, payload) => {
      state.current.recipientName = payload
    },
    setRecipientEmail: (state, payload) => {
      state.current.recipientEmail = payload
    },
    setRecipientPhone: (state, payload) => {
      state.current.recipientPhone = payload
    },
    setRecipientCountryCode: (state, payload) => {
      state.current.recipientCountryCode = payload
    },
    setRecipientNumberPhone: (state, payload) => {
      state.current.recipientNumberPhone = payload
    },
    setRecipientNumberPhoneValid: (state, payload) => {
      state.current.recipientNumberPhoneValid = payload
    },
    setCheckedRecipientPhone: (state, payload) => {
      state.current.checkedRecipientPhone = payload
    },
    setNotifyBySms: (state, payload) => {
      state.current.notifyBySms = payload
    },
    setSendExactTime: (state, payload) => {
      state.current.sendExactTime = payload
    },
    setSendTime: (state, payload) => {
      state.current.sendTime = payload
    },
    setCardImage: (state, payload) => {
      state.current.cardImage = payload
    },
    setQuantity: (state, payload) => {
      state.current.quantity = payload
    },
    setUploadedImages: (state, payload) => {
      if (!state.uploadedImages[payload.productId]) {
        Vue.set(state.uploadedImages, payload.productId, [ payload.img ])
      } else {
        state.uploadedImages[payload.productId].push(payload.img)
      }
    }
  },
  actions: {
    setAddVideo: ({ commit }, payload) => {
      commit('setAddVideo', payload)
    },
    setVideoUid: ({ commit }, payload) => {
      commit('setVideoUid', payload)
    },
    setVideoUrl: ({ commit }, payload) => {
      commit('setVideoUrl', payload)
    },
    setAll: ({ commit }, payload) => {
      commit('setAll', payload)
    },
    addAnotherCard: ({ commit }) => {
      commit('addAnotherCard')
    },
    setProductId: ({ commit }, payload) => {
      commit('setProductId', payload)
    },
    setFace: ({ commit }, payload) => {
      commit('setFace', payload)
    },
    setType: ({ commit }, payload) => {
      commit('setType', payload)
    },
    setTypeToSend: ({ commit }, payload) => {
      commit('setTypeToSend', payload)
    },
    setGreeting: ({ commit }, payload) => {
      commit('setGreeting', payload)
    },
    setSenderName: ({ commit }, payload) => {
      commit('setSenderName', payload)
    },
    setSenderEmail: ({ commit }, payload) => {
      commit('setSenderEmail', payload)
    },
    setSenderPhone: ({ commit }, payload) => {
      commit('setSenderPhone', payload)
    },
    setRecipientName: ({ commit }, payload) => {
      commit('setRecipientName', payload)
    },
    setRecipientEmail: ({ commit }, payload) => {
      commit('setRecipientEmail', payload)
    },
    setRecipientPhone: ({ commit }, payload) => {
      commit('setRecipientPhone', payload)
    },
    setRecipientCountryCode: ({ commit }, payload) => {
      commit('setRecipientCountryCode', payload)
    },
    setRecipientNumberPhone: ({ commit }, payload) => {
      commit('setRecipientNumberPhone', payload)
    },
    setRecipientNumberPhoneValid: ({ commit }, payload) => {
      commit('setRecipientNumberPhoneValid', payload)
    },
    setCheckedRecipientPhone: ({ commit }, payload) => {
      commit('checkedRecipientPhone', payload)
    },
    setNotifyBySms: ({ commit }, payload) => {
      commit('setNotifyBySms', payload)
    },
    setSendExactTime: ({ commit }, payload) => {
      commit('setSendExactTime', payload)
    },
    setSendTime: ({ commit }, payload) => {
      commit('setSendTime', payload)
    },
    setCardImage: ({ commit }, payload) => {
      commit('setCardImage', payload)
    },
    setQuantity: ({ commit }, payload) => {
      commit('setQuantity', payload)
    },
    setUploadedImages: ({ commit }, payload) => {
      commit('setUploadedImages', payload)
    }
  }
}

export default module
