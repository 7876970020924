<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10 3.5C10 2.94772 9.55229 2.5 9 2.5C8.44771 2.5 8 2.94772 8 3.5V8.75C8 9.44036 8.55964 10 9.25 10H12.5C13.0523 10 13.5 9.55228 13.5 9C13.5 8.44772 13.0523 8 12.5 8H10V3.5Z" class="svg-background" fill="url(#paint0_linear_408_1340)"/>
    <defs>
      <linearGradient id="paint0_linear_408_1340" x1="-1.3258" y1="1.99661" x2="22.0606" y2="14.2176" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF944D"/>
        <stop offset="0.480912" stop-color="#FA6400"/>
        <stop offset="1" stop-color="#FF944D"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconWarning'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(18);
  height: rem(18);
}

.svg-background {
  fill: var(--theme-svg-icon-background);
}

.svg-content {
  fill: var(--theme-svg-icon-content);
  stroke: var(--theme-svg-icon-content);
}
</style>
