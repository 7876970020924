<template>
  <div>
    <custom-modal v-model="show">
      <template v-slot:title>
        SMS-уведомление
      </template>
      <img :src="require('../../assets/img/sms-preview.png')" />
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from '../hoc/CustomModal'

export default {
  name: 'ModalSMSWithAGift',
  components: { CustomModal},
  data: () => ({
    show: true
  }),
  mounted() {
    this.show = true
  },
  destroyed() {
    this.show = false
  },
  methods: {
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
