<template>
  <div>
    <div class="form-wrapper">
      <div class="gc-text--left gc-text--center@m">
        <slot name="title"></slot>
        <slot name="description"></slot>
      </div>
      <div
        class="form"
        :class="{'form--horizontal': horizontal}"
      >
        <slot name="form"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtxForm',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .b24-form * {
    font-family: "Raleway", sans-serif !important;
  }

  .b24-form-header {
    display: none;
  }

  .b24-form-wrapper {
    background: transparent !important;
    min-height: auto !important;
    box-shadow: none !important;
  }

  .b24-form-btn-block {
    margin: 0 auto;
    padding: 0;
  }

  .b24-form-wrapper.b24-form-border-bottom {
    border: none;
  }

  .b24-form-btn {
    width: 100%;
    height: 48px;
    min-height: 48px;
    background: #FA6400;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    border-radius: 12px;
    margin: 0 auto;

    &:hover {
      background-color: #fa4f19;
      box-shadow: 0px 0.8px 1.5px rgba(250, 100, 0, 0.08), 0px 6px 16px rgba(250, 100, 0, 0.12);
    }
  }

  .form--horizontal {
    .b24-form-wrapper {
      max-width: 100%;
      width: 100%;
    }

    form {
      > div:first-child {

        @media(min-width: 960px) {
          display: flex;
          justify-content: space-between;

        }
      }
    }

    .b24-form-control {
      margin-bottom: 0;
    }

    .b24-form-field:not(:last-child) {

      @media(min-width: 960px) {
        margin-right: 20px;
        margin-bottom: 0;

      }
    }
  }

  .b24-form-content {
    padding: 0;
    margin-bottom: 20px;
  }

  .b24-form-control {
    background: #F6F6F6 !important;
    color: #000 !important;
    font-size: 14px !important;
    border-radius: 12px !important;
    border: 1px solid #E9EBEC !important;
    padding-left: 20px !important;

    &:focus {
      border-color: #b6bbc0 !important;

      .b24-form-control-label {
        font-size: 14px !important;
      }
    }

    &-label {
      font-size: 16px !important;
      color: #222 !important;
      padding-left: 20px !important;
    }
  }

  input.b24-form-control {
    height: 52px !important;

  }

  .b24-from-state-on form {
    visibility: hidden !important;
  }

  .b24-from-state-on .b24-form-content {
    height: 100px;
  }

  .b24-from-state-on .b24-form-state-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    border-radius: 40px;


    .b24-form-state{
      background: #fff !important;
      overflow: hidden;
      border-radius: 40px;

    }

    .b24-form-success-icon {
      display: none !important;
    }

    .b24-form-state-text {
      color: #000;
      margin-bottom: 0;
    }
  }

  .b24-form-control-container {
    input {
      box-shadow: none;
    }
  }

  .b24-form-loader-icon {
    height: 90px;
    text-align: center;
    svg {
      height: 100%;
    }
  }

  .b24-form-sign {
    justify-content: center;
  }
}
</style>
