const module = {
  namespaced: true,
  state: {
    version: null,
    locale: null,
    key: null,
    product: {},
    recipientFields: null,
    code: null,
    colorHue: null,
    colorSaturation: null,
    colorLightness: null,
    colorLightnessHover: null,
    themeBackground: null,
    themeBackgroundWidget: null,
    themePrimaryBackgroundComponent: null,
    themePrimaryTextColorComponent: null,
    themeSecondaryBackgroundComponent: null,
    themeSecondaryTextColorComponent: null,
    themeReservePrimaryBackgroundComponent: null,
    themeSvgIconBackground: null,
    themeSvgiconContent: null,
    productId: null,
    orderId: null,
    clientId: null,
    isDemo: false,
    isLoading: false,
    currentFormStep: 0,
    currentFormStepMobile: 0,
    options: {},
    faces: [],
    maxCartCount: 15,
    apiUrl: '',
    showPreload: true,
    error: false,
    isMobile: false,
    allowVideo: false,
    devMode: false,
    headerLink: null,
  },
  getters: {
    getIsMobile: state => {
      if (typeof state !== 'undefined') {
        return state.isMobile
      } else {
        return false
      }
    }
  },
  mutations: {
    setDevMode (state, data) {
      state.devMode = data
    },
    setAllowVideo (state, data) {
      state.allowVideo = data
    },
    setVersion (state, data) {
      state.version = data
    },
    setLocale (state, data) {
      state.locale = data
    },
    setKey (state, data) {
      state.key = data
    },
    setProduct (state, data) {
      state.product = data
    },
    setRecipientFields(state, data) {
      state.recipientFields = data
    },
    setCode (state, data) {
      state.code = data
    },
    setColorHue (state, data) {
      state.colorHue = data
    },
    setColorSaturation (state, data) {
      state.colorSaturation = data
    },
    setColorLightness (state, data) {
      state.colorLightness = data
    },
    setColorLightnessHover (state, data) {
      state.colorLightnessHover = data
    },
    setThemeBackground (state, data) {
      state.themeBackground = data
    },
    setThemeBackgroundWidget (state, data) {
      state.themeBackgroundWidget = data
    },
    setThemePrimaryBackgroundComponent (state, data) {
      state.themePrimaryBackgroundComponent = data
    },
    setThemePrimaryTextColorComponent (state, data) {
      state.themePrimaryTextColorComponent = data
    },
    setThemeSecondaryBackgroundComponent (state, data) {
      state.themeSecondaryBackgroundComponent = data
    },
    setThemeSecondaryTextColorComponent (state, data) {
      state.themeSecondaryTextColorComponent = data
    },
    setThemeReservePrimaryBackgroundComponent (state, data) {
      state.themeReservePrimaryBackgroundComponent = data
    },
    setThemeSvgIconBackground (state, data) {
      state.themeSvgIconBackground = data
    },
    setThemeSvgIconContent (state, data) {
      state.themeSvgIconContent = data
    },
    setProductId (state, data) {
      state.productId = data
    },
    setOrderId (state, data) {
      state.orderId = data
    },
    setClientId (state, data) {
      state.clientId = data
    },
    setDemo (state, data) {
      state.isDemo = data
    },
    setDemoFaces (state, data) {
      state.product.properties.faces = data
    },
    setLoadingState (state, data) {
      state.isLoading = data
    },
    setOptions (state, data) {
      state.options = data
    },
    setFaces (state, data) {
      state.faces = data
    },
    setMaxCartCount (state, data) {
      state.maxCartCount = data
    },
    setApiUrl (state, data) {
      state.apiUrl = data
    },
    setPreload (state, data) {
      state.showPreload = data
    },
    setError (state, data) {
      state.error = data
    },
    setMobile (state, data) {
      state.isMobile = data
    },
    setCurrentFormStep (state, data) {
      state.currentFormStep = data
    },
    setCurrentFormStepMobile (state, data) {
      state.currentFormStepMobile = data
    },
    setHeaderLink (state, data) {
      state.headerLink = data
    },
  },
  actions: {
    setDevMode: ({ commit }, payload) => {
      commit('setDevMode', payload)
    },
    setAllowVideo: ({ commit }, payload) => {
      commit('setAllowVideo', payload)
    },
    setVersion: ({ commit }, payload) => {
      commit('setVersion', payload)
    },
    setLocale: ({ commit }, payload) => {
      commit('setLocale', payload)
    },
    setKey: ({ commit }, payload) => {
      commit('setKey', payload)
    },
    setRecipientFields: ({ commit }, payload) => {
      commit('setRecipientFields', payload)
    },
    setProduct: ({ commit }, payload) => {
      commit('setProduct', payload)
    },
    setCode: ({ commit }, payload) => {
      commit('setCode', payload)
    },
    setColorHue: ({ commit }, payload) => {
      commit('setColorHue', payload)
    },
    setColorSaturation: ({ commit }, payload) => {
      commit('setColorSaturation', payload)
    },
    setColorLightness: ({ commit }, payload) => {
      commit('setColorLightness', payload)
    },
    setColorLightnessHover: ({ commit }, payload) => {
      commit('setColorLightnessHover', payload)
    },
    setThemeBackground: ({ commit }, payload) => {
      commit('setThemeBackground', payload)
    },
    setThemeBackgroundWidget: ({ commit }, payload) => {
      commit('setThemeBackgroundWidget', payload)
    },
    setThemePrimaryBackgroundComponent: ({ commit }, payload) => {
      commit('setThemePrimaryBackgroundComponent', payload)
    },
    setThemePrimaryTextColorComponent: ({ commit }, payload) => {
      commit('setThemePrimaryTextColorComponent', payload)
    },
    setThemeSecondaryBackgroundComponent: ({ commit }, payload) => {
      commit('setThemeSecondaryBackgroundComponent', payload)
    },
    setThemeSecondaryTextColorComponent: ({ commit }, payload) => {
      commit('setThemeSecondaryTextColorComponent', payload)
    },
    setThemeReservePrimaryBackgroundComponent: ({ commit }, payload) => {
      commit('setThemeReservePrimaryBackgroundComponent', payload)
    },
    setThemeSvgIconBackground: ({ commit }, payload) => {
      commit('setThemeSvgIconBackground', payload)
    },
    setThemeSvgIconContent: ({ commit }, payload) => {
      commit('setThemeSvgIconContent', payload)
    },
    setProductId: ({ commit }, payload) => {
      commit('setProductId', payload)
    },
    setOrderId: ({ commit }, payload) => {
      commit('setOrderId', payload)
    },
    setClientId: ({ commit }, payload) => {
      commit('setClientId', payload)
    },
    setDemo: ({ commit }, payload) => {
      commit('setDemo', payload)
    },
    setDemoFaces: ({ commit }, payload) => {
      commit('setDemoFaces', payload)
    },
    setLoadingState: ({ commit }, payload) => {
      commit('setLoadingState', payload)
    },
    setOptions: ({ commit }, payload) => {
      commit('setOptions', payload)
    },
    setFaces: ({ commit }, payload) => {
      commit('setFaces', payload)
    },
    setMaxCartCount: ({ commit }, payload) => {
      commit('setMaxCartCount', payload)
    },
    setApiUrl: ({ commit }, payload) => {
      commit('setApiUrl', payload)
    },
    setPreload: ({ commit }, payload) => {
      commit('setPreload', payload)
    },
    setError: ({ commit }) => {
      commit('setError', true)
    },
    unsetError: ({ commit }) => {
      commit('setError', false)
    },
    setMobile: ({ commit }, payload) => {
      commit('setMobile', payload)
    },
    setCurrentFormStep: ({ commit }, payload) => {
      commit('setCurrentFormStep', payload)
    },
    setCurrentFormStepMobile: ({ commit }, payload) => {
      commit('setCurrentFormStepMobile', payload)
    },
    setHeaderLink: ({ commit }, payload) => {
      commit('setHeaderLink', payload)
    },
  }
}

export default module
