<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.49984 11.667C2.96007 11.667 3.33317 12.0401 3.33317 12.5003V15.8337C3.33317 16.0547 3.42097 16.2666 3.57725 16.4229C3.73353 16.5792 3.94549 16.667 4.1665 16.667H15.8332C16.0542 16.667 16.2661 16.5792 16.4224 16.4229C16.5787 16.2666 16.6665 16.0547 16.6665 15.8337V12.5003C16.6665 12.0401 17.0396 11.667 17.4998 11.667C17.9601 11.667 18.3332 12.0401 18.3332 12.5003V15.8337C18.3332 16.4967 18.0698 17.1326 17.6009 17.6014C17.1321 18.0703 16.4962 18.3337 15.8332 18.3337H4.1665C3.50346 18.3337 2.86758 18.0703 2.39874 17.6014C1.9299 17.1326 1.6665 16.4967 1.6665 15.8337V12.5003C1.6665 12.0401 2.0396 11.667 2.49984 11.667Z" class="svg-content" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.41074 1.91107C9.73618 1.58563 10.2638 1.58563 10.5893 1.91107L14.7559 6.07774C15.0814 6.40317 15.0814 6.93081 14.7559 7.25625C14.4305 7.58169 13.9028 7.58169 13.5774 7.25625L10 3.67884L6.42259 7.25625C6.09715 7.58169 5.56951 7.58169 5.24408 7.25625C4.91864 6.93081 4.91864 6.40317 5.24408 6.07774L9.41074 1.91107Z" class="svg-content" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 1.66699C10.4601 1.66699 10.8332 2.04009 10.8332 2.50033V12.5003C10.8332 12.9606 10.4601 13.3337 9.99984 13.3337C9.5396 13.3337 9.1665 12.9606 9.1665 12.5003V2.50033C9.1665 2.04009 9.5396 1.66699 9.99984 1.66699Z" class="svg-content" />
  </svg>
</template>

<script>
export default {
  name: 'IconUploadImage'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(20);
  height: rem(20);
}

.svg-content {
  fill: var(--theme-svg-icon-content);
}
</style>
