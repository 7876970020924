<template>
  <div>
    <custom-modal v-model="show">
      <template v-slot:title>
        SMS с подарком
      </template>
      <div class="description">
        Ссылка на&nbsp;получение может<br>
        прийти с&nbsp;указанием данных<br>
        о&nbsp;получателе и отправителе либо<br>
        без&nbsp;указания этих данных
      </div>
      <img :src="require('../../assets/img/sms-with-a-gift.png')" />
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from '../hoc/CustomModal'

export default {
  name: 'ModalSMSWithAGift',
  components: { CustomModal},
  data: () => ({
    show: true
  }),
  mounted() {
    this.show = true
  },
  destroyed() {
    this.show = false
  },
  methods: {
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  text-align: center;
  padding-bottom: rem(20);

  @include mobile {
    font-size: rem(12);
    color: #6E7882;

    br {
      display: none;
    }
  }
}
</style>
