<template>
  <svg viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <rect width="30" height="20" fill="white"/>
      <path opacity="0.1" d="M0 2.5C0 1.11929 1.11929 0 2.5 0H27.5C28.8808 0 30 1.11929 30 2.5V17.5C30 18.8807 28.8808 20 27.5 20H2.5C1.11929 20 0 18.8807 0 17.5V2.5Z" fill="#6E7882"/>
      <path d="M24.9299 6.37842H20.1169C20.3973 7.96721 21.9393 9.46254 23.6216 9.46254H27.4533C27.5001 9.32236 27.5001 9.08871 27.5001 8.94852C27.5001 7.54664 26.3318 6.37842 24.9299 6.37842Z" fill="url(#paint0_linear)"/>
      <path d="M20.5374 9.74316V13.6217H22.8739V11.5656H24.9299C26.0514 11.5656 27.0327 10.7712 27.3597 9.74316H20.5374Z" fill="#4FAD50"/>
      <path d="M12.313 6.37842V13.5747H14.369C14.369 13.5747 14.883 13.5747 15.1634 13.0607C16.5653 10.3037 16.9859 9.46254 16.9859 9.46254H17.2663V13.5747H19.6027V6.37842H17.5467C17.5467 6.37842 17.0327 6.42516 16.7523 6.89244C15.584 9.27562 14.9298 10.4906 14.9298 10.4906H14.6494V6.37842H12.313Z" fill="#4FAD50"/>
      <path d="M2.5 13.6215V6.42529H4.83645C4.83645 6.42529 5.49066 6.42529 5.86449 7.45333C6.79907 10.1636 6.89254 10.5374 6.89254 10.5374C6.89254 10.5374 7.07944 9.88323 7.92056 7.45333C8.2944 6.42529 8.94861 6.42529 8.94861 6.42529H11.285V13.6215H8.94861V9.74306H8.66822L7.35982 13.6215H6.33179L5.02337 9.74306H4.743V13.6215H2.5Z" fill="#4FAD50"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="20.1086" y1="7.94258" x2="27.4836" y2="7.94258" gradientUnits="userSpaceOnUse">
        <stop stop-color="#27B1E6"/>
        <stop offset="1" stop-color="#148ACA"/>
      </linearGradient>
      <clipPath id="clip0">
        <rect width="30" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImgMir'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(40);
  height: rem(27);
}
</style>
