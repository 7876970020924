<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99992 14.6666C6.36811 14.6666 6.66658 14.3681 6.66658 13.9999C6.66658 13.6317 6.36811 13.3333 5.99992 13.3333C5.63173 13.3333 5.33325 13.6317 5.33325 13.9999C5.33325 14.3681 5.63173 14.6666 5.99992 14.6666Z" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3334 14.6666C13.7016 14.6666 14.0001 14.3681 14.0001 13.9999C14.0001 13.6317 13.7016 13.3333 13.3334 13.3333C12.9652 13.3333 12.6667 13.6317 12.6667 13.9999C12.6667 14.3681 12.9652 14.6666 13.3334 14.6666Z" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.666748 0.666748H3.33341L5.12008 9.59342C5.18104 9.90035 5.34802 10.1761 5.59178 10.3723C5.83554 10.5685 6.14055 10.6727 6.45341 10.6667H12.9334C13.2463 10.6727 13.5513 10.5685 13.795 10.3723C14.0388 10.1761 14.2058 9.90035 14.2667 9.59342L15.3334 4.00008H4.00008" stroke="#0B132A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconBasket'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(16);
  height: rem(16);
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
</style>
