<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" class="svg-background" fill="#F6C516"/>
    <circle cx="12" cy="8" r="1" class="svg-content" fill="white"/>
    <path d="M12 17V11" class="svg-content" stroke="white" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWarning'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(24);
  height: rem(24);
}

.svg-background {
  fill: var(--theme-svg-icon-background);
}

.svg-content {
  fill: var(--theme-svg-icon-content);
  stroke: var(--theme-svg-icon-content);
}
</style>
