import Vue from 'vue'

const module = {
  namespaced: true,
  state: {
    url: '',
    payment: null
  },
  getters: {
    url: state => {
      return state.url
    },
    payment: state => {
      return state.payment
    }
  },
  mutations: {
    setUrl (state, payload) {
      state.url = payload
    },
    setPayment (state, payload) {
      state.payment = payload
    }
  },
  actions: {
    setUrl: ({ commit }, payload) => {
      Vue.$logger.info('pay | setUrl: ', payload)
      commit('setUrl', payload)
    },
    setPayment: ({ commit }, payload) => {
      Vue.$logger.info('pay | setPayment: ', payload)
      commit('setPayment', payload)
    }
  }
}

export default module
