<template>
  <div class="widget-form">

    <div class="widget-form-step" :class="{
      'widget-form-step--height-410': [0, 1].includes(currentStep),
    }">
      <div class="widget-form-step__left">
        <div class="widget-form-step__header">
          <div class="widget-form-step__header-title">
            <template v-if="currentStep === 0">{{ $t('form.sender.title_to') }}</template>
            <template v-if="currentStep === 1">{{ $t('form.recipient.title_to') }}</template>
            <template v-if="currentStep === 2">{{ $t('form.date.title_ex') }}</template>
            <template v-if="currentStep === 3 && typeToSend !== 'phone'">{{ $t('form.greeting.title_ex') }}</template>
          </div>
          <div class="widget-form-step__header-steps">{{ currentStep + 1 }}/{{ maxSteps() }}</div>
        </div>
        <div class="widget-form-step__content">
          <template v-if="currentStep === 0">
            <div class="widget-form-step__radio-btns">
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': type === 'myself' }">
                <label @click="[resetRecipientPhone(), resetValidation()]" v-if="available.includes('myself')"
                  class="widget-action--send-yourself">
                  <input type="radio" v-model="type" name="recipient_type" value="myself" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="type === 'myself'" />
                    </span></span>
                  <span class="widget-radio-btn__content"><span v-html="$t('form.send_to_myself')"></span></span>
                </label>
              </div>
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': type === 'friend' }">
                <label @click="[resetRecipientPhone(), resetValidation()]" v-if="available.includes('friend')"
                  class="widget-action--send-to-friend">
                  <input type="radio" v-model="type" name="recipient_type" value="friend" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="type === 'friend'" />
                    </span></span>
                  <span class="widget-radio-btn__content">
                    <span v-if="[18848, 18849].includes(product.id)"
                      v-html="$t('form.send_to_myself_or_friend')"></span>
                    <span v-else v-html="$t('form.send_to_friend')"></span>
                  </span>
                </label>
              </div>
            </div>
            <div class="widget-form-step__text widget-form-step__text--separator" :class="{
              '': $store.state.region.macroregion !== 'es' && currentStep !== 3,
              'widget-form-step__text--no-line': $store.state.region.macroregion === 'es' && currentStep === 3
            }">
              <template v-if="[18848, 18849].includes(product.id)">
                {{ type === 'myself' ? $t(`form.sender.desc_my_self_mvm.${ $store.state.main.product.kind }`) : $t(`form.sender.desc_mvm.${ $store.state.main.product.kind }`) }}
              </template>
              <template v-else>
                {{ type === 'myself' ? $t(`form.sender.desc_my_self.${ $store.state.main.product.kind }`) : $t(`form.sender.desc.${ $store.state.main.product.kind }`) }}
              </template>
            </div>
          </template>


          <template v-if="currentStep === 1">
            <div class="widget-form-step__radio-btns" v-if="recipientFields.includes('phone')">
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': typeToSend === 'email' }">
                <label @click="[resetRecipientPhone(), resetValidation()]" v-if="availableToSend.includes('email')"
                  class="widget-action--send-to-email">
                  <input type="radio" v-model="typeToSend" name="recipient_type_to_send-pc" value="email" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="typeToSend === 'email'" />
                    </span></span>
                  <span class="widget-radio-btn__content"><span v-html="$t('form.send_to_email')"></span></span>
                </label>
              </div>
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': typeToSend === 'phone' }">
                <label @click="[resetRecipientEmail(), resetRecipientPhone(), resetValidation()]"
                  v-if="availableToSend.includes('phone')" class="widget-action--send-to-phone">
                  <input type="radio" v-model="typeToSend" name="recipient_type_to_send-pc" value="phone" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="typeToSend === 'phone'" />
                    </span></span>
                  <span class="widget-radio-btn__content"><span v-html="$t('form.send_to_phone')"></span></span>
                </label>
              </div>
            </div>
            <div class="widget-form-step__text widget-form-step__text--separator"
              :class="{ 'widget-form-step__text--no-line': $store.state.region.macroregion === 'es' && currentStep === 1 }">
              <template v-if="typeToSend === 'phone'">{{ $t(`form.recipient.desc_to_phone`) }}</template>
              <template v-else>{{ $t(`form.recipient.desc.${ $store.state.main.product.kind }`) }}</template>
            </div>
          </template>

          <template v-if="currentStep === 2">
            <div class="widget-form-step__radio-btns">
              <span style="font-size: 0; display: none">{{ modeTimeSend }}</span>
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': modeTimeSend === 'now' }">
                <label class="widget-action--send-time-now">
                  <input type="radio" v-model="modeTimeSend" name="send-time_type" value="now" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="modeTimeSend === 'now'" />
                    </span></span>
                  <span class="widget-radio-btn__content"><span v-html="$t('form.date.instant')"></span></span>
                </label>
              </div>
              <div class="widget-radio-btn" :class="{ 'widget-radio-btn--active': modeTimeSend === 'exact' }">
                <label class="widget-action--send-time-exact">
                  <input type="radio" v-model="modeTimeSend" name="send-time_type" value="exact" />
                  <span class="widget-radio-btn__checkmark"><span>
                      <IconCheckEs v-if="modeTimeSend === 'exact'" />
                    </span></span>
                  <span class="widget-radio-btn__content"><span v-html="$t('form.date.specify')"></span></span>
                </label>
              </div>
            </div>
          </template>

          <template v-if="currentStep === 3 && typeToSend !== 'phone'">
            <div class="widget-form-step__text">
              <span v-html="$t(`form.greeting.text.${ $store.state.main.product.kind }`)"></span>
            </div>
          </template>

        </div>
      </div>


      <div class="widget-form-step__right">
        <div class="widget-form-step__header widget-form-step__header--only-title">
          <div class="widget-form-step__header-title">
            <template v-if="currentStep === 0">
              <div>{{ $t('form.sender.title') }}</div>
              <div class="widget-tooltip">
                <div class="widget-tooltip__icon">
                  <IconWarning />
                </div>
                <div class="widget-tooltip__content" tabindex="-1">
                  <div class="widget-tooltip__content-text">
                    <span v-html="$t('form.sender.tooltip')"></span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="currentStep === 1">
              <div>{{ $t('form.recipient.title') }}</div>
              <div class="widget-tooltip">
                <div class="widget-tooltip__icon">
                  <IconWarning />
                </div>
                <div class="widget-tooltip__content" tabindex="-1">
                  <div class="widget-tooltip__content-text">
                    <span
                      v-html="$t(`form.recipient.tooltip.${ $store.state.main.product.kind }`)"></span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="currentStep === 2">{{ $t('form.date.desc_ex') }}</template>
            <template v-if="currentStep === 3 && typeToSend !== 'phone'">{{ $t('form.greeting.desc_ex') }}</template>
          </div>
        </div>
        <div class="widget-form-step__content">
          <div class="widget-form-step__inputs">

            <template v-if="type === 'friend'">
              <template v-if="currentStep === 0">
                <div class="widget-input">
                  <div class='widget-input__wrapper'>
                    <label>{{ $t('form.sender.name') }}</label>
                    <input v-model.lazy="senderName" type="text" name="name" id="widget-input-sender-name">
                  </div>
                </div>

                <div class="widget-input" :class="{ 'widget-input--error': $v.senderEmail.$error }">
                  <div class='widget-input__wrapper'>
                    <label>{{ $t('form.sender.email') }}*</label>
                    <EmailDropdown v-model.lazy="$v.senderEmail.$model" :key="'sender-2-email'"
                      @input="delayTouch($v.senderEmail)" inputId="'sender-2-email'"
                      :placeholder="$v.senderEmail.$error ? $t('form.required_field') : ''"
                      :initial-value="getEmail($v.senderEmail.$model)" :domains="domains" :maxSuggestions="6"
                      :defaultDomains="defaultDomains[$store.state.region.macroregion]" />
                  </div>
                </div>
              </template>

              <template v-if="currentStep === 1">

                <template v-if="typeToSend === 'email'">

                  <div class="widget-input" v-if="recipientFields.includes('name')">
                    <div class='widget-input__wrapper'>
                      <label>{{ $t('form.recipient.name') }}</label>
                      <input v-model.lazy="recipientName" type="text" name="name" id="widget-input-recipient-name">
                    </div>
                  </div>

                  <div class="widget-input" :class="{ 'widget-input--error': $v.recipientEmail.$error }">
                    <div class='widget-input__wrapper'>
                      <label>{{ $t('form.recipient.email') }}*</label>
                      <EmailDropdown v-model.lazy="$v.recipientEmail.$model" :key="'recipient-3-email'"
                        @input="delayTouch($v.recipientEmail)" inputId="'recipient-3-email'"
                        :placeholder="$v.recipientEmail.$error ? $t('form.required_field') : ''"
                        :initial-value="getEmail($v.recipientEmail.$model)" :domains="domains" :maxSuggestions="6"
                        :defaultDomains="defaultDomains[$store.state.region.macroregion]" />
                    </div>
                  </div>

                  <div
                    class="widget-input-checkbox"
                    v-if="recipientFields.includes('phone')"
                    :class="{ 'widget-input-checkbox--active': checkedRecipientPhone }"
                  >
                    <div class='widget-input-checkbox__wrapper'>
                      <input class="widget-input-checkbox__input"
                        id="checkedPhoneNumberBlockEmail" v-model="checkedRecipientPhone" type="checkbox" />
                      <label for="checkedPhoneNumberBlockEmail">
                        <div class="widget-input-checkbox__icon">
                          <IconCheckEs v-if="checkedRecipientPhone" />
                        </div>
                        <span v-html="$t('form.recipient.checked_phone')"></span>
                      </label>
                    </div>
                  </div>

                  <div class="widget-input widget-input--phone"
                    :class="{ 'widget-input--error': $v.recipientPhone.$error }"
                    v-if="checkedRecipientPhone && recipientFields.includes('phone')">
                    <div class="widget-input__wrapper">
                      <!-- <label>{{ $t('form.recipient.phone') }}*</label> -->

                      <div class="widget-input__inputs">
                        <v-select class="country-select" :value="selectedCountry" :options="countries" label="country"
                          @input="selectCountry" :clearable="false" :searchable="false">
                          <template #selected-option="{ country }">
                            <RuFlag v-if="country === 'RU'" />
                            <KzFlag v-if="country === 'KZ'" />
                            <ByFlag v-if="country === 'BY'" />
                          </template>
                          <template #option="{ title, country }">
                            <div class="country-select__select-icon">
                              <RuFlag v-if="country === 'RU'" />
                              <KzFlag v-if="country === 'KZ'" />
                              <ByFlag v-if="country === 'BY'" />
                            </div>
                            <div class="country-select__select-text">
                              <template v-if="$store.state.region.macroregion === 'by'">{{ title }}</template>
                              <template v-else>{{ title }}</template>
                            </div>
                          </template>

                          <template #open-indicator="{ attributes }">
                            <div class="country-select__icon" v-bind="attributes">
                              <IconArrow style="transform: rotate(90deg)" />
                            </div>
                          </template>
                        </v-select>

                        <input-facade :mask="getFormatNumber()" :placeholder="getPlaceholderNumber()"
                          v-model="$v.recipientPhone.$model" @input="delayTouch($v.recipientPhone)"
                          :formatter="formatPhoneNumber" masked prefill type="tel" name="phone" />
                      </div>
                    </div>

                    <div class="widget-input-preview-link">
                      <span @click="openModalSMSPreview">Как выглядит SMS-уведомление?</span>
                    </div>
                  </div>
                </template>

                <template v-if="typeToSend === 'phone'">
                  <div class="widget-input" v-if="recipientFields.includes('name')">
                    <div class='widget-input__wrapper'>
                      <label>{{ $t('form.recipient.name') }}</label>
                      <input v-model.lazy="recipientName" type="text" name="name">
                    </div>
                  </div>


                  <div class="widget-input widget-input--phone"
                    :class="{ 'widget-input--error': $v.recipientPhone.$error }"
                    v-if="recipientFields.includes('phone')">
                    <div class="widget-input__wrapper">
                      <!-- <label>{{ $t('form.recipient.phone') }}*</label> -->

                      <div class="widget-input__inputs">
                        <v-select class="country-select" :value="selectedCountry" :options="countries" label="country"
                          @input="selectCountry" :clearable="false" :searchable="false">
                          <template #selected-option="{ country }">
                            <RuFlag v-if="country === 'RU'" />
                            <KzFlag v-if="country === 'KZ'" />
                            <ByFlag v-if="country === 'BY'" />
                          </template>
                          <template #option="{ title, country }">
                            <div class="country-select__select-icon">
                              <RuFlag v-if="country === 'RU'" />
                              <KzFlag v-if="country === 'KZ'" />
                              <ByFlag v-if="country === 'BY'" />
                            </div>
                            <div class="country-select__select-text">{{ title }}</div>
                          </template>
                          <template #open-indicator="{ attributes }">
                            <div class="country-select__icon" v-bind="attributes">
                              <IconArrow style="transform: rotate(90deg)" />
                            </div>
                          </template>
                        </v-select>

                        <input-facade :mask="getFormatNumber()" :placeholder="getPlaceholderNumber()"
                          v-model="$v.recipientPhone.$model" @input="delayTouch($v.recipientPhone)"
                          :formatter="formatPhoneNumber" masked prefill type="tel" name="phone" />


                      </div>
                    </div>
                    <div class="widget-input-preview-link">
                      <span @click="openModalSMSWithAGift">Как выглядит SMS с подарком?</span>
                    </div>
                  </div>
                </template>
              </template>

              <template v-if="currentStep === 2">
                <div v-show="$store.getters['card/current'].type !== 'test'">
                  <template v-if="modeTimeSend === 'exact'">
                    <send-time ref="sendTime"></send-time>
                  </template>
                  <template v-else>

                    <div class="widget-form-step__content-text widget-form-step__content-text--no-line">
                      {{ $t(`form.date.instant_text.${ $store.state.main.product.kind }`) }}
                    </div>
                  </template>
                </div>
              </template>

              <template v-if="currentStep === 3 && typeToSend !== 'phone'">
                <greeting is-big></greeting>
                <VideoBlock
                  :senderEmail="senderEmail"
                  :recipientEmail="recipientEmail"
                  :recipientPhone="recipientPhone" />
              </template>
            </template>


            <template v-if="type === 'myself'">
              <div class="widget-input" :class="{ 'widget-input--error': $v.recipientEmail.$error }">
                <div class='widget-input__wrapper'>
                  <label>{{ $t('form.recipient.your_email') }}*</label>
                  <EmailDropdown v-model.lazy="$v.recipientEmail.$model" :key="'recipient-1-email'"
                    @input="delayTouch($v.recipientEmail)" inputId="'recipient-1-email'"
                    :placeholder="$v.recipientEmail.$error ? $t('form.required_field') : ''"
                    :initial-value="getEmail($v.recipientEmail.$model)" :domains="domains" :maxSuggestions="6"
                    :defaultDomains="defaultDomains[$store.state.region.macroregion]" />
                </div>
              </div>
            </template>

          </div>


          <div class="widget-form-step__btns" :class="{ 'widget-form-step__btns-end': currentStep === 0 }">
            <default-btn :class="{
              'widget-action--form-back-to-sender': currentStep === 1,
              'widget-action--form-back-to-recipient': currentStep === 2,
              'widget-action--form-back-to-date': currentStep === 3 && typeToSend !== 'phone'
            }" grey class="widget-btn widget-btn--no-icon" @click="prevStep()" v-if="currentStep !== 0"
              :disabled="currentStep === 0">
              {{ $t('form.buttons.back') }}
            </default-btn>

            <template v-if="type === 'myself'">
              <template v-if="currentStep === 0 && $store.getters['card/current'].id === null">
                <default-btn class="widget-btn widget-btn--no-icon widget-action--form-pay" form @click="payMySelf()">
                  <span>{{ $t('form.buttons.to_cart') }}</span>
                </default-btn>
              </template>
            </template>

            <template v-if="type === 'friend'">
              <template v-if="currentStep === 0">
                <default-btn class="widget-btn widget-btn--no-icon widget-action--form-next-to-recipient" form @click="nextStep()">
                  <span>{{ $t('form.buttons.next') }}</span>
                </default-btn>
              </template>
              <template v-if="currentStep === 1">
                <default-btn class="widget-btn widget-btn--no-icon widget-action--form-next-to-date" form @click="nextStep()">
                  <span>{{ $t('form.buttons.next') }}</span>
                </default-btn>
              </template>
              <template v-if="currentStep === 2 && typeToSend !== 'phone'">
                <default-btn class="widget-btn widget-btn--no-icon widget-action--form-next-to-congratulations" form @click="nextStep()">
                  <span>{{ $t('form.buttons.next') }}</span>
                </default-btn>
              </template>
              <template v-if="$store.getters['card/current'].id === null">
                <template v-if="currentStep === 3 || (typeToSend === 'phone' && currentStep === 2)">
                  <default-btn class="widget-btn widget-btn--no-icon widget-action--form-pay" form @click="payMySelf()">
                    <span>{{ $t('form.buttons.to_cart') }}</span>
                  </default-btn>
                </template>
              </template>
            </template>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Greeting from './Greeting'
import { requiredIf } from 'vuelidate/lib/validators'
import { validateEmail } from 'app/modules/utils'
import EmailDropdown from './default/EmailDropdown'
import { domains, defaultDomains } from 'app/modules/constants'
import DefaultBtn from '../components/default/DefaultBtn'
import IconArrow from './icons/IconArrow'
import IconCheckEs from './icons/IconCheckEs'
import IconWarning from './icons/IconWarning'
import SendTime from '../components/SendTime'
import ModalSMSPreview from './modals/ModalSMSPreview'
import ModalSMSWithAGift from './modals/ModalSMSWithAGift'
import parsePhoneNumber from 'libphonenumber-js'
import parseMobilePhoneNumber from 'libphonenumber-js/mobile'

import RuFlag from './icons/countries/ru'
import KzFlag from './icons/countries/kz'
import ByFlag from './icons/countries/by'
import VideoBlock from './VideoBlock.vue'

const defaultRecipients = ['friend', 'myself']
const defaultWhereToSend = ['email', 'phone']
const touchMap = new WeakMap()
const countries = [
  { title: 'Russia (Россия)', country: 'RU', icon: 'ru.svg', format: '+7 (###) ###-##-##', placeholder: '+7' },
  { title: 'Kazakhstan (Казахстан)', country: 'KZ', icon: 'kz.svg', format: '+7 (###) ###-##-##', placeholder: '+7' },
  { title: 'Belarus (Беларусь)', country: 'BY', icon: 'by.svg', format: '+375 ## ###-##-##', placeholder: '+375' }
]
const defaultCountry = countries.find(item => item.country === 'RU')

export default {
  components: {
    VideoBlock,
    IconCheckEs,
    greeting: Greeting,
    'send-time': SendTime,
    EmailDropdown,
    DefaultBtn,
    IconArrow,
    IconWarning,
    RuFlag,
    KzFlag,
    ByFlag,
  },
  data() {
    return {
      domains,
      defaultDomains,
      selectedCountry: defaultCountry,
      countries: countries
    }
  },
  validations: {
    recipientEmail: {
      required: requiredIf(function () {
        return this.typeToSend === 'email' && !this.recipientEmail
      }),
      validateEmail: function (value) {
        if (this.typeToSend === 'email') {
          return validateEmail(value)
        } else {
          return !value
        }
      },
    },
    senderEmail: {
      required: requiredIf(function () {
        return this.type !== 'myself' && this.type !== 'test'
      }),
      validateEmail: function (value) {
        return this.type === 'myself' || this.type === 'test'
          ? true
          : validateEmail(value)
      },
    },
    recipientPhone: {
      required: requiredIf(function () {
        return (this.typeToSend === 'phone' || this.checkedRecipientPhone) && !this.recipientPhone
      }),
      validatePhone: function (value) {
        if (this.typeToSend === 'phone' || this.checkedRecipientPhone) {
          if (!value) {
            return false
          }

          const phoneNumber = parsePhoneNumber(value, this.selectedCountry.country)
          const mobilePhoneNumber = parseMobilePhoneNumber(value, this.selectedCountry.country)

          if (!phoneNumber || !mobilePhoneNumber) {
            return false
          }

          return value && phoneNumber.isValid() && mobilePhoneNumber.isValid()
        } else {
          return true
        }
      },
    },
  },
  computed: {
    currentStep: {
      set(step) {
        this.$store.commit('main/setCurrentFormStep', step)
      },
      get() {
        return this.$store.state.main.currentFormStep
      }
    },
    modeTimeSend: {
      set(mode) {
        this.$store.commit('card/setSendExactTime', mode === 'exact')
      },
      get() {
        return this.$store.state.card.current.sendExactTime ? 'exact' : 'now'
      }
    },
    available() {
      if (typeof this.$store.state.main.options.recipients === 'object') {
        return this.$store.state.main.options.recipients.filter((item) =>
          defaultRecipients.includes(item)
        )
      } else {
        return defaultRecipients
      }
    },
    availableToSend() {
      if (typeof this.$store.state.main.options.recipients === 'object') {
        return this.$store.state.main.options.recipients.filter((item) =>
          defaultWhereToSend.includes(item)
        )
      } else {
        return defaultWhereToSend
      }
    },
    type: {
      set(type) {
        this.$store.commit('card/setType', type)
      },
      get() {
        return this.$store.state.card.current.type
      },
    },
    typeToSend: {
      set(type) {
        if (type === 'email') {
          this.recipientPhone = ''
        }

        this.$store.commit('card/setTypeToSend', type)
      },
      get() {
        return this.$store.state.card.current.typeToSend
      },
    },
    recipientFields() {
      if (this.$store.state.main.recipientFields) {
        return this.$store.state.main.recipientFields
      } if (!['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)) {
        return ['name', 'email']
      } else {
        return ['name', 'email', 'phone']
      }
    },
    senderEmail: {
      set(senderEmail) {
        this.$store.commit('card/setSenderEmail', senderEmail)
      },
      get() {
        return this.$store.state.card.current.senderEmail
      },
    },
    senderPhone: {
      set(senderPhone) {
        this.$store.commit('card/setSenderPhone', senderPhone)
      },
      get() {
        return this.$store.state.card.current.senderPhone
      },
    },
    senderName: {
      set(senderName) {
        this.$store.commit('card/setSenderName', senderName)
      },
      get() {
        return this.$store.state.card.current.senderName
      },
    },
    recipientEmail: {
      set(recipientEmail) {
        this.$store.commit('card/setRecipientEmail', recipientEmail)
      },
      get() {
        return this.$store.state.card.current.recipientEmail
      },
    },
    recipientPhone: {
      set(recipientPhone) {
        if (recipientPhone) {
          const phoneNumber = parsePhoneNumber(recipientPhone, this.selectedCountry.country)

          if (phoneNumber && phoneNumber.country && phoneNumber.number) {
            this.selectedCountry = countries.find(country => country.country === phoneNumber.country)
            this.$store.commit('card/setRecipientCountryCode', phoneNumber.country)
            this.$store.commit('card/setRecipientNumberPhone', phoneNumber.number)
          }
        }

        this.$store.commit('card/setRecipientPhone', recipientPhone)
      },
      get() {
        return this.$store.state.card.current.recipientPhone
      },
    },
    recipientPhoneValid() {
      return this.$store.state.card.current.recipientNumberPhoneValid
    },
    checkedRecipientPhone: {
      set(checkedRecipientPhone) {
        this.$store.commit('card/setCheckedRecipientPhone', checkedRecipientPhone)

        if (!checkedRecipientPhone) {
          this.$store.commit('card/setRecipientPhone', '')
        }
      },
      get() {
        return this.$store.state.card.current.checkedRecipientPhone
      },
    },
    recipientName: {
      set(recipientName) {
        this.$store.commit('card/setRecipientName', recipientName)
      },
      get() {
        return this.$store.state.card.current.recipientName
      },
    },
    product() {
      return this.$store.state.main.product
    },
  },
  mounted() {
    this.$nextTick(() => {
      //временно убрали Беларусь из списка доступных стран для отправки СМС

      // if (this.product.region.toUpperCase() === 'BY') {
      //   const hasCountryBy = countries.some(country => country.country === 'BY')

      //   if (!hasCountryBy) {
      //     countries.push({
      //       title: 'Belarus (Беларусь)',
      //       country: 'BY',
      //       icon: 'by.svg',
      //       format: '+375 ## ###-##-##',
      //       placeholder: '+375'
      //     })
      //   }
      // }
      this.selectedCountry = countries.find(country => country.country === this.product.region.toUpperCase())

      if (this.availableToSend.length === 1 && this.availableToSend[0] === 'phone') {
        this.typeToSend = 'phone'
      }
      if (this.available.length === 1 && this.available[0] === 'friend') {
        this.type = 'friend'
      }
    })
  },
  destroyed() {
    this.$store.commit('main/setCurrentFormStep', 0)
  },
  methods: {
    maxSteps() {
      if (this.typeToSend === 'phone') {
        return '3'
      }

      return this.type === 'friend' ? 4 : 1
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1
      }
    },
    nextStep() {
      switch (this.currentStep) {
        case 0: {
          if (!this.$v.senderEmail.$invalid) {
            this.goNextStep()
          } else {
            this.$v.senderEmail.$touch()
          }

          break
        }

        case 1: {
          if (this.type === 'myself') {
            if (!this.$v.recipientEmail.$invalid) {
              this.goNextStep()
            } else {
              this.$v.recipientEmail.$touch()
            }
          } else if (this.checkedRecipientPhone && this.typeToSend === 'email') {
            if (!this.$v.recipientEmail.$invalid && !this.$v.recipientPhone.$invalid) {
              this.goNextStep()
            } else {
              this.$v.recipientEmail.$touch()
              this.$v.recipientPhone.$touch()
            }
          } else if (this.typeToSend === 'phone') {
            if (!this.$v.recipientPhone.$invalid) {
              this.goNextStep()
            } else {
              this.$v.recipientPhone.$touch()
            }
          } else {
            if (!this.$v.recipientEmail.$invalid) {
              this.goNextStep()
            } else {
              this.$v.recipientEmail.$touch()
            }
          }

          break
        }

        case 2: {
          if (this.modeTimeSend === 'exact') {
            if (!this.$refs.sendTime.$v.$invalid) {
              this.goNextStep()
            } else {
              this.$refs.sendTime.$v.$touch()
            }
          } else {
            this.goNextStep()
          }

          break
        }

        default: {

          break
        }
      }
    },
    goNextStep() {
      if (this.currentStep < this.maxSteps()) {
        this.currentStep += 1
      }
    },
    delayTouch($v) {
      $v.$reset()

      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }

      touchMap.set($v, setTimeout($v.$touch, 1500))
    },
    selectCountry(country) {
      this.selectedCountry = country

      this.$store.commit('card/setRecipientPhone', '')
    },
    resetValidation() {
      this.$parent.$refs.recipient.$v.$reset()

      if (this.$refs.sendTime) {
        this.$refs.sendTime.$v.$reset()
      }

      this.$nextTick()
    },
    resetRecipientEmail() {
      this.recipientEmail = ''
    },
    resetRecipientPhone() {
      if (this.typeToSend === 'phone' || this.checkedRecipientPhone) {
        this.recipientPhone = ''
        this.checkedRecipientPhone = false
      }
    },
    getEmail(email) {
      return email && typeof email === 'string' ? email : ''
    },
    payMySelf() {
      this.$emit('pay-my-self')
    },
    getFormatNumber() {
      const currentCountry = this.countries.find(country => country.country === this.selectedCountry.country)

      return currentCountry.format
    },
    getPlaceholderNumber() {
      const currentCountry = this.countries.find(country => country.country === this.selectedCountry.country)

      return currentCountry.placeholder
    },
    formatPhoneNumber(value) {
      let splitStr = value.masked.split('')

      if (this.selectedCountry.country !== 'BY') {
        if (splitStr.length > 3 && splitStr[4] === '9') {
          this.selectedCountry = countries.find(item => item.country === 'RU')
        }

        if (splitStr.length > 3 && splitStr[4] === '7') {
          this.selectedCountry = countries.find(item => item.country === 'KZ')
        }

        /*if (event.inputType === 'insertFromPaste') {
          if (splitStr[4] === '8') {
            splitStr[4] = ''
          }

          splitStr[1] = '7'
        } else if (event.inputType !== 'deleteContentBackward') {
          if (this.selectedCountry.country === 'RU' && splitStr[4] !== '9') {
            splitStr[4] = '9'
          } else if (this.selectedCountry.country === 'KZ' && splitStr[4] !== '7') {
            splitStr[4] = '7'
          }

          splitStr[1] = '7'
        }*/
      }

      return splitStr.join('')
    },
    openModalSMSPreview() {
      this.$vfm.show({
        component: ModalSMSPreview,
      })
    },
    openModalSMSWithAGift() {
      this.$vfm.show({
        component: ModalSMSWithAGift,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.widget-form-step {
  display: grid;
  grid-template-columns: rem(320) rem(480);
  grid-gap: rem(20);

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
  }

  &__header {
    height: rem(50);
    background-color: #E9EBEC;
    font-weight: 600;
    font-size: rem(15);
    line-height: 0;
    color: #0B132A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(20) rem(20) 0 0;
    padding: rem(14) rem(20);

    &-title {
      display: flex;
      align-items: center;
    }

    &--only-title {
      background-color: #E9EBEC;
      color: #0B132A;
      justify-content: flex-start;
    }
  }

  &__content {
    background-color: #ffffff;
    font-size: rem(16);
    line-height: 120%;
    color: #0E1E0E;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 rem(20) rem(20);
    padding: rem(43) rem(20) rem(20);

    &-text {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
      position: relative;
      padding: rem(3) 0;

      &::after {
        height: rem(1);
        background-color: #6E7882;
        content: '';
        opacity: 0.2;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &--no-line {
        &::after {
          background-color: transparent;
        }
      }
    }
  }

  &__inputs {
    margin-bottom: rem(20);

    .widget-input {
      margin-bottom: rem(20);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-end {
      justify-content: flex-end;
    }

    .btn {
      margin-left: rem(10);
    }
  }

  &__progress {
    height: rem(4);
    background-color: #F1F2F3;
    flex-grow: 1;
    border-radius: rem(4);
    margin: auto rem(30);

    &-bar {
      height: rem(4);
      background-color: #6E7882;
      border-radius: rem(4);
    }

    &--no-back {
      margin-left: 0;
    }
  }

  &__text {
    font-size: rem(13);
    line-height: 140%;
    color: #666666;
    padding-right: rem(10);

    &--separator {
      padding-top: rem(10);
    }

    &--no-line {
      border-top: none;
      padding-top: 0;
    }
  }

  &--height-380 {
    min-height: rem(320);
  }

  &--height-410 {
    min-height: rem(300);
  }
}

.widget-radio-btn {
  margin-bottom: rem(20);

  &:last-child {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .widget-radio-btn__checkmark > span {
      background: var(--theme-primary-background-component);
    }

    &:checked ~ .widget-radio-btn__content {
      color: #0B132A;
    }
  }

  label {
    font-size: rem(15);
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__checkmark {
    width: rem(18);
    height: rem(18);
    font-size: 0;
    line-height: 0;
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: rem(1) solid #E9EBEC;
    border-radius: 50%;
    cursor: pointer;

    & > span {
      width: rem(18);
      height: rem(18);
      background-color: #E9EBEC;
      display: inline-flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding-top: rem(1);
    }
  }

  &__content {
    color: #6E7882;
    margin-left: rem(16);
  }

  &--active {
    .widget-radio-btn__checkmark > span {
      background: var(--theme-primary-background-component);
    }
  }
}

.widget-input-checkbox {
  margin-top: rem(-6);
  margin-bottom: rem(14);
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  input {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
  }

  label {
    font-size: rem(11);
    line-height: 140%;
    color: #6E7882;
    position: relative;
    padding-top: rem(1);
    padding-left: rem(24);
    transition: 0.3s;
    cursor: pointer;
  }

  &__icon {
    width: rem(18);
    height: rem(18);
    background-color: #E9EBEC;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: 0.1s;
  }

  &--active {
    .widget-input-checkbox__icon {
      background: var(--theme-primary-background-component);
    }
  }
}


.widget-input::v-deep {
  input {
    width: 100%;
    font-size: rem(15);
    line-height: 140%;
    color: #0B132A;
    border: none;
    outline: none;
    padding: rem(3) rem(26) rem(3) 0;
    border-bottom: rem(1) solid rgb(110 120 130 / 20%);
  }

  .email-dropdown-item:focus-visible {
    outline: none;
  }

  .email-dropdown-wrapper .email-dropdown-list .email-dropdown-item {
    padding: rem(4) rem(8);
    font-size: rem(13);
    border: none;

    &:focus,
    &:hover {
      border: none;
    }
  }

  &.widget-input--error {
    input {
      border-bottom-color: #FA6400;
    }

    .vti__dropdown {
      border-bottom-color: #FA6400;
    }
  }
}

.widget-input {
  &--phone {
    margin-top: rem(-8);
    .widget-input__inputs {
      height: rem(42);
      display: flex;
    }

    input {
      height: rem(42);
      padding-bottom: rem(2);
      padding-top: rem(4);

      &::placeholder {
        color: #0B132A;
      }
    }

    &.widget-input--error {
      .country-select {
        border-bottom-color: #FA6400;
      }
    }
  }
}

.country-select {
  cursor: pointer;

  &__icon {
    width: rem(30);
    height: rem(6);
    display: flex;
    align-items: center;
    justify-content: center;
    content: '';
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-icon {
      width: rem(23);
      height: rem(16);
      border-radius: rem(4);
      box-shadow: 0 0 rem(1) rem(1) #E9EBEC;
    }

    &-text {
      padding-top: rem(2);
      margin-left: rem(13);
    }
  }

  &::v-deep {
    .country-select__select-icon {
      svg {
        width: rem(23);
        height: rem(16);
        opacity: 1;
      }
    }
  }
}

.country-select::v-deep {
  max-width: rem(60);
  border-bottom: rem(1) solid rgb(110 120 130 / 20%);

  &.vs--open {
    width: 100%;

    .country-select__icon {
      transition: 0.2s;
      transform: rotate(180deg);
    }
  }

  .vs__dropdown-toggle {
    width: rem(60);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: static;
    border-radius: 0;
    padding: 0 rem(3) 0 0;
    transition: background-color .2s;

    @include mobile {
      background-color: #ffffff;
      padding-left: 0;
    }
  }

  .vs__selected {
    width: rem(25);
    height: rem(18);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(4);

    svg {
      width: rem(25);
      height: rem(18);
      opacity: 1;
      border-radius: rem(4);
    }
  }

  .vs__selected-options {
    font-weight: 600;
    font-size: rem(15);
    line-height: rem(19);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: rem(4);
    box-shadow: 0 0 rem(1) rem(1) #E9EBEC;

    @include mobile {
      font-weight: 600;
      font-size: rem(16);
      color: #0B132A;
    }
  }

  input {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
    background-color: transparent;
  }

  .vs__dropdown-menu {
    width: rem(300);
    background-color: #ffffff;
    overflow-y: scroll;
    position: relative;
    z-index: 2;
    border-radius: rem(3);
    border: rem(1) solid #F1F2F3;
    box-shadow: 0 rem(6) rem(16) rgba(110, 120, 130, 0.12);
    padding: rem(5) 0;
    margin: rem(10) 0 0;

    @include mobile {
      width: rem(139);
      left: rem(-15);
      margin-top: rem(15);
    }
  }

  .vs__dropdown-option {
    height: rem(36);
    background-color: #ffffff;
    font-size: rem(15);
    line-height: 1;
    color: #0B132A;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-top: rem(2);
    padding-left: rem(13);
    padding-right: rem(5);

    &:hover {
      background-color: #F3F5F6;
      font-weight: 600;
      color: #0B132A;
    }
  }

  * {
    &::-webkit-scrollbar {
      width: rem(6);
      height: rem(5);
      appearance: none;
    }

    &::-webkit-scrollbar-track {
      background-color: #F9FAFB;
      border-radius: rem(3);
      border: 1px solid #F9FAFB;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-primary-background-component);
      border-radius: 3px;
      box-shadow: none;
    }
  }
}

.widget-tooltip {
  position: relative;
  appearance: none;
  margin-left: rem(6);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    max-width: rem(250);
    min-width: rem(250);
    background: #ffffff;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0B132A;
    text-align: left;
    position: absolute;
    left: rem(4);
    bottom: rem(40);
    opacity: 0;
    border-radius: rem(10);
    padding: rem(14) rem(20);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    @include mobile {
      top: rem(-30);
      right: rem(-85);
      bottom: auto;
      left: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      right: rem(104);
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #ffffff;
      transform: translate(-50%, 0);

      @include mobile {
        top: rem(32);
        right: rem(-19);
        border-top-color: transparent;
        border-left-color: #ffffff;
      }
    }
  }

  &:hover {
    cursor: help;

    .widget-tooltip__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.widget-input-preview-link {
  font-size: rem(14);
  margin-top: rem(20);

  span {
    border-bottom: rem(1) solid #0B132A;
    cursor: pointer;
  }
}
</style>
