<template>
  <div class="face-selector">
    <div class="face-selector__container">
      <div class="face-selector__select" v-show="hasMultipleFaces">
        <v-select class="face-selector-select" :options="options" v-model="currentFace" label="face" :clearable="false"
          :searchable="false" @option:selecting="openModalFace">
          <template #option="{ face }">
            <div class="face-selector-select__item"
              :class="{ 'face-selector-select__item--no-desc': !getDescription(face) }">
              <div class="face-selector-select__item-face">
                {{ face === 0 ? $t('form.enter_manually') : numberFormat(face, 0, ',', ' ') + '&nbsp;' +
                    $store.state.region.currency
                }}
              </div>
              <div class="face-selector-select__item-desc" v-if="getDescription(face)">
                {{ getDescription(face) }}
              </div>
            </div>
          </template>

          <template #open-indicator="{ attributes }">
            <div class="face-selector-select__icon" v-bind="attributes">
              <IconArrow style="transform: rotate(90deg)" />
            </div>
          </template>
        </v-select>
      </div>

      <div class="face-selector__select-single" v-show="!hasMultipleFaces">
        <span class="face-selector__select-single-face">{{ $store.state.main.faces[0] }}&nbsp;{{
            $store.state.region.currency
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormat } from 'app/modules/utils'
import ModalFace from './modals/ModalFace'
import IconArrow from './icons/IconArrow'

// Номиналы для тестовых виджетов, если отсутствуют
function setDemoFaces(store) {
  if (store.state.main.isDemo &&
    store.state.main.product.properties &&
    store.state.main.product.properties.faces &&
    store.state.main.product.properties.faces.length === 0
  ) {
    store.dispatch('main/setDemoFaces', [1000, 2000, 3000, 5000])
  }
}

export default {
  components: { IconArrow },
  data() {
    return {
      selectedFace: null,
      manualFace: null
    }
  },
  computed: {
    options: {
      get() {
        return this.$store.state.main.faces.map(face => {
          return {
            face: face
          }
        })
      }
    },
    currentFace: {
      set(currentFace) {
        this.$emit('set-description', this.getDescription(currentFace.face))

        if (currentFace.face !== 0) {
          this.$store.commit('card/setFace', currentFace.face)
        }
      },
      get() {
        const face = this.$store.state.card.current.face

        this.$emit('set-description', this.getDescription(face))

        return numberFormat(face, 0, ',', ' ') + ' ' + this.$store.state.region.currency
      }
    },
    product() {
      setDemoFaces(this.$store)

      return this.$store.state.main.product
    },
    descriptions() {
      if (typeof this.product.properties !== 'undefined' && typeof this.product.properties.faces_description !== 'undefined' && this.product.properties.faces_description !== null) {
        return this.product.properties.faces_description.map(value => {
          let [faceValue, faceDescription] = value.split('|')
          return {
            face: parseInt(faceValue),
            description: faceDescription
          }
        })
      } else {
        return []
      }
    },
    hasMultipleFaces() {
      return this.$store.state.main.faces && this.$store.state.main.faces.length > 1
    },
    hasCustom() {
      return !!~this.$store.state.main.faces.indexOf(0)
    }
  },
  watch: {
    selectedFace: function (newValue) {
      if (newValue !== 0) {
        this.$store.commit('card/setFace', newValue)
      } else {
        // this.$store.commit('card/setFace', null)
      }
    }
  },
  methods: {
    openModalFace($e) {
      if ($e.face === 0) {
        this.$modal.show(ModalFace, {
          store: this.$store,
          locale: this,
          title: this.$t('modals.title_pay_fail'),
          buttonOk: this.$t('modals.buttons.ok'),
          buttonCancel: this.$t('modals.buttons.cancel')
        })
      }
    },
    getDescription(face) {
      if (typeof this.descriptions !== 'undefined' && this.descriptions.length > 0 && face !== null) {
        const valDesc = this.descriptions.find(value => {
          return value.face === face
        })

        return valDesc && valDesc.description ? valDesc.description : ''
      } else {
        return ''
      }
    },
    numberFormat(...args) {
      return numberFormat.apply(this, args)
    }
  },
  mounted() {
    if (this.currentFace === null) {
      this.currentFace = this.$store.state.main.faces.filter(face => face !== 0)[0]
    }

    if (this.$store.state.main.options.face && parseInt(this.$store.state.main.options.face) > 0) {
      this.selectedFace = parseInt(this.$store.state.main.options.face)
    } else {
      if (typeof this.$store.state.main.options.face !== 'undefined' && parseInt(this.$store.state.main.options.face) > 0) {
        this.selectedFace = parseInt(this.$store.state.main.options.face)
      } else {
        if (this.$store.state.card.current.face === null) {
          this.selectedFace = this.$store.state.main.faces.filter(face => face !== 0)[0]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.face-selector {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.face-selector__container {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.face-selector__input {
  margin-left: rem(10);
  position: relative;
  border-radius: rem(10);
  background-color: #f0f0f0;
  transition: background-color .2s;
}

.face-selector__input:hover {
  background-color: #f6f6f6;
}

.face-selector__input-node {
  width: rem(87);
  height: rem(40);
  background-color: transparent;
  font-size: rem(15);
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
  padding-right: rem(20);
  padding-left: rem(17);
  border: none;
  appearance: none;
}

.face-selector__input-node:focus {
  outline: none;
}

.face-selector__input-node::-moz-focus-inner {
  outline: none;
}

.face-selector__input i {
  position: absolute;
  top: 50%;
  left: rem(12);
  line-height: 0;
  transform: translateY(-50%);
  color: #666;
}

.face-selector__select {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-single {
    height: rem(40);
    background: var(--theme-primary-background-component);
    font-weight: 600;
    font-size: rem(15);
    line-height: 1;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: rem(10);
    padding: 0 rem(16);
    box-shadow: 0 rem(6) rem(16) rgba(110, 120, 130, 0.12);

    @include mobile {
      background: var(--theme-primary-background-component);
    }
  }
}

.categories__select-toggle {
  height: rem(40);
  font-size: rem(16);
  color: #0B132A;
}

.face-selector-select {
  width: rem(140);
  height: rem(40);
  cursor: pointer;

  @include mobile {
    width: rem(140);
  }

  &__icon {
    width: rem(40);
    height: rem(40);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '';
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    &::v-deep {
      .svg-content {
        fill: var(--theme-primary-text-color-component);
        @include mobile {
          fill: #0B132A;
        }
      }
    }
  }

  &__item {
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-face {
      font-weight: 500;
      padding: rem(1) 0;
    }

    &-desc {
      font-size: rem(10);
      font-weight: 400;
      padding: rem(1) 0;
    }
  }

  &::v-deep {
    svg {
      opacity: 1;
    }

    &:hover {
      .vs__dropdown-toggle {
        background: var(--theme-primary-background-component);

        @include mobile {
          background: #F6F6F6;
          color: var(--theme-primary-text-color-component);
        }
      }
    }

    .face-selector-select__item {
      &--no-desc {
        height: rem(40);
        padding: 0;

        .face-selector-select__item-face {
          padding: 0;
        }
      }
    }
  }
}

.face-selector-select::v-deep {
  .vs__dropdown-toggle {
    height: 100%;
    background: var(--theme-primary-background-component);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: rem(10);
    padding: 0 rem(3) 0 rem(16);
    transition: background-color .2s;

    @include mobile {
      background: #F6F6F6;
    }

    &:hover {
      background: var(--theme-primary-background-component);

      .vs__selected-options {
        color: var(--theme-primary-text-color-component);
      }

      svg {
        .svg-content {
          @include mobile {
            fill: #fff !important;
          }
        }
      }
    }

    &::v-deep {
      .svg-content {
        fill: #fff;
      }
    }
  }

  .vs__selected-options {
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(19);
    color: var(--theme-primary-text-color-component);
    display: flex;
    align-items: center;

    @include mobile {
      font-weight: 600;
      font-size: rem(16);
      color: #0B132A;
    }
  }

  input {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
    background-color: transparent;
  }

  .vs__dropdown-menu {
    width: auto;
    min-width: rem(140);
    max-height: rem(195);
    background-color: #ffffff;
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    border-radius: rem(6);
    border: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1),
      10px 10px 10px rgba(110, 120, 130, 0.05),
      20px 10px 80px rgba(110, 120, 130, 0.1);
    padding: 0;
    margin: rem(10) 0 0;

    @include mobile {
      width: rem(300);
      left: 0;
      border: rem(1) solid #E9EBEC;
      box-shadow: none;
      margin-top: rem(15);
    }
  }

  .vs__dropdown-option {
    height: rem(40);
    width: 100%;
    min-width: rem(140);
    background-color: #ffffff;
    font-size: rem(13);
    line-height: 1;
    color: #6E7882;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 rem(19) 0 rem(14);

    @include mobile {
      width: rem(300);
      max-width: 100%;
    }

    &:hover {
      background-color: #F6F6F6;
      color: #0B132A;

      @include mobile {
        background: #F6F6F6;
      }
    }
  }

  * {
    scrollbar-color: var(--theme-secondary-background-component) #F9FAFB;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: rem(3);
      height: rem(5);
      appearance: none;
    }

    &::-webkit-scrollbar-track {
      background-color: #F9FAFB;
      border-radius: rem(3);
      border: rem(1) solid #F9FAFB;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-primary-background-component);
      border-radius: rem(3);
      box-shadow: none;
    }
  }
}
</style>
