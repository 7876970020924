<template>
  <div class="card-add">
    <AddCard v-if="!$store.state.main.isMobile" class="card-add__pc" />
    <AddCardMobile v-else class="card-add__mobile" />
  </div>
</template>

<script>
import AddCard from '../components/AddCard'
import AddCardMobile from '../components/AddCardMobile'

export default {
  components: { AddCard, AddCardMobile },
}
</script>

<style lang="scss" scoped>
.card-add {
  &__pc {
    @include mobile {
      display: none;
    }
  }

  &__mobile {
    display: none;
    @include mobile {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
