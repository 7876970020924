<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 6.33366L7 8.33366L13.6667 1.66699" :stroke="color" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13 7V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H9.66667" :stroke="color" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckboxChecked',
  props: {
    color: {
      type: String,
      default: '#FA6400'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(15);
  height: rem(14);
}
</style>
