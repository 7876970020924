<template>
  <svg width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2418:877)">
      <rect width="40" height="26.6647" fill="#F9FAFB"/>
      <path d="M0 3.33309C0 1.49228 1.49239 0 3.33333 0H36.6667C38.5077 0 40 1.49228 40 3.33309V23.3317C40 25.1725 38.5077 26.6647 36.6667 26.6647H3.33333C1.49238 26.6647 0 25.1725 0 23.3317V3.33309Z" fill="#6E7882" fill-opacity="0.1"/>
      <path d="M9.41802 10.415C9.01683 10.1392 8.49331 10.001 7.84746 10.001H5.34709C5.1491 10.001 5.03972 10.1 5.01894 10.2978L4.00319 16.6736C3.99263 16.7363 4.0083 16.7935 4.05005 16.8456C4.09156 16.8978 4.14376 16.9237 4.20629 16.9237H5.39395C5.60226 16.9237 5.71675 16.825 5.73777 16.6268L6.01902 14.908C6.02929 14.8246 6.06592 14.7569 6.12845 14.7048C6.19093 14.6527 6.26907 14.6186 6.36284 14.603C6.4566 14.5875 6.54502 14.5798 6.62852 14.5798C6.71177 14.5798 6.8107 14.585 6.92548 14.5954C7.03997 14.6058 7.11296 14.6108 7.14425 14.6108C8.04015 14.6108 8.74337 14.3584 9.25394 13.853C9.76427 13.3478 10.0197 12.6472 10.0197 11.7511C10.0197 11.1364 9.81901 10.6911 9.41802 10.4148V10.415ZM8.12881 12.3295C8.07655 12.6942 7.94127 12.9337 7.72251 13.0483C7.50369 13.1631 7.19115 13.2201 6.7849 13.2201L6.26917 13.2358L6.53489 11.5636C6.55562 11.4492 6.62331 11.3918 6.738 11.3918H7.03501C7.45153 11.3918 7.75384 11.4519 7.94137 11.5715C8.12881 11.6914 8.19133 11.9442 8.12881 12.3295Z" fill="#003087"/>
      <path d="M35.7972 10H34.6409C34.526 10 34.4584 10.0573 34.4378 10.1719L33.4219 16.6729L33.4062 16.7041C33.4062 16.7565 33.4271 16.8057 33.4689 16.8526C33.5103 16.8994 33.5626 16.9229 33.6251 16.9229H34.6566C34.8543 16.9229 34.9637 16.8241 34.9848 16.626L36.0006 10.2344V10.2189C36.0005 10.0731 35.9326 10.0001 35.7972 10.0001V10Z" fill="#009CDE"/>
      <path d="M21.8289 12.5161C21.8289 12.4642 21.808 12.4146 21.7665 12.3678C21.7247 12.3209 21.6778 12.2974 21.6258 12.2974H20.4226C20.3077 12.2974 20.214 12.3497 20.1412 12.4536L18.4847 14.8916L17.7972 12.5475C17.7448 12.3809 17.6303 12.2974 17.4534 12.2974H16.2812C16.229 12.2974 16.1821 12.3209 16.1407 12.3678C16.0989 12.4146 16.0781 12.4642 16.0781 12.5161C16.0781 12.5371 16.1797 12.8443 16.3828 13.4382C16.5859 14.0321 16.8047 14.6728 17.0392 15.3605C17.2736 16.048 17.3959 16.4128 17.4064 16.4542C16.5521 17.6211 16.125 18.2463 16.125 18.3295C16.125 18.4651 16.1927 18.5327 16.3282 18.5327H17.5315C17.6461 18.5327 17.7398 18.4808 17.8128 18.3765L21.7978 12.6254C21.8185 12.6047 21.8289 12.5684 21.8289 12.5161V12.5161Z" fill="#003087"/>
      <path d="M33.0321 12.2974H31.8444C31.6984 12.2974 31.6101 12.4693 31.5788 12.8132C31.3076 12.3966 30.8131 12.188 30.0941 12.188C29.344 12.188 28.7058 12.4693 28.1798 13.0319C27.6536 13.5946 27.3906 14.2562 27.3906 15.0167C27.3906 15.6315 27.5703 16.1211 27.9297 16.4856C28.2891 16.8504 28.7709 17.0325 29.3754 17.0325C29.6774 17.0325 29.9847 16.9699 30.2972 16.845C30.6098 16.72 30.8544 16.5534 31.0318 16.3449C31.0318 16.3554 31.0212 16.4022 31.0006 16.4855C30.9796 16.569 30.9693 16.6316 30.9693 16.673C30.9693 16.8399 31.0369 16.923 31.1725 16.923H32.2509C32.4485 16.923 32.5633 16.8242 32.5945 16.6261L33.2353 12.5474C33.2455 12.4849 33.23 12.4276 33.1884 12.3755C33.1466 12.3235 33.0946 12.2974 33.0321 12.2974ZM30.9927 15.376C30.727 15.6365 30.4067 15.7667 30.0317 15.7667C29.7294 15.7667 29.4848 15.6835 29.2972 15.5167C29.1095 15.3504 29.0158 15.1211 29.0158 14.8291C29.0158 14.4439 29.146 14.1181 29.4066 13.8525C29.6667 13.5868 29.99 13.4539 30.3754 13.4539C30.6669 13.4539 30.9092 13.5399 31.1021 13.7117C31.2947 13.8836 31.3913 14.1208 31.3913 14.4229C31.3912 14.7979 31.2584 15.1157 30.9927 15.376Z" fill="#009CDE"/>
      <path d="M15.2782 12.2974H14.0906C13.9445 12.2974 13.8561 12.4693 13.8249 12.8132C13.5436 12.3966 13.0487 12.188 12.3402 12.188C11.5901 12.188 10.9519 12.4693 10.4259 13.0319C9.89967 13.5946 9.63672 14.2562 9.63672 15.0167C9.63672 15.6315 9.81646 16.1211 10.1759 16.4856C10.5353 16.8504 11.0171 17.0325 11.6214 17.0325C11.913 17.0325 12.2153 16.9699 12.5278 16.845C12.8403 16.72 13.0904 16.5534 13.2779 16.3449C13.2361 16.4699 13.2154 16.5793 13.2154 16.673C13.2154 16.8399 13.283 16.923 13.4185 16.923H14.4968C14.6946 16.923 14.8093 16.8242 14.8406 16.6261L15.4813 12.5474C15.4916 12.4849 15.4759 12.4276 15.4344 12.3755C15.3927 12.3235 15.3407 12.2974 15.2782 12.2974ZM13.2389 15.3838C12.9731 15.6394 12.6474 15.7667 12.2622 15.7667C11.9599 15.7667 11.7177 15.6835 11.5355 15.5167C11.3531 15.3504 11.262 15.1211 11.262 14.8291C11.262 14.4439 11.3921 14.1181 11.6527 13.8525C11.913 13.5868 12.236 13.4539 12.6216 13.4539C12.9131 13.4539 13.1554 13.5399 13.3483 13.7118C13.5409 13.8837 13.6374 14.1208 13.6374 14.4229C13.6374 14.8083 13.5045 15.1288 13.2389 15.3838Z" fill="#003087"/>
      <path d="M27.172 10.414C26.7708 10.1382 26.2474 10 25.6015 10H23.1167C22.9082 10 22.7935 10.099 22.7729 10.2968L21.7571 16.6727C21.7465 16.7353 21.7622 16.7926 21.804 16.8447C21.8453 16.8968 21.8977 16.9228 21.9602 16.9228H23.2416C23.3666 16.9228 23.4499 16.8551 23.4916 16.7197L23.773 14.907C23.7833 14.8237 23.8198 14.7559 23.8824 14.7038C23.9449 14.6517 24.0229 14.6177 24.1168 14.602C24.2105 14.5866 24.2989 14.5788 24.3825 14.5788C24.4657 14.5788 24.5647 14.584 24.6793 14.5944C24.7939 14.6048 24.8671 14.6099 24.8981 14.6099C25.7942 14.6099 26.4972 14.3574 27.0078 13.852C27.5183 13.3468 27.7735 12.6462 27.7735 11.7501C27.7736 11.1355 27.573 10.6901 27.172 10.4139V10.414ZM25.5702 12.9848C25.341 13.1411 24.9971 13.2192 24.5388 13.2192L24.0387 13.2348L24.3044 11.5627C24.325 11.4482 24.3927 11.3909 24.5075 11.3909H24.7887C25.0179 11.3909 25.2001 11.4013 25.3358 11.422C25.471 11.4431 25.6015 11.508 25.7265 11.6174C25.8515 11.7268 25.914 11.8857 25.914 12.0941C25.914 12.5316 25.7993 12.8285 25.5702 12.9848Z" fill="#009CDE"/>
    </g>
    <defs>
      <clipPath id="clip0_2418:877">
        <rect width="40" height="26.6647" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImgPayPal'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(40);
  height: rem(27);
}
</style>
