const module = {
  namespaced: true,
  state: {
    checkBalance: false,
    corporateOrder: true,
  },
  getters: {
    checkBalance: state => {
      return state.checkBalance
    },
    corporateOrder: state => {
      return state.corporateOrder
    }
  },
  mutations: {
    setCheckBalance (state, payload) {
      state.checkBalance = payload
    },
    setCorporateOrder (state, payload) {
      state.corporateOrder = payload
    }
  },
  actions: {
    setCheckBalance: ({ commit }, payload) => {
      commit('setCheckBalance', payload)
    },
    setCorporateOrder: ({ commit }, payload) => {
      commit('setCorporateOrder', payload)
    }
  }
}

export default module
