<template>
  <button :class="{
    'btn--disabled': disabled,
    'btn--link': to,
    'btn--white': white,
    'btn--grey': grey,
    'btn--round': round,
    'btn--link': isLink,
    'btn--link-header': isLinkHeader,
    'btn--small': small,
    'btn--big': big,
    'btn--form': form,
    'btn--full-width': fullWidth,
  }" :disabled="disabled" :href="href" :is="to ? 'router-link' : href ? 'a' : 'button'"
    :target="href ? target || '_blank' : undefined" @click="!disabled && $emit('click', $event)"
    :to="!disabled ? to || undefined : ''" class="btn">
    <slot></slot>
  </button>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DefaultBtn',
  props: {
    href: {
      type: String,
    },
    target: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: String
    },
    isLink: {
      type: Boolean
    },
    isLinkHeader: {
      type: Boolean
    },
    white: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    form: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
});
</script>

<style lang="scss" scoped>
.btn {
  height: rem(40);
  background: var(--theme-primary-background-component);
  font-size: rem(15);
  font-weight: 600;
  line-height: 1;
  color: var(--theme-primary-text-color-component);
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: all 0.3s;
  cursor: pointer;
  border: none;
  appearance: none;
  border-radius: rem(10);
  padding: 0 rem(40);

  @include mobile {
    font-size: rem(15);
    line-height: 1;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: none !important;
    transition: all 0.3s;
  }

  &:active,
  &:hover {
    background: var(--theme-primary-background-component);
  }

  &:active {
    @include mobile {
      background: var(--theme-primary-background-component);
    }
  }

  &--white {
    background: #ffffff;
    color: #0E1E0E;

    &:hover,
    &:active {
      background: #E9EBEC;
    }
  }

  &--big {
    height: rem(40);

    @include mobile {
      font-size: rem(15);
      line-height: 1;
    }
  }

  &--grey {
    background: #F6F6F6;
    color: #0B132A;

    &:hover,
    &:active {
      background: #E9EBEC;
    }
  }

  &--orange {
    background: rgba(250, 100, 0, 0.1);
    color: #FA6400;
    border: rem(1) solid #FA6400;

    &:hover {
      background: rgba(250, 100, 0, 0.2);
    }
  }

  &--round {
    width: rem(40);
    height: rem(40);
    background: #F6F6F6;
    flex-shrink: 0;
    border-radius: 50%;
    padding: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover,
    &:active {
      background: #E9EBEC;
    }
  }

  &--link {
    width: auto;
    height: auto;
    background: transparent;
    font-weight: 400;
    font-size: rem(13);
    color: #0B132A;
    text-decoration: underline;
    display: inline;
    box-shadow: none;
    padding: 0;

    &:active,
    &:hover {
      background: transparent;
      opacity: 1;
    }
  }

  &--link-header {
    width: auto;
    height: auto;
    background: transparent;
    font-size: rem(15);
    line-height: 1;
    font-weight: 400;
    color: #0E1E0E;
    text-decoration: none;
    display: inline;
    box-shadow: none;
    padding: 0;

    &:active,
    &:hover {
      background: transparent;
    }
  }

  &--small {
    height: rem(44);
    min-width: rem(122);

    @include mobile {
      width: rem(40);
      font-size: rem(15);
    }
  }

  &--full-width {
    width: 100%;
  }

  &--disabled {
    cursor: default;
    background: #F6F6F6;
    color: #d6d6d6;

    &:hover,
    &:active {
      background: #F6F6F6;
    }
  }
}

.btn::v-deep {
  &.btn--disabled {
    svg {
      opacity: 0.4;
    }
  }

  &:not(.btn--disabled) {
    &.btn--round {
      svg {
        opacity: 1;
      }

      &:active,
      &:hover {
        svg {
          opacity: 1;

          @include mobile {
            opacity: 0.6;
          }
        }
      }

      &:active {
        @include mobile {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
