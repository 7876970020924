import Vue from 'vue'
import axios from 'axios'

export default {
  get (id, isDemo) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/product/'

    let params = {
      id
    }

    if (isDemo) {
      params['isDemo'] = 1
    }

    return axios.get(ACTION_URL, {
      params
    })
      .then((response) => {
        return response.data
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('product | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 404) {
            throw new Error('product_not_found')
          } else {
            throw new Error('product_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('product', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
