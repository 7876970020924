<template>
  <default-modal disableClose class="modal-access" @close="$emit('close')">
    <template #title>
      <span v-html="title"></span>
    </template>
    <default-btn @click="$emit('close')" grey small>
      {{ buttonCancel }}
    </default-btn>
    <default-btn @click="onClick()" small>
      {{ buttonDelete }}
    </default-btn>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'

export default Vue.extend({
  name: 'ModalAccess',
  components: { DefaultModal, DefaultBtn },
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    buttonCancel: {
      type: String,
      default: ''
    },
    buttonDelete: {
      type: String,
      default: ''
    },
    store: {
      type: Object
    }
  },
  computed: {
    product() {
      if (typeof this.store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.store.state.main.product
      }
    },
  },
  methods: {
    onClick() {
      this.sendYandexEcommerceRemove(this.id)
      this.store.dispatch('cart/removePosition', this.id)
      this.$emit('close')
    },
    sendYandexEcommerceRemove(id) {
      // Giftery Data Layer for E-commerce
      if (window.dataLayer) {
        const position = this.store.getters['cart/positions'].find(position => position.id === id)
        const title = this.product ? this.product.title : ''

        window.dataLayer.push({
          ecommerce: {
            currencyCode: this.store.state.region.currencyCode,
            remove: {
              products: [
                {
                  id: position.productId,
                  name: title,
                  category: 'Виджет',
                  quantity: position.quantity,
                }
              ]
            }
          },
        })
      }
    },
  }
});
</script>

<style lang="scss" scoped>
</style>
