<template>
  <div
    v-if="!$store.state.main.showPreload && $store.state.main.productId"
    class="footer"
    :class="{
      'footer__1-column': ($store.state.main.options.showCopyrightLogo === false) && ($store.state.main.options.showCopyright === false) && ($store.state.main.options.showFooterContacts === false),
    }">
    <div class="footer__row">
      <div class="footer-giftery-info">
        <div class="footer-giftery-info__logo" v-if="!($store.state.main.options.showCopyrightLogo === false)">
          <template v-if="!['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)">
            <div>
              <LogoGifteryEs />
            </div>
          </template>
          <template v-else>
            <div>
              <LogoGiftery />
            </div>
            <div v-if="product.region === 'by'">
              <div class="footer-giftery-info__logo-separator"></div>
            </div>
            <div>
              <LogoGifteryBelarus v-if="product.region === 'by'" />
            </div>
          </template>
        </div>
        <div class="footer-giftery-info__copyright" v-if="!($store.state.main.options.showCopyright === false)">
          <div>©{{ (new Date()).getFullYear() }} Giftery.</div>
          <div>{{ $t('app.copyright') }}</div>
        </div>
      </div>
    </div>

    <div class="footer__row">
      <div class="footer-list">
        <div class="footer-list__title">{{ $t('app.info') }}</div>
        <div class="footer-list__items">
          <div class="footer-list__item">
            <span v-if="!($store.state.main.options.showHelp === false)" @click="goTo('Help')">
              {{ $t('app.how_it_works') }}
            </span>
          </div>
          <slot name="conditionsLink" v-if="!($store.state.main.options.showConditions === false)">
            <div class="footer-list__item">
              <span @click="goTo('Conditions')">
                {{ $t('app.terms_and_coditions') }}
              </span>
            </div>
          </slot>
          <slot name="offerLink" v-if="!($store.state.main.options.showOfferLink === false)">
            <div class="footer-list__item" @click="goTo('Offer')">
              <span @click="goTo('Offer')">
                {{ $t('app.agreement') }}
              </span>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <template v-if="!($store.state.main.options.showFooterContacts === false)">
      <div class="footer__row" v-if="getLocaleTitle() || getLocalePhone() || getLocaleEmail() || getLocaleTime()">
        <div class="footer-list">
          <div class="footer-list__title">
            {{ getLocaleTitle() }}
          </div>
          <div class="footer-list__items">
            <div class="footer-list__item" v-if="!($store.state.main.options.showCopyrightPhone === false)">
              <a :href="getLocalePhoneLink()">
                <span v-html="getLocalePhone()"></span>
              </a>
            </div>
            <div class="footer-list__item" v-if="getLocaleEmail()">
              <a :href="getLocaleEmailLink()">
                <span v-html="getLocaleEmail()"></span>
              </a>
            </div>
            <div class="footer-list__item" v-if="getLocaleTime()">
              <span v-html="getLocaleTime()"></span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LogoGiftery from './icons/LogoGiftery'
import LogoGifteryEs from './icons/LogoGifteryEs'
import LogoGifteryBelarus from './icons/LogoGifteryBelarus'

export default {
  name: 'Footer',
  components: {
    LogoGiftery,
    LogoGifteryEs,
    LogoGifteryBelarus
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    currentLocale() {
      return this.$store.state.main.locale
    },
    macroregion() {
      return this.$store.state.region.macroregion
    }
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    region() {
      return this.macroregion
    },
    getLocaleTitle() {
      return this.$t(`app.contacts.${this.region()}.title`, this.currentLocale)
    },
    getLocaleTime() {
      return this.$t(`app.contacts.${this.region()}.time`, this.currentLocale)
    },
    getLocaleEmail() {
      return this.$t(`app.contacts.${this.region()}.email`, this.currentLocale)
    },
    getLocaleEmailLink() {
      return 'mailto:' + this.$t(`app.contacts.${this.region()}.email`, this.currentLocale)
    },
    getLocalePhone() {
      return this.$t(`app.contacts.${this.region()}.phone`, this.currentLocale)
    },
    getLocalePhoneLink() {
      const phone = this.$t(`app.contacts.${this.region()}.phone`, this.currentLocale)

      return 'tel:' + phone.replace(/ |\(|\)/g, '')
    },
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #ffffff;
  font-size: rem(13);
  line-height: 120%;
  color: #666666;
  display: grid;
  grid-template-columns: rem(300) 1fr 1fr;
  grid-gap: rem(30);
  padding: rem(36) rem(40);
  box-shadow: 0 rem(6) rem(16) rgba(110, 120, 130, 0.1);

  @include mobile {
    height: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    padding: rem(40) rem(10);
  }

  &__row {
    @include mobile {
      box-sizing: border-box;
      padding: 0 0 rem(10);
    }

    &:nth-child(1) {
      @include mobile {
        order: 3;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: rem(10) 0 0;
        margin-bottom: 0;
      }
    }

    &:nth-child(2) {
      @include mobile {
        order: 1;
      }
    }

    &:nth-child(3) {
      @include mobile {
        order: 2;
      }
    }

    &:last-child {
      padding-left: rem(10);

      @include mobile {
        padding-left: 0;
      }
    }
  }

  &__1-column {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  &__2-column {
    grid-template-columns: 1fr 1fr;
  }
}

.footer-giftery-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    flex-direction: row;
    align-items: center;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(20);

    @include mobile {
      display: flex;
      align-items: center;
      flex-direction: column;
      display: none;
      margin-bottom: 0;
    }

    &>div {
      display: flex;
      align-items: center;
    }

    &-separator {
      width: rem(80);
      height: rem(2);
      background-color: #fb7720;
      display: flex;
      margin: rem(6) 0;
    }
  }

  &__copyright {
    @include mobile {
      font-size: rem(12);
      line-height: 160%;
      text-align: left;
    }
  }

  &::v-deep {
    svg {
      width: rem(80);
      height: rem(30);
    }
  }
}

.footer-list {
  &__title {
    min-height: rem(23);
    font-weight: 600;
    font-size: rem(16);
    color: #0E1E0E;
    margin-bottom: rem(25);

    @include mobile {
      min-height: rem(17);
      font-size: rem(12);
      color: #6E7882;
      margin-bottom: rem(10);
    }
  }

  &__item {
    color: #6E7882;
    margin-bottom: rem(10);

    @include mobile {
      font-size: rem(15);
      color: #0B132A;
      display: inline-flex;
      margin-right: rem(24);
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    a {
      color: #6E7882;
      text-decoration: none;

      &:hover {
        color: #6E7882;
      }
    }

    span {
      color: #6E7882;
      text-decoration: none;

      &:hover {
        color: #6E7882;
        cursor: pointer;
      }
    }
  }
}



.widget-footer {
  height: rem(176);
  background-color: #ffffff;
  box-shadow: 0 rem(6) rem(16) rgba(110, 120, 130, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0 rem(30);

  @include mobile {
    display: none;
  }

  &__phone {
    padding-top: rem(4);
    padding-left: rem(15);

    a {
      font-size: rem(12);
      font-weight: 600;
      line-height: 1;
      color: #6E7882;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #0B132A;
      }
    }
  }

  &__giftery-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
