<template>
  <div class="greeting">
    <label class="greeting__label" for="greeting">
      {{ $t('form.greeting.placeholder') }}
    </label>
    <textarea-autosize class="greeting__field"
                       :class="{ 'greeting__field--big': isBig }"
                       ref="myTextarea"
                       id="greeting"
                       v-model="greeting"
                       :min-height="28"
                       :max-height="400"
                       maxlength="400"
                       rows="1"
    />
    <div class="greeting__field-desc">
      <div class="greeting__chars-counter">{{ greeting === null ? 0 : greeting.length }}/400</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isBig: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    product () {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    greeting: {
      set (greeting) {
        this.$store.commit('card/setGreeting', greeting)
      },
      get () {
        return this.$store.state.card.current.greeting
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.greeting {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    font-size: rem(13);
    color: #666666;
    margin-bottom: rem(5);
  }

  &__field {
    width: 100%;
    background-color: transparent;
    font-size: rem(16);
    line-height: 120%;
    overflow: hidden;
    border: none;
    resize: none;
    appearance: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border-bottom: rem(1) solid rgba(110, 120, 130, 0.2);
    padding-bottom: rem(8);
    transition: .2s;

    &:focus {
      outline: none;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &::placeholder {
      font-size: rem(13);
      color: #666666;
    }

    &-desc {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: rem(6);
    }

    &--big {
      height: rem(60);
    }
  }

  &__chars-counter {
    margin-left: auto;
    font-size: rem(13);
    color: #6E7882;
  }

  &__warning {
    font-size: rem(12);
    color: #666;
  }
}

.widget-tooltip {
  position: relative;
  appearance: none;
  margin-left: rem(6);
  margin-bottom: rem(2);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    max-width: rem(230);
    min-width: rem(230);
    background: #ffffff;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0B132A;
    text-align: left;
    position: absolute;
    left: rem(-68);
    bottom: rem(35);
    opacity: 0;
    border-radius: rem(10);
    padding: rem(14) rem(20);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;
    @include mobile {
      top: rem(-30);
      right: rem(-85);
      bottom: auto;
      left: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      right: rem(26);
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #ffffff;
      transform: translate(-50%, 0);
      @include mobile {
        top: rem(32);
        right: rem(-19);
        border-top-color: transparent;
        border-left-color: #ffffff;
      }
    }
  }

  &:hover {
    cursor: help;

    .widget-tooltip__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
</style>
