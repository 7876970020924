<template>
  <div class="page pay-success">
    <div class="page__header">
      <div class="page__title">
        <span>{{ $t('pay.fail.title') }}</span>
        <span class="page__header-icon">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" transform="rotate(-180 9 9)" fill="url(#paint0_linear_414_3208)"/>
            <circle cx="9" cy="13" r="1" transform="rotate(-180 9 13)" fill="white"/>
            <path d="M9 4L9 10" stroke="white" stroke-width="2"/>
            <defs>
              <linearGradient id="paint0_linear_414_3208" x1="-1.3258" y1="1.9966" x2="22.0606" y2="14.2176" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF944D"/>
                <stop offset="0.480912" stop-color="#FA6400"/>
                <stop offset="1" stop-color="#FF944D"/>
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content-wrapper">
        <div class="page__content-text" v-html="$t('pay.fail.text')"></div>
        <div class="page__content-btns" v-if="!isProcessing">
          <default-btn class="widget-action--payment-fail-back-to-cart" :to="'cart'" grey big>{{
              $t('form.buttons.back_to_cart')
          }}</default-btn>
          <default-btn class="widget-action--payment-fail-repeat-pay" @click.prevent="pay">{{
              $t('form.buttons.repeat_pay')
          }}</default-btn>
        </div>
      </div>
    </div>

    <div class="basket-pay" v-if="isProcessing">
      <div class="basket-pay__loading-container">
        <div class="basket-pay__loading-container-text" v-html="$t('cart.please_wait_payment_processing')"></div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from 'vue'
import DefaultBtn from '../components/default/DefaultBtn'
import ModalPayFail from '../components/modals/ModalPayFail'
import { Position } from 'app/models'
import { regionCloudPaymentsPublicId } from 'app/modules/constants'
import order from 'app/api/order'
import { availableErrors } from 'app/modules/app-error'
import { getSuccessUrl, getFailUrl } from 'app/modules/utils'

export default {
  components: { DefaultBtn },
  data() {
    return {
      isProcessing: false,
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (window.gtag) {
        window.gtag('event', 'thxpagefailga');
        console.log("(gtag) Спасибо - Результат оплаты | Ошибка оплаты");
      }

      if (window.yaCounter12446476) {
        window.ym(12446476, 'reachGoal', 'thxpagefail');
        console.log("(ym) Спасибо - Результат оплаты | Ошибка оплаты");
      }

      window.history.pushState('', '', window.location.href.replace('&fail=1', ''))
    })
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    pay() {
      if (this.isProcessing) return

      this.isProcessing = true
      let stat = {}
      let params = {}

      if (typeof this.$store.state.main.code !== 'undefined') {
        stat.widgetConfigCode = this.$store.state.main.code
      }

      // Giftery Data Layer for E-commerce
      window.gifteryDataLayer = {
        event: 'pay',
        data: {
          productId: this.product.id,
          productTitle: this.product.title,
          positions: this.$store.getters['cart/positions'].map(position => {
            return {
              face: position.face,
              quantity: position.quantity,
              type: position.type
            }
          }),
          sum: this.$store.getters['cart/sum']
        }
      }

      if (typeof window.roistat !== 'undefined') {
        stat.roistatVisit = window.roistat.getVisit()
      }

      if (window.yaCounter2199583) {
        window.ym(2199583, 'reachGoal', 'sert_purchase')
      }

      try {
        if (this.product.id === 11501) {
          // console.log('sending litres event')
          /*eslint-disable no-undef*/
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'sert_purchase')
          }
          /*eslint-enable no-undef*/
        }

      } catch (e) {
        console.log(e)
      }

      if (this.$store.state.cart.promoCode && this.$store.state.cart.promoCode.promocode) {
        params['promocode'] = this.$store.state.cart.promoCode.promocode
      }

      if (typeof this.$store.state.main.options.psOverride === 'string' && this.$store.state.main.options.psOverride !== '') {
        params['psOverride'] = this.$store.state.main.options.psOverride
      }

      params['demo'] = this.$store.state.main.isDemo

      if (Object.values(stat).length > 0) {
        params['stat'] = stat
      }

      params['successUrl'] = getSuccessUrl(window.location.href)
      params['failUrl'] = getFailUrl(window.location.href)

      order.make(this.$store.getters['cart/positions'], params)
        .then((response) => {
          this.$store.dispatch('pay/setPayment', response.data.payment)

          // this.paymentProcessingComponent = this.$store.getters['pay/payment'].ps

          if (['cloudpayments', 'cloudpayments-kz'].includes(response.data.payment.ps)) {
            let widget = new window.cp.CloudPayments()
            widget.pay('charge',
              {
                publicId: process.env[regionCloudPaymentsPublicId[this.$store.state.main.product.region]], //id из личного кабинета
                description: 'Оплата подарочных карт', //назначение
                amount: parseInt(response.data.payment.amount), //сумма
                currency: this.$store.state.region.currencyCode, //валюта
                // accountId: 'user@example.com', //идентификатор плательщика (необязательно)
                invoiceId: response.data.payment.gateResponse.orderId, //номер заказа  (необязательно)
                skin: 'mini', //дизайн виджета (необязательно)
                // data: {
                //   myProp: 'myProp value'
                // }
              },
              {
                onComplete: (paymentResult) => {
                  if (paymentResult.success === true) {
                    // Reach goal
                  }
                },
                onSuccess: () => {
                  this.$router.push({ name: 'PaySuccess' })
                },
                onFail: (reason) => {
                  if (reason === 'User has cancelled') {
                    this.isProcessing = false
                    this.isPayVisible = false
                    this.isOrderMade = false
                  } else {
                    this.$router.push({ name: 'PayFail' })
                  }
                }
              }
            )
          } else {
            window.location.href = response.data.payment.gateResponse.formUrl
          }

          this.$store.dispatch('card/setAll', new Position())
          this.$store.dispatch('card/setProductId', this.product.id)
          this.$store.dispatch('card/setCardImage', this.product.images[0])

          this.isProcessing = false
          this.isOrderMade = true

          if (this.$store.state.main.isDemo) {
            this.isDemoPayVisible = true
            this.$store.dispatch('cart/clear')
            this.isMessageVisible = true
            this.messageText = this.$t(`cart.test_gift_card_is_sent.${ this.$store.state.main.product.kind }`)
          } else {
            this.isPayVisible = true
          }
        }).catch((error) => {
          this.isProcessing = false

          let titleModal = ''
          let textModal = ''
          if (availableErrors.includes(error.message)) {
            titleModal = this.$t(`errors.${error.message}.heading`)
            textModal = this.$t(`errors.${error.message}.text`)
          } else {
            titleModal = this.$t(`errors.default.heading`)
            textModal = this.$t(`errors.default.text`)
          }

          this.$modal.show(ModalPayFail, {
            store: this.$store,
            title: titleModal,
            text: textModal,
            buttonOk: this.$t('modals.buttons.ok')
          })

          Vue.$logger.debug('Order | order.make | ошибка: ', error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  &__header {
    height: rem(50);
    background-color: #E9EBEC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(20) rem(20) 0 0;
    padding: rem(15) rem(20);

    @include mobile {
      padding: rem(10);
    }

    &-icon {
      width: rem(18);
      height: rem(18);
      display: inline-flex;
      position: relative;
      margin-left: rem(10);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 120%;
    color: #0E1E0E;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: rem(15);
    }
  }

  &__content {
    background: #FFFFFF;
    font-size: rem(13);
    line-height: 160%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 rem(20) rem(20);
    padding: rem(30) rem(30) rem(20);
    margin-bottom: rem(30);

    @include mobile {
      font-size: rem(12);
      flex-direction: column;
      padding: rem(30) rem(10);
    }

    &-text {
      color: #6E7882;
      margin-bottom: rem(30);
      @include mobile {
        font-size: rem(12);
        line-height: 160%;
        color: #6E7882;
      }
    }

    &-text-bold {
      font-weight: 600;
      color: #0B132A;
      margin: 0 rem(4);
      text-decoration: underline;
    }

    &-btns::v-deep {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        flex-direction: column-reverse;
      }

      .btn {
        margin-right: rem(10);
        padding: 0 rem(24);

        @include mobile {
          width: 100%;
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;

          @include mobile {
            margin-bottom: rem(10);
          }
        }
      }
    }
  }
}

.basket-pay {
  margin-top: rem(30);
  padding: rem(30) 0;

  &__loading-container {
    &-text {
      color: #FA6400;
      text-align: center;
    }
  }
}
</style>
