var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'router-link' : _vm.href ? 'a' : 'button',{tag:"button",staticClass:"btn",class:{
  'btn--disabled': _vm.disabled,
  'btn--link': _vm.to,
  'btn--white': _vm.white,
  'btn--grey': _vm.grey,
  'btn--round': _vm.round,
  'btn--link': _vm.isLink,
  'btn--link-header': _vm.isLinkHeader,
  'btn--small': _vm.small,
  'btn--big': _vm.big,
  'btn--form': _vm.form,
  'btn--full-width': _vm.fullWidth,
},attrs:{"disabled":_vm.disabled,"href":_vm.href,"target":_vm.href ? _vm.target || '_blank' : undefined,"to":!_vm.disabled ? _vm.to || undefined : ''},on:{"click":function($event){!_vm.disabled && _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }