<template>
  <div class="check-balance">

    <div class="check-balance__form" v-if="!isShowResult">
      <div class="check-balance__inputs">
        <div
          class="widget-input"
          :class="{ 'widget-input--error': $v.code.$error }"
        >
          <div class='widget-input__wrapper'>
            <label>{{ $t('balance.form.code') }}*</label>
            <InputFacade
              v-model.lazy="code"
              masked
              :mask="mask"
              name="code"
              type="text"
              :placeholder="errorText"
              @input="delayTouch($v.code)"
            />
          </div>
          <div class="widget-input__error" v-if="errorText && code">
            {{ errorText }}
          </div>
        </div>
      </div>
      <div class="check-balance__actions">
        <default-btn @click="onClick()">
          {{ $t('balance.form.button') }}
        </default-btn>
      </div>
    </div>

    <div class="check-balance__response" v-if="isShowResult">
      <div class="check-balance__info">
        {{ $t('balance.result.card') }} {{ code }}<br> {{ product.title }}
      </div>
      <div class="check-balance__image">
        <img :src="data.main_image.medium" alt="">
      </div>
      <div class="check-balance-field">
        <div class="check-balance-field__title">{{ $t('balance.result.balance') }}</div>
        <div class="check-balance-field__value">
          {{ data.balance }}&nbsp;{{ $store.state.region.currency }}
        </div>
      </div>
      <div class="check-balance-field">
        <div class="check-balance-field__title">{{ $t('balance.result.expire_date') }}</div>
        <div class="check-balance-field__value">
          {{ getAddedDate(data.added) }}{{ formatDate(data.expire_date) }}
        </div>
      </div>
      <div class="check-balance-field" v-if="data.redeem_info.length > 0">
        <div class="check-balance-field__title">{{ $t('balance.result.redeem_info') }}</div>
        <div class="check-balance-field__value">
          <div class="check-balance-field-list">
            <div class="check-balance-field-list__item"
              v-for="(item) in data.redeem_info"
              :key="item.date"
            >
              <div class="check-balance-field-list__value">
                <div class="check-balance-field-list__title">{{ item.title }}</div>
                <div class="check-balance-field-list__date">{{ formatDate(item.date) }}</div>
              </div>
              <div class="check-balance-field-list__sum">
                -{{ item.face }}&nbsp;{{ $store.state.region.currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="check-balance__actions">
        <default-btn @click="$emit('close-modal')">
          {{ $t('balance.result.button') }}
        </default-btn>
      </div>
    </div>
  </div>
</template>

<script>
import balance from 'app/api/balance'
import { InputFacade } from 'vue-input-facade'
import { required, minLength } from 'vuelidate/lib/validators'
import DefaultBtn from './default/DefaultBtn'

const touchMap = new WeakMap()



export default {
  name: 'CheckBalance',
  components: { InputFacade, DefaultBtn },
  data() {
    return {
      code: '',
      lenghtCode: 16,
      mask: '#####-###-###-###',
      data: null,
      errorText: '',
      isShowResult: false,
    }
  },
  mounted() {
    // TODO: костыль
    /* if ([20866].includes(this.product.id)) {
      this.lenghtCode = 16
      this.mask = '#####-###-###-##'
    } */
    // this.mask = '#####-###-###-##'
  },
  validations() {
    return {
      code: {
        required,
        minLength: minLength(this.lenghtCode),
      }
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.$store.state.main.product
      }
    },
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      this.errorText = ''

      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }

      touchMap.set($v, setTimeout(() => {
        if (!this.validate()) {
          this.setErrorText()
        } else {
          this.errorText = ''
        }
      }, 1500))
    },
    onClick() {
      if (this.validate()) {
        this.checkBalance()
      } else {
        this.setErrorText()
      }
    },
    setErrorText() {
      if (!this.$v.code.minLength && this.code.length > 0) {
        this.errorText = this.$t('balance.errors.min_length', {value: this.lenghtCode - 3})
      } else if (this.$v.code.required || this.code === '') {
        this.errorText = this.$t('balance.errors.required')
      }
    },
    validate() {
      this.$v.code.$touch()

      return !this.$v.code.$invalid
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    getAddedDate(date) {
      if (date) {
        return this.formatDate(date) + ' - '
      } else {
        return ''
      }
    },
    async checkBalance() {
      const data = await balance.check(
        this.product.supplier_id,
        this.code,
      )

      this.data = data

      if (data && (data.code && data.code === 'active')) {
        this.isShowResult = true
      }

      if (data && (data.code && data.code === 'redeemed')) {
        this.errorText = this.$t('balance.errors.redeemed')
      }

      if (data && (data.code && data.code === 'expired')) {
        this.errorText = this.$t('balance.errors.expired')
      }

      if (data && (data.code && data.code === 'not_found')) {
        this.errorText = this.$t('balance.errors.not_found')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.check-balance {
  @include mobile {
    min-width: rem(300);
  }

  &__form {
    padding-top: rem(20);
  }

  &__response {
    padding-top: rem(5);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(40);
  }

  &__info {
    color: #6E7882;
    text-align: center;
    margin-bottom: rem(40);
  }

  &__image {
    width: rem(300);
    height: rem(136);
    border-radius: rem(18);
    margin-bottom: rem(20);

    img {
      width: 100%;
      border-radius: rem(18);
      border: rem(1) solid #E9EBEC;
    }
  }
}

.check-balance-field {
  margin-bottom: rem(20);

  &__title {
    font-size: rem(13);
    color: #6E7882;
    margin-bottom: rem(10);
  }

  &__value {
    font-weight: 600;
    color: #0B132A;
    padding-bottom: rem(10);
    border-bottom: rem(1) solid #E9EBEC;
  }
}

.check-balance-field-list {
  padding: rem(10) 0;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: 600;
    color: #0B132A;
    padding-bottom: rem(10);
  }

  &__date {
    font-size: rem(13);
    color: #6E7882;
  }

  &__sum {
    font-weight: 600;
    color: #0B132A;
  }
}

.check-balance::v-deep {
  .modal__header {
    padding: rem(40) rem(20) rem(25);
  }

  .modal__content {
    text-align: left;
  }

  .modal__content-wrapper {
    .btn {
      color: #ffffff;
    }

    & > div {
      flex-grow: 1;
    }
  }

  .widget-input {
    margin-bottom: rem(20);

    label {
        @include mobile {
          margin-bottom: rem(5);
        }
      }

    input {
      font-weight: 500;
      font-size: rem(16);
      line-height: 120%;
      padding-right: 0;
      padding-bottom: rem(8);
      @include mobile {
        font-size: rem(15);
        margin-bottom: rem(8);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__error {
      font-size: rem(13);
      color: #CC0000;
      line-height: 120%;
      margin-top: rem(3);
    }

    &--error {
      input {
        border-bottom-color: #CC0000;

        &::placeholder {
          color: #CC0000 !important;
        }
      }
    }
  }

  .btn {
    width: 100%;
    height: rem(50);
    font-size: rem(16);
    @include mobile {
      height: rem(40);
      font-size: rem(15);
    }
  }
}
</style>
