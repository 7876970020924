<template>
  <div class="slider-htw">
    <Flicking ref="flicking" :options="options" :plugins="plugins" @will-change="willChange">
      <div class="slider-htw__panel">
        <div class="help__block">
          <div class="help__illustration help__illustration--faces">
            <div class="illustration__faces">
              <div class="illustration__face">
                <span>1&nbsp;000&nbsp;{{ $store.state.region.currency }}</span>
              </div>
              <div class="illustration__face">
                <span>3&nbsp;000&nbsp;{{ $store.state.region.currency }}</span>
              </div>
            </div>
          </div>
          <div class="help__text">
            <h3 v-html="$t('help.create.title')"></h3>
            <p v-html="$t('help.create.text')"></p>
          </div>
        </div>
      </div>
      <div class="slider-htw__panel">
        <div class="help__block">
          <div class="help__illustration help__illustration--send">
            <div class="illustration__send">
              <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="60" rx="10" fill="white" />
                <circle cx="24" cy="20" r="2" fill="#B6BBC0" />
                <rect x="34" y="18" width="44" height="4" rx="2" fill="#B6BBC0" />
                <circle cx="24" cy="30" r="2" fill="#B6BBC0" />
                <rect x="34" y="28" width="44" height="4" rx="2" fill="#B6BBC0" />
                <circle cx="24" cy="40" r="2" fill="#B6BBC0" />
                <rect x="34" y="38" width="44" height="4" rx="2" fill="#B6BBC0" />
              </svg>
              <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="60" rx="10" fill="white" />
                <path
                  d="M54.167 29.5C54.4431 29.5 54.667 29.7239 54.667 30C54.667 30.2761 54.4431 30.5 54.167 30.5V29.5ZM42.5003 30.5C42.2242 30.5 42.0003 30.2761 42.0003 30C42.0003 29.7239 42.2242 29.5 42.5003 29.5V30.5ZM54.167 30.5H42.5003V29.5H54.167V30.5Z"
                  fill="#B6BBC0" />
                <path
                  d="M57.9757 29.7856L55.3779 28.227C54.8447 27.907 54.1663 28.2911 54.1663 28.913V31.087C54.1663 31.7089 54.8447 32.093 55.3779 31.773L57.9757 30.2144C58.1376 30.1173 58.1376 29.8827 57.9757 29.7856Z"
                  fill="#B6BBC0" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.1287 23.2957C13.25 24.1744 13.25 25.5886 13.25 28.417V31.5837C13.25 34.4121 13.25 35.8263 14.1287 36.705C15.0074 37.5837 16.4216 37.5837 19.25 37.5837H26.75C29.5784 37.5837 30.9926 37.5837 31.8713 36.705C32.75 35.8263 32.75 34.4121 32.75 31.5837V28.417C32.75 25.5886 32.75 24.1744 31.8713 23.2957C30.9926 22.417 29.5784 22.417 26.75 22.417H19.25C16.4216 22.417 15.0074 22.417 14.1287 23.2957ZM17.0547 25.9183C16.5952 25.6119 15.9743 25.7361 15.6679 26.1956C15.3616 26.6552 15.4858 27.276 15.9453 27.5824L21.8906 31.5459C22.5624 31.9938 23.4376 31.9938 24.1094 31.5459L30.0547 27.5824C30.5142 27.276 30.6384 26.6552 30.3321 26.1956C30.0257 25.7361 29.4048 25.6119 28.9453 25.9183L23 29.8818L17.0547 25.9183Z"
                  fill="#B6BBC0" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M77 39.75C82.3848 39.75 86.75 35.3848 86.75 30C86.75 24.6152 82.3848 20.25 77 20.25C71.6152 20.25 67.25 24.6152 67.25 30C67.25 35.3848 71.6152 39.75 77 39.75ZM82.1016 27.3902C82.4551 26.9659 82.3978 26.3353 81.9735 25.9818C81.5492 25.6282 80.9187 25.6855 80.5651 26.1098L76.5505 30.9274C76.2038 31.3434 76.0089 31.5738 75.8532 31.7153L75.8473 31.7207L75.8409 31.7159C75.6731 31.589 75.4581 31.3772 75.0752 30.9943L73.3738 29.2929C72.9833 28.9024 72.3501 28.9024 71.9596 29.2929C71.569 29.6834 71.569 30.3166 71.9596 30.7071L73.6609 32.4085L73.7018 32.4493L73.7018 32.4494C74.0283 32.776 74.3424 33.0902 74.6346 33.3111C74.962 33.5587 75.3907 33.7915 75.9401 33.7665C76.4895 33.7416 76.8954 33.471 77.199 33.1948C77.47 32.9483 77.7544 32.6069 78.05 32.2521L78.05 32.2521L78.0869 32.2077L82.1016 27.3902Z"
                  fill="#B6BBC0" />
              </svg>



            </div>
          </div>
          <div class="help__text">
            <h3 v-html="$t(`help.send.title.${ $store.state.main.product.kind }`)"></h3>
            <p v-html="$t('help.send.text')"></p>
          </div>
        </div>
      </div>
      <div class="slider-htw__panel">
        <div class="help__block">
          <div class="help__illustration help__illustration--cards">
            <div class="illustration__cards">
              <div class="illustration__card">
                <span>3&nbsp;000&nbsp;{{ $store.state.region.currency }}</span>
              </div>
              <div class="illustration__card">
                <img :src="getImage()" alt="" />
              </div>
            </div>
          </div>
          <div class="help__text">
            <h3 v-html="$t(`help.use.title.${ $store.state.main.product.kind }`)"></h3>
            <p v-if="product.properties.digital_acceptance === 'offline'"
              v-html="$t(`help.use.text_offline.${ $store.state.main.product.kind }`)">

            </p>
            <p v-else v-html="$t(`help.use.text.${ $store.state.main.product.kind }`)"></p>
          </div>
        </div>
      </div>
      <div slot="viewport" class="flicking-pagination"></div>
      <Pagination v-if="false" />
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from '@egjs/vue-flicking'
import { Pagination } from "@egjs/flicking-plugins"
import { Settings } from 'luxon'

import "@egjs/vue-flicking/dist/flicking.css"

let imageIndex = 0

export default {
  components: {
    Flicking,
    Pagination
  },

  data: () => ({
    options: {
      circular: false,
      bound: false,
      horizontal: true,
      adaptive: true,
      renderOnlyVisible: true,
      resizeOnContentsReady: false,
      duration: 600,
      threshold: 0,
      changeOnHold: false,
      moveType: "snap",
      useFractionalSize: true
    },
    plugins: [new Pagination({ type: 'bullet' })],
  }),
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
  },
  methods: {
    getImage() {
      const images = this.product.images

      if (images && images.length >= imageIndex) {
        imageIndex = images.length === imageIndex ? 0 : imageIndex

        return images[imageIndex++]
      }
      return this.$store.getters['card/current'].cardImage
    },
    willChange(e) {
      const heightNewPanel = e.panel.height
      this.$refs.flicking.viewport.setSize({ height: heightNewPanel })
      this.$refs.flicking.viewport.resize()
    },
  },
  mounted() {
    Settings.defaultLocale = this.$store.state.main.locale
  },
}
</script>

<style lang="scss" scoped>
.slider-htw {
  width: rem(300);
  overflow: hidden;
  padding-bottom: rem(16);

  &__panel {
    width: rem(300);
    height: max-content;
    flex-shrink: 0;
    margin-right: rem(10);
  }

  &::v-deep {
    .flicking-viewport {
      overflow: visible;
      border-radius: rem(20);
      transition: height 50ms;
    }

    .flicking-pagination {
      width: 100%;
      font-size: 0;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: rem(-16);
      z-index: 2;

      // bottom: rem(-40);
      .flicking-pagination-scroll {
        width: rem(6);
        height: rem(6);
        background-color: #666666;
        font-size: 0;
      }

      .flicking-pagination-bullet {
        width: rem(6);
        height: rem(6);
        background-color: #666666;
        display: inline-block;
        margin: 0 rem(3);
        cursor: pointer;
        font-size: 0;
        border-radius: 50%;
      }

      .flicking-pagination-bullet-active {
        width: rem(16);
        background: var(--theme-primary-background-component);
        border-radius: rem(100);
      }

      .flicking-pagination-bullets,
      .flicking-pagination-scroll {
        font-size: 0;
      }
    }
  }
}

.help {
  &__block {
    width: 100%;
    margin-right: rem(20);

    @include mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: rem(13);
    }

    &:last-child {
      margin-right: 0;

      @include mobile {
        margin-bottom: 0;
      }
    }
  }

  &__illustration {
    height: rem(140);
    background-color: #E9EBEC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(20) rem(20) 0 0;

    &--faces {
      padding: rem(30) rem(40);
    }

    &--send {
      padding: rem(40) rem(20);
    }

    &--cards {
      padding: rem(30) rem(40);
    }
  }

  &__text {
    background-color: #ffffff;
    text-align: center;
    padding: rem(26) rem(20) rem(30);
    border-radius: 0 0 rem(20) rem(20);

    h3 {
      font-weight: 400;
      font-size: rem(15);
      line-height: 120%;
      color: #0E1E0E;
      margin-top: 0;
      margin-bottom: rem(16);
    }

    p {
      font-size: rem(13);
      line-height: 150%;
      color: #666666;
      margin: 0;
    }
  }
}

.illustration {
  &__faces {
    width: rem(180);
    height: rem(80);
    position: relative;
  }

  &__face {
    width: rem(100);
    height: rem(46);
    font-weight: 700;
    font-size: rem(13);
    line-height: 110%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: rem(6);

    &:nth-child(1) {
      background-color: #ffffff;
      color: #B6BBC0;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      background-color: #B6BBC0;
      color: #ffffff;
      top: rem(34);
      left: rem(80);
    }
  }

  &__send {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      justify-content: center;
    }

    svg {
      &:nth-child(1) {
        @include mobile {
          margin-right: rem(20);
        }
      }
    }
  }

  &__cards {
    width: rem(170);
    height: rem(66);
    position: relative;
  }

  &__card {
    width: rem(100);
    height: rem(46);
    position: relative;

    img {
      width: 100%;
      display: block;
      border-radius: rem(4);
    }

    &:nth-child(1) {
      width: rem(100);
      height: rem(46);
      background: #B6BBC0;
      font-weight: 700;
      font-size: rem(13);
      text-align: center;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: rem(6);
      transform: rotate(-15deg);
    }

    &:nth-child(2) {

      position: absolute;
      top: rem(20);
      left: rem(70);
    }
  }
}
</style>
