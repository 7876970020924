<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="640" height="480" viewBox="0 0 640 480">
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#0039a6" d="M0 160.003h640V480H0z"/>
      <path fill="#d52b1e" d="M0 319.997h640V480H0z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ru'
}
</script>
