<template>
  <div class="modal">
    <div class="modal__header" v-if="$slots.title">
      <slot name="title" />
    </div>
    <div v-if="!disableClose" class="modal_close" @click="close" />
    <div class="modal__content">
      <div class="modal__content-wrapper">
        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "DefaultModal",
  props: {
    disableClose: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden'
  },
  destroyed() {
    document.body.style.overflow = 'auto'
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
});
</script>

<style lang="scss">
.vm--container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include mobile {
    align-items: center;
  }
}

.vm--overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.vm--modal {
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
  position: relative;
  top: rem(90) !important;
  left: auto !important;
  z-index: 9999;
  box-shadow: none !important;

  @include mobile {
    top: auto !important;
  }
}
</style>

<style lang="scss" scoped>
.modal::v-deep {
  .btn {
    margin-right: rem(10);

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal {
  width: 100%;
  height: 100%;
  min-width: rem(340);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: rem(20);

  @include mobile {
    min-width: rem(300);
  }

  &__header {
    width: 100%;
    background-color: #ffffff;
    font-weight: 600;
    font-size: rem(22);
    line-height: 120%;
    color: #0B132A;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(40) rem(20) 0;

    @include mobile {
      text-align: center;
      font-size: rem(15);
      color: #0B132A;
      padding: rem(40) rem(20) 0;
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      text-align: center;
      font-size: rem(12);
      line-height: 120%;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(15) rem(20) rem(40);

      @include mobile {
        padding: rem(20) rem(12) rem(30);
      }

      &::v-deep {
        .btn {
          @include mobile {
            font-weight: 600;
            font-size: rem(15);
          }
        }
      }
    }
  }
}
</style>
