<template>
  <svg width="98" height="30" viewBox="0 0 98 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4433 14.7354C13.5651 14.7354 14.9721 12.5767 13.6151 10.9456C11.0425 7.85335 7.17587 5.88719 2.84741 5.88719C2.71062 5.88719 2.57463 5.88909 2.43942 5.89285C1.01052 5.93265 0 7.19852 0 8.62798V12.3325C0 13.6612 1.0713 14.7354 2.39634 14.7354H11.4433ZM20.7419 15.2647C18.6201 15.2647 17.2132 17.4234 18.5702 19.0545C21.1428 22.1467 25.0094 24.1129 29.3379 24.1129C29.4747 24.1129 29.6107 24.111 29.7459 24.1072C31.1748 24.0674 32.1853 22.8016 32.1853 21.3721V17.6676C32.1853 16.3389 31.114 15.2647 29.7889 15.2647H20.7419Z" fill="url(#paint0_linear_85_171)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5514 10.5631C17.0035 12.6164 18.7312 14.5362 20.6546 13.6375C24.2866 11.9405 27.1711 8.7032 28.2895 4.51232C28.3254 4.3779 28.3591 4.24377 28.3909 4.10993C28.721 2.71736 27.7592 1.40986 26.3773 1.03806L22.8259 0.082608C21.5477 -0.26127 20.237 0.499697 19.8941 1.78477L17.5514 10.5631ZM14.6361 19.4369C15.1841 17.3836 13.4564 15.4638 11.533 16.3625C7.90091 18.0595 5.01647 21.2968 3.89806 25.4877C3.86219 25.6221 3.82841 25.7562 3.79667 25.8901C3.46651 27.2826 4.42831 28.5901 5.81026 28.9619L9.36166 29.9174C10.6398 30.2613 11.9506 29.5003 12.2935 28.2152L14.6361 19.4369Z" fill="url(#paint1_linear_85_171)"/>
  <path d="M45.5771 9.84123H48.1617V19.5893C48.1617 21.173 47.6352 22.3813 46.5822 23.2141C45.5429 24.047 44.2848 24.4634 42.8079 24.4634C41.7002 24.4634 40.7225 24.2517 39.8746 23.8285C39.0405 23.4053 38.3977 22.7841 37.9465 21.9649L40.2028 20.6747C40.7225 21.6304 41.6182 22.1083 42.89 22.1083C43.7241 22.1083 44.3805 21.8898 44.8592 21.4529C45.3378 21.016 45.5771 20.3948 45.5771 19.5893V18.4834C44.7976 19.5211 43.7036 20.0399 42.2951 20.0399C40.8456 20.0399 39.6285 19.5347 38.6439 18.5244C37.673 17.5004 37.1875 16.258 37.1875 14.7972C37.1875 13.35 37.6798 12.1144 38.6644 11.0905C39.649 10.0665 40.8592 9.55452 42.2951 9.55452C43.7036 9.55452 44.7976 10.0733 45.5771 11.1109V9.84123ZM40.6336 16.8246C41.1806 17.3434 41.8712 17.6028 42.7054 17.6028C43.5395 17.6028 44.2233 17.3434 44.7566 16.8246C45.3036 16.2922 45.5771 15.6164 45.5771 14.7972C45.5771 13.9917 45.3036 13.3295 44.7566 12.8107C44.2233 12.2783 43.5395 12.012 42.7054 12.012C41.8712 12.012 41.1806 12.2783 40.6336 12.8107C40.1003 13.3295 39.8336 13.9917 39.8336 14.7972C39.8336 15.6164 40.1003 16.2922 40.6336 16.8246Z" fill="#0E1E0E"/>
  <path d="M51.0963 8.61248C50.6587 8.61248 50.2758 8.45547 49.9476 8.14145C49.633 7.81379 49.4758 7.43151 49.4758 6.99462C49.4758 6.55773 49.633 6.17545 49.9476 5.84778C50.2758 5.52012 50.6587 5.35628 51.0963 5.35628C51.5475 5.35628 51.9304 5.52012 52.245 5.84778C52.5732 6.17545 52.7373 6.55773 52.7373 6.99462C52.7373 7.43151 52.5732 7.81379 52.245 8.14145C51.9304 8.45547 51.5475 8.61248 51.0963 8.61248ZM49.7835 20.0808V9.84123H52.4296V20.0808H49.7835Z" fill="#0E1E0E"/>
  <path d="M59.9193 7.81897C58.4698 7.70975 57.745 8.28999 57.745 9.5597V9.84123H59.9193V12.3806H57.745V20.0808H55.0989V12.3806H53.622V9.84123H55.0989V9.5597C55.0989 8.11251 55.5023 7.01346 56.3092 6.26255C57.116 5.51165 58.3194 5.18398 59.9193 5.27955V7.81897Z" fill="#0E1E0E"/>
  <path d="M67.7243 12.3806H65.4064V16.6403C65.4064 16.9953 65.4953 17.2547 65.6731 17.4185C65.8509 17.5824 66.1107 17.6779 66.4526 17.7052C66.7944 17.7189 67.2184 17.7121 67.7243 17.6848V20.0808C65.9056 20.2856 64.6201 20.115 63.868 19.5688C63.1295 19.0227 62.7603 18.0466 62.7603 16.6403V12.3806H60.9757V9.84123H62.7603V7.77283L65.4064 6.97414V9.84123H67.7243V12.3806Z" fill="#0E1E0E"/>
  <path d="M71.2054 16.0464C71.5609 17.3298 72.525 17.9715 74.0976 17.9715C75.1096 17.9715 75.8754 17.6301 76.395 16.9475L78.5283 18.1763C77.5164 19.6371 76.0258 20.3675 74.0566 20.3675C72.3609 20.3675 71.0002 19.8556 69.9746 18.8316C68.949 17.8076 68.4362 16.5174 68.4362 14.961C68.4362 13.4183 68.9422 12.1349 69.9541 11.1109C70.966 10.0733 72.2652 9.55452 73.8515 9.55452C75.3557 9.55452 76.5933 10.0733 77.5642 11.1109C78.5488 12.1485 79.0411 13.4319 79.0411 14.961C79.0411 15.3023 79.0069 15.6641 78.9386 16.0464H71.2054ZM71.1643 13.9985H76.395C76.2446 13.3022 75.9301 12.7834 75.4514 12.4421C74.9865 12.1008 74.4532 11.9301 73.8515 11.9301C73.1404 11.9301 72.5523 12.1144 72.0874 12.483C71.6224 12.838 71.3148 13.3432 71.1643 13.9985Z" fill="#0E1E0E"/>
  <path d="M82.7568 11.6024C83.0029 10.9471 83.4064 10.4556 83.967 10.1279C84.5414 9.80027 85.1773 9.63643 85.8747 9.63643V12.5854C85.0679 12.4899 84.3431 12.6537 83.7004 13.0769C83.0713 13.5002 82.7568 14.2033 82.7568 15.1863V20.0808H80.1107V9.84123H82.7568V11.6024Z" fill="#0E1E0E"/>
  <path d="M94.3563 9.84123H97.187L93.4537 20.0808C92.9204 21.5553 92.2093 22.6202 91.3204 23.2756C90.4452 23.9446 89.3512 24.2449 88.0384 24.1767V21.7192C88.7495 21.7328 89.3102 21.5826 89.7205 21.2686C90.1444 20.9546 90.4794 20.4494 90.7256 19.7532L86.5205 9.84123H89.4128L92.0794 16.7222L94.3563 9.84123Z" fill="#0E1E0E"/>
  <defs>
    <linearGradient id="paint0_linear_85_171" x1="-9.27334" y1="22.3132" x2="26.7173" y2="-7.2526" gradientUnits="userSpaceOnUse">
      <stop stop-color="#90EA71"/>
      <stop offset="0.515625" stop-color="#59D52D"/>
      <stop offset="1" stop-color="#90EA71"/>
    </linearGradient>
    <linearGradient id="paint1_linear_85_171" x1="1.91033" y1="3.32768" x2="36.4074" y2="18.1848" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FFDF39"/>
      <stop offset="0.480912" stop-color="#F7C516"/>
      <stop offset="1" stop-color="#FFDF39"/>
    </linearGradient>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoGifteryEs'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(100);
  height: rem(30);
}
</style>
