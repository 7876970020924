import Vue from 'vue'
import axios from 'axios'

export default {
  upload (formData) {
    Vue.$logger.info('upload | Uploading cover', JSON.parse(JSON.stringify(formData)))

    // process.env.VUE_APP_WIDGET_API_URL
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/cover/upload'

    return axios.post(ACTION_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response.data
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('upload | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('upload_error')
          } else if (error.response.status === 404) {
            throw new Error('upload_error')
          } else {
            throw new Error('upload_error')
          }
        } else if (error.request) {
          Vue.$logger.error('order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
