<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00001C14.6667 4.31811 11.6819 1.33334 8.00004 1.33334C4.31814 1.33334 1.33337 4.31811 1.33337 8.00001C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z" stroke="#FA6400" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 6L6 10" stroke="#FA6400" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 6L10 10" stroke="#FA6400" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconError'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(16);
  height: rem(16);
}
</style>
