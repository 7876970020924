<template>
  <div class="widget-notification">
    <div class="widget-notification__wrapper">
      <div class="widget-notification__icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0003 6.66699V10.0003M10.0003 13.3337H10.0087M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z" stroke="#0B132A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="widget-notification__text">
        <div class="widget-notification__title">
          Возможны перебои в работе сервиса с 00:00 до 05:00 по МСК.
        </div>
        <div class="widget-notification__desc">
          Приносим извинения за временные неудобства.
        </div>
      </div>
      <div class="widget-notification__close" @click="close">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 1L1 11M1 1L11 11" stroke="#0B132A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
/* import { Settings } from 'luxon'
import { timeZone } from 'app/modules/constants' */

export default {
  name: 'NotificationWorks',
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.widget-notification {
  background-color: #fff;
  border-radius: rem(12);
  padding: rem(12);

  @include mobile {
    padding: rem(12) rem(10);
  }

  &__wrapper {
    display: flex;
  }

  &__icon {
    width: rem(40);
    height: rem(40);
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(10);

    @include mobile {
      display: none;
    }
  }

  &__text {
    line-height: 120%;
    flex-grow: 1;
    padding: 0 rem(16);

    @include mobile {
      padding: 0;
    }
  }

  &__title {
    font-size: rem(16);
    font-weight: 600;
    color: #0B132A;

    @include mobile {
      font-size: rem(15);
    }
  }

  &__desc {
    font-size: rem(13);
    color: #6E7882;
    margin-top: rem(2);

    @include mobile {
      font-size: rem(12);
    }
  }

  &__close {
    width: rem(40);
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
