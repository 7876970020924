import Vue from 'vue'
import axios from 'axios'

export default {
  check (supplier_id, code) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/check-certificate'

    return axios.post(ACTION_URL, {
      supplier_id: supplier_id,
      code: code
    })
      .then((response) => {
        if (typeof response.data !== 'undefined') {
          return response.data
        } else {
          throw new Error(response.data.code)
        }
      }).catch((error) => {
        if (error.message === 'balance_error') {
          throw new Error(error.message)
        }
        if (error.response.status >= 500) {
          throw new Error('server_error')
        } else if (error.request) {
          Vue.$logger.error('balance', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
