<template>
  <div class="quantity-selector" :class="{ 'quantity-selector--cart': quantityType !== 'quantity' }">
    <default-btn v-if="quantityType === 'quantity' || quantityType === 'cartQuantity'"
      @click.prevent="quantityType === 'cartQuantity' ? decreaseSelected() : decrease()" round
      :disabled="quantityType !== 'quantity' ? cartQuantity === 1 : quantity === 1">
      <IconMinus />
    </default-btn>
    <input class="quantity-selector__field" v-if="quantityType === 'quantity'" v-model="quantity"
      @blur="checkQuantity()" maxlength="2">
    <input class="quantity-selector__field quantity-selector__field--cart" v-else v-model="cartQuantity"
      @blur="checkQuantity()" maxlength="2">
    <default-btn v-if="quantityType === 'quantity' || quantityType === 'cartQuantity'"
      @click.prevent="quantityType === 'cartQuantity' ? increaseSelected() : increase()" round :disabled="quantityType !== 'quantity' ?
      cartQuantity === $store.state.main.maxCartCount || $store.getters['cart/quantity'] >= $store.state.main.maxCartCount :
      $store.getters['cart/quantity'] + quantity >= $store.state.main.maxCartCount">
      <IconPlus />
    </default-btn>
  </div>
</template>

<script>
import { findIndex } from 'lodash'
import DefaultBtn from './default/DefaultBtn'
import IconMinus from './icons/IconMinus'
import IconPlus from './icons/IconPlus'

export default {
  components: { DefaultBtn, IconMinus, IconPlus },
  data() {
    return {}
  },
  props: {
    quantityType: {
      type: String,
      default: 'quantity'
    },
    id: {
      type: String
    }
  },
  computed: {
    product() {
      return this.$store.state.main.product ? this.$store.state.main.product : {}
    },
    quantity: {
      set(quantity) {
        this.$store.commit('card/setQuantity', quantity)
      },
      get() {
        return this.$store.state.card.current.quantity
      }
    },
    cartQuantity: {
      set(quantity) {
        this.$store.commit('cart/setQuantity', { quantity, id: this.id })
      },
      get() {
        const index = findIndex(this.$store.getters['cart/positions'], { id: this.id })

        return this.$store.state.cart.positions[index].quantity
      }
    }
  },
  methods: {
    decrease() {
      if (this[this.quantityType] > 1) {
        this[this.quantityType]--
      } else {
        this[this.quantityType] = 1
      }
    },
    increase() {
      if (this[this.quantityType] + 1 <= this.$store.state.main.maxCartCount) {
        this[this.quantityType]++
      } else {
        this[this.quantityType] = this.$store.state.main.maxCartCount
      }
    },
    decreaseSelected() {
      if (this.cartQuantity > 1) {
        this.cartQuantity--
        this.sendYandexEcommerce('remove')
      } else {
        this.cartQuantity = 1
      }
    },
    increaseSelected() {
      if (this.cartQuantity + 1 <= this.$store.state.main.maxCartCount) {
        this.cartQuantity++
        this.sendYandexEcommerce('add')
      } else {
        this.cartQuantity = this.$store.state.main.maxCartCount
      }
    },
    checkQuantity() {
      if (parseInt(this[this.quantityType]) > this.$store.state.main.maxCartCount) {
        this[this.quantityType] = this.$store.state.main.maxCartCount
      } else if (parseInt(this[this.quantityType]) < 1 || isNaN(parseInt(this[this.quantityType]))) {
        this[this.quantityType] = 1
      }
    },
    sendYandexEcommerce(type) {
      const index = findIndex(this.$store.getters['cart/positions'], { id: this.id })
      const position = this.$store.state.cart.positions[index]
      const title = this.product.title ? this.product.title : ''

      if (window.dataLayer) {
        if (type === 'add') {
          window.dataLayer.push({
            ecommerce: {
              currencyCode: this.$store.state.region.currencyCode,
              add: {
                products: [
                  {
                    id: position.productId,
                    name: title,
                    price: position.face,
                    category: 'Виджет',
                    quantity: 1,
                    variant: position.type === 'friend' ? 'Отправить в подарок' : 'Отправить себе'
                  }
                ]
              },
            }
          })
        } else if (type === 'remove') {
          window.dataLayer.push({
            ecommerce: {
              currencyCode: this.$store.state.region.currencyCode,
              remove: {
                products: [
                  {
                    id: position.productId,
                    name: title,
                    category: 'Виджет',
                    quantity: 1,
                  }
                ]
              }
            },
          })
        }
      }
    }
  },
  mounted() {
    if (this[this.quantityType] === null) {
      this[this.quantityType] = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.quantity-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__field {
    width: rem(45);
    height: rem(30);
    background-color: transparent;
    font-weight: 600;
    font-size: rem(15);
    line-height: 1;
    text-align: center;
    border: none;
    transition: background-color .2s;

    @include mobile {
      width: rem(40);
      font-weight: 600;
      font-size: rem(16);
    }

    &:focus {
      outline: none;
    }
  }
}

.quantity-selector::v-deep {
  .btn {
    &--disabled {
      svg {
        opacity: 0.6;
      }
    }
  }
}
</style>
