<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 1.75065C4.10018 1.75065 1.74967 4.10116 1.74967 7.00065C1.74967 9.90015 4.10018 12.2507 6.99967 12.2507C9.89917 12.2507 12.2497 9.90015 12.2497 7.00065C12.2497 4.10116 9.89917 1.75065 6.99967 1.75065ZM0.583008 7.00065C0.583008 3.45682 3.45585 0.583984 6.99967 0.583984C10.5435 0.583984 13.4163 3.45682 13.4163 7.00065C13.4163 10.5445 10.5435 13.4173 6.99967 13.4173C3.45585 13.4173 0.583008 10.5445 0.583008 7.00065Z" fill="#6E7882"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckboxAround'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(14);
  height: rem(14);
}
</style>
