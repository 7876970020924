<template>
  <default-modal disableClose class="modal-access" @close="$emit('close')">
    <template #title>
      {{ title }}
    </template>
    <default-btn @click="$emit('close')" grey small>
      {{ buttonCancel }}
    </default-btn>
    <default-btn @click="onClick()" small>
      {{ buttonDelete }}
    </default-btn>
  </default-modal>
</template>

<script>
import Vue from 'vue'
import DefaultBtn from '../../components/default/DefaultBtn'
import DefaultModal from '../../components/default/DefaultModal'

export default Vue.extend({
  name: 'ModalAccess',
  components: { DefaultModal, DefaultBtn },
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    buttonCancel: {
      type: String,
      default: ''
    },
    buttonDelete: {
      type: String,
      default: ''
    },
    store: {
      type: Object
    }
  },
  computed: {
    product() {
      if (typeof this.store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.store.state.main.product
      }
    },
  },
  methods: {
    onClick() {
      this.store.dispatch('card/setVideoUid', null)
      this.store.dispatch('card/setVideoUrl', null)
      this.$emit('close')
    },
  }
});
</script>

<style lang="scss" scoped></style>
