import Vue from 'vue'
import axios from 'axios'

export default {
  getParamsByKey (key) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/params/'

    return axios.get(ACTION_URL, {
      params: {
        key
      }
    })
      .then((response) => {
        if (response.data.status === 'ok' && typeof response.data.data !== 'undefined') {
          return response.data.data
        } else {
          throw new Error('response_error')
        }
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('params | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 404) {
            throw new Error('params_not_found')
          } else {
            throw new Error('params_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('params', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },

  getParamsByCode (code) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/params/'

    return axios.get(ACTION_URL, {
      params: {
        code
      }
    })
      .then((response) => {
        if (response.data.status === 'ok' && typeof response.data.data !== 'undefined') {
          return response.data.data
        } else {
          throw new Error('response_error')
        }
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('params | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 404) {
            throw new Error('params_not_found')
          } else {
            throw new Error('params_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('params', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },

  getParamsByUrl (url) {
    const ACTION_URL = process.env.VUE_APP_WIDGET_API_URL + '/params/'

    return axios.get(ACTION_URL, {
      params: {
        url
      }
    })
      .then((response) => {
        if (response.data.status === 'ok' && typeof response.data.data !== 'undefined') {
          return response.data.data
        } else {
          throw new Error('response_error')
        }
      }).catch((error) => {
        if (error.response) {
          Vue.$logger.error('params | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 404) {
            throw new Error('params_not_found')
          } else {
            throw new Error('params_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('params', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
