<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#0E1E0E"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(18);
  height: rem(18);
}
</style>
