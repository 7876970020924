<template>
  <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7594 15.7348C10.1871 16.3628 9.48929 16.8631 8.71129 17.2031C7.93329 17.5431 7.09256 17.7152 6.24379 17.7081C5.43099 17.7191 4.62423 17.5669 3.87105 17.2606C3.11787 16.9543 2.4335 16.5001 1.85826 15.9247C0.267578 14.3162 0.267578 12.3652 0.267578 8.99932C0.267578 5.73025 0.267578 3.68615 1.85826 2.07395C2.43786 1.49005 3.13047 1.03098 3.89356 0.724928C4.65666 0.418878 5.47414 0.272305 6.29582 0.294206C8.54805 0.294206 9.77451 1.11706 11.3578 2.625L10.135 3.85369C9.02005 2.7367 8.0203 1.92874 6.3144 1.92874C5.75443 1.92137 5.19863 2.02628 4.67973 2.23729C4.16082 2.4483 3.68928 2.76115 3.29285 3.15743C2.04409 4.43081 2.0924 6.50842 2.0924 9.00304V9.6509C2.0924 12.8045 2.33398 13.9104 3.29285 14.8635C4.0784 15.6406 5.13988 16.0732 6.24379 16.0662C6.85472 16.0759 7.46015 15.9493 8.01619 15.6956C8.57224 15.4418 9.06495 15.0673 9.4586 14.5992C10.1313 13.8061 10.3952 12.8678 10.3952 11.4716V10.2019H6.24379V8.58975H12.2237V11.3078C12.2237 13.3295 11.7889 14.6029 10.7594 15.7348Z" fill="#FA6400"/>
    <path d="M15.4683 2.45746C15.1563 2.45746 14.8571 2.33331 14.6365 2.11231C14.4159 1.89131 14.292 1.59157 14.292 1.27903C14.292 0.966496 14.4159 0.666758 14.6365 0.44576C14.8571 0.224762 15.1563 0.100606 15.4683 0.100606C15.779 0.0984839 16.0783 0.217905 16.3025 0.433465C16.5267 0.649025 16.6581 0.943711 16.6687 1.25483C16.6702 1.41318 16.6402 1.57024 16.5804 1.71682C16.5206 1.8634 16.4322 1.99657 16.3204 2.10855C16.2087 2.22052 16.0757 2.30905 15.9294 2.36896C15.7831 2.42887 15.6263 2.45896 15.4683 2.45746ZM14.606 17.5629V5.73026H16.3528V17.5629H14.606Z" fill="#FA6400"/>
    <path d="M29.3607 7.21225V5.72292H26.7591V1.99961H25.2725V5.72292H21.556V3.61181C21.556 2.45758 22.1246 1.99589 23.1838 1.99589H24.1575V0.506561H22.8642C21.0059 0.506561 19.694 1.62356 19.694 3.55968V5.7192H18.2111V7.20853H19.6977V17.6338H21.556V7.20853H25.2725V14.3908C25.2725 16.3381 26.3243 17.6375 28.1751 17.6375H29.3607V16.1482H28.4799C27.4244 16.1482 26.7517 15.4706 26.7517 14.3387V7.21225H29.3607Z" fill="#FA6400"/>
    <path d="M31.7059 12.1048C31.6896 12.8359 31.8158 13.5631 32.0776 14.2457C32.6313 15.5675 33.6868 16.193 35.2961 16.193C35.8609 16.1956 36.4196 16.0765 36.9345 15.844C37.4494 15.6115 37.9084 15.2708 38.2805 14.8452L39.4549 15.8802C38.5927 16.7217 38.1839 16.9972 37.4629 17.2988C36.7356 17.5813 35.9608 17.7204 35.1809 17.7084C31.7728 17.7084 29.9777 15.5936 29.9777 11.5761C29.9777 7.85279 31.836 5.58529 34.8278 5.58529C37.8196 5.58529 39.6593 7.7746 39.6593 11.308V12.1011L31.7059 12.1048ZM37.7304 9.24158C37.5582 8.64585 37.2079 8.11731 36.7267 7.72711C36.2455 7.33692 35.6564 7.10381 35.039 7.05919C34.4215 7.01457 33.8052 7.16057 33.273 7.47754C32.7409 7.79451 32.3185 8.26722 32.0627 8.83202C31.8211 9.36073 31.7505 9.74795 31.691 10.8054H37.946C37.9591 10.2761 37.8876 9.74819 37.7342 9.24158H37.7304Z" fill="#FA6400"/>
    <path d="M47.531 7.99015C46.836 7.31623 46.3788 7.12634 45.5612 7.12634C43.807 7.12634 42.6586 8.39972 42.6586 10.2986V17.5628H40.9304V5.7301H42.6586V7.17475C43.048 6.65025 43.561 6.23054 44.1518 5.95312C44.7426 5.67569 45.3928 5.54918 46.0443 5.58489C46.5872 5.57729 47.1262 5.67594 47.6313 5.87531C48.0805 6.05773 48.4821 6.34043 48.8057 6.70188L47.531 7.99015Z" fill="#FA6400"/>
    <path d="M54.3545 20.4261C53.8008 21.9415 52.8159 22.6377 51.2066 22.6377H50.4633V21.0963H50.9911C52.106 21.0963 52.5037 20.8096 52.9348 19.6293L53.7748 17.3208L49.5305 5.73015H51.3887L54.6333 15.2283L57.8518 5.72643H59.7101L54.3545 20.4261Z" fill="#FA6400"/>
    <path d="M6.18434 8.56364V22.5819C6.18164 22.5955 6.18164 22.6094 6.18434 22.6229C6.191 22.6356 6.20137 22.646 6.21407 22.6527H6.25495H6.27354L7.73043 21.4724L9.14643 20.3219C9.15639 20.3137 9.16887 20.3092 9.18174 20.3092C9.19461 20.3092 9.20709 20.3137 9.21705 20.3219L10.6368 21.4724L12.0899 22.6527H12.1122H12.1531C12.16 22.6503 12.1662 22.6463 12.1714 22.6412C12.1765 22.636 12.1805 22.6298 12.1829 22.6229C12.186 22.6094 12.186 22.5954 12.1829 22.5819V8.56364H6.18434Z" fill="#FCBD4B"/>
    <g style="mix-blend-mode:multiply" opacity="0.06">
      <g style="mix-blend-mode:multiply" opacity="0.06">
        <path d="M6.18434 8.56364V22.5819C6.18164 22.5955 6.18164 22.6094 6.18434 22.6229C6.191 22.6356 6.20137 22.646 6.21407 22.6527H6.25495H6.27354L7.73043 21.4724L9.14643 20.3219C9.15639 20.3137 9.16887 20.3092 9.18174 20.3092C9.19461 20.3092 9.20709 20.3137 9.21705 20.3219L10.6368 21.4724L12.0899 22.6527H12.1122H12.1531C12.16 22.6503 12.1662 22.6463 12.1714 22.6412C12.1765 22.636 12.1805 22.6298 12.1829 22.6229C12.186 22.6094 12.186 22.5954 12.1829 22.5819V8.56364H6.18434Z" fill="#323232"/>
      </g>
    </g>
    <path d="M12.2423 8.56014H6.17688V10.2543H12.2423V8.56014Z" fill="#FDE7A7"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoGiftery'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(60);
  height: rem(23);
}
</style>
