export default function () {
  this.id = null
  this.productId = null
  this.face = null
  this.type = 'myself'
  this.typeToSend = 'email'
  this.greeting = null
  this.senderName = null
  this.senderEmail = null
  this.senderPhone = null
  this.recipientName = null
  this.recipientEmail = null
  this.recipientPhone = null
  this.recipientCountryCode = null
  this.recipientNumberPhone = null
  this.recipientNumberPhoneValid = false
  this.checkedRecipientPhone = null
  this.notifyBySms = false
  this.sendExactTime = false
  this.sendTime = null
  this.cardImage = null
  this.quantity = null
  this.videoUid = null
  this.videoUrl = null
}
