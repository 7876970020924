<template>
  <div class="short-info-block__item">
    <div class="short-info-block__icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L10.07 19.97L12.58 12.58L19.97 10.07L3 3Z" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M13 13L19 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="short-info-block__text"
      v-html="$t(`conditions.enter_code.${ $store.state.main.product.kind }`)"></div>
  </div>
</template>

<script>
export default {
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
  }
}
</script>

<style scoped>

</style>
