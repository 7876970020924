<template>
  <svg width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <rect width="40" height="26.6647" fill="#F9FAFB"/>
      <path d="M0 3.33309C0 1.49228 1.49239 0 3.33333 0H36.6667C38.5077 0 40 1.49228 40 3.33309V23.3317C40 25.1725 38.5077 26.6647 36.6667 26.6647H3.33333C1.49238 26.6647 0 25.1725 0 23.3317V3.33309Z" fill="#6E7882" fill-opacity="0.1"/>
      <path d="M34.4251 8.3616H32.3296C31.6959 8.3616 31.2086 8.55652 30.9163 9.19001L26.9201 18.3024H29.7468C29.7468 18.3024 30.2341 17.0841 30.3314 16.7918C30.6239 16.7918 33.4016 16.7918 33.7916 16.7918C33.8889 17.133 34.1326 18.2537 34.1326 18.2537H36.6668L34.4251 8.3616ZM31.1113 14.7452C31.3549 14.1604 32.1833 11.9676 32.1833 11.9676C32.1833 12.0163 32.4269 11.3828 32.5244 11.0417L32.7194 11.9188C32.7194 11.9188 33.2554 14.3066 33.3529 14.7939H31.1113V14.7452Z" fill="#3362AB"/>
      <path d="M27.115 15.0376C27.115 17.0842 25.2631 18.4487 22.3878 18.4487C21.1696 18.4487 20 18.2051 19.3665 17.9126L19.7563 15.6711L20.0975 15.8173C20.9746 16.2071 21.5595 16.3533 22.6315 16.3533C23.4113 16.3533 24.2398 16.0609 24.2398 15.3787C24.2398 14.9402 23.8986 14.6478 22.8265 14.1605C21.8031 13.6732 20.4386 12.8935 20.4386 11.4803C20.4386 9.53117 22.3391 8.21548 25.0195 8.21548C26.0428 8.21548 26.92 8.4104 27.4561 8.65405L27.0663 10.7981L26.8713 10.6032C26.384 10.4083 25.7505 10.2134 24.8245 10.2134C23.8011 10.2621 23.3138 10.7007 23.3138 11.0905C23.3138 11.5291 23.8986 11.8702 24.8245 12.3088C26.384 13.0397 27.115 13.8681 27.115 15.0376Z" fill="#3362AB"/>
      <path d="M3.33334 8.45934L3.38208 8.2644H7.57311C8.15791 8.2644 8.59649 8.45934 8.74269 9.09285L9.66863 13.4788C8.74269 11.1396 6.59844 9.23905 3.33334 8.45934Z" fill="#F9B50B"/>
      <path d="M15.5653 8.36172L11.3255 18.2538H8.45024L6.01361 9.96979C7.76799 11.0906 9.22998 12.8448 9.76605 14.0631L10.0584 15.0864L12.69 8.31299H15.5653V8.36172Z" fill="#3362AB"/>
      <path d="M16.6861 8.31299H19.3665L17.6608 18.2538H14.9805L16.6861 8.31299Z" fill="#3362AB"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="26.6647" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImgVisa'
}
</script>

<style lang="scss" scoped>
svg {
  width: rem(40);
  height: rem(27);
}
</style>
