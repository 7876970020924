<template>
  <div>
    <custom-modal v-model="show">
      <template v-slot:title>
        {{ $t('balance.title') }}
      </template>
      <CheckBalance @close-modal="close" />
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from '../hoc/CustomModal'
import CheckBalance from '../CheckBalance'

export default {
  name: 'ModalCheckBalance',
  components: { CustomModal, CheckBalance },
  data: () => ({
    show: true
  }),
  mounted() {
    this.show = true
  },
  destroyed() {
    this.show = false
  },
  methods: {
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
