import { formatDateForLog } from '../../modules/utils'

const module = {
  namespaced: true,
  state: {
    enabled: false,
    log: []
  },
  getters: {
    dump: state => () => {
      return state.log.map((record) => {
        return [formatDateForLog(record.time) + ':', '[' + record.level.toUpperCase() + ']', record.message].join(' ')
      }).join('\n')
    }
  },
  mutations: {
    addToLog: (state, payload) => {
      state.log.push(payload)
    },
    updateDebug: function (state, enabled) {
      state.enabled = enabled
    }
  },
  actions: {
    enableDebug ({ commit }) {
      localStorage.setItem('debug', true)
      commit('updateDebug', true)
    },
    disableDebug ({ commit }) {
      localStorage.removeItem('debug')
      commit('updateDebug', false)
    },
    addToLog: ({ commit }, payload) => {
      commit('addToLog', payload)
    }
  }
}

export default module
